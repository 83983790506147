import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';

interface InfoSectionProps {
  sectionId: string;
  title?: string;
  children: React.ReactNode;
}

export const InfoSection: React.FC<InfoSectionProps> = ({
  sectionId,
  title,
  children,
}) => {
  const storageKey = `infoSection_${sectionId}_isOpen`;
  const [isOpen, setIsOpen] = useState(() => {
    const stored = localStorage.getItem(storageKey);
    return stored === null ? true : stored === 'true';
  });

  useEffect(() => {
    localStorage.setItem(storageKey, isOpen.toString());
  }, [isOpen, storageKey]);

  return (
    <div className={styles.infoSection}>
      <div className={styles.infoHeader} onClick={() => setIsOpen(!isOpen)}>
        <div className={styles.infoTitle}>
          {title || 'About this section'}
          <span className={styles.infoToggle}>{isOpen ? '▼' : '▶'}</span>
        </div>
      </div>
      {isOpen && <div className={styles.infoContent}>{children}</div>}
    </div>
  );
};
