import { configureStore } from '@reduxjs/toolkit';
import rootReducer, { RootState } from './reducers/rootReducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { jobQueueApi } from '../api/generated/jobQueueApi';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(jobQueueApi.middleware),
});

export type AppDispatch = typeof store.dispatch;

export default store;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
