import React, { useState, useMemo } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  ReferenceLine,
  Legend,
  Area,
  AreaChart,
} from 'recharts';
import styles from './EmotionalAnalysis.module.css';
import { TChapterAnalysis } from 'api/generated/types/common';

// Define emotion key type
type EmotionKey =
  | 'joy'
  | 'sadness'
  | 'anger'
  | 'fear'
  | 'trust'
  | 'anticipation'
  | 'surprise'
  | 'disgust'
  | 'positive'
  | 'negative';

// Define the emotion color scheme
const EMOTION_COLORS: Record<EmotionKey, string> = {
  joy: '#FFD700',
  sadness: '#4169E1',
  anger: '#FF4500',
  fear: '#800080',
  trust: '#32CD32',
  anticipation: '#FFA500',
  surprise: '#FF69B4',
  disgust: '#8B4513',
  positive: '#00CED1',
  negative: '#EF5350',
};

// Types to match your data structure
type EmotionDistribution = {
  [key in EmotionKey]?: number;
};

type EmotionalTransition = {
  change_magnitude: number;
  from_text: string;
  to_text: string;
  position: number;
  primary_changes: EmotionKey[];
};

type SentenceEmotions = {
  text: string;
  position: number;
  emotions: EmotionDistribution;
  rolling_emotions: EmotionDistribution;
};

type ChapterEmotions = {
  dominant_emotion: EmotionKey;
  emotion_distribution: EmotionDistribution;
  emotional_transitions: EmotionalTransition[];
  charge: {
    positive: number;
    negative: number;
  };
  sentence_emotions: SentenceEmotions[];
};

interface BookwideEmotionalFlowProps {
  chapters: TChapterAnalysis[];
}

interface ChapterEmotionalAnalysisProps {
  emotionalData: ChapterEmotions;
  chapterTitle: string;
}

const BookwideEmotionalFlow: React.FC<BookwideEmotionalFlowProps> = ({
  chapters,
}) => {
  const [selectedEmotions, setSelectedEmotions] = useState<EmotionKey[]>([
    'joy',
    'fear',
    'anticipation',
    'trust',
  ]);

  // Transform chapter data into a flat array of emotional points with position transformed to book-wide scale
  const emotionalFlow = useMemo(() => {
    return chapters.flatMap((chapter, chapterIndex) => {
      const chapterStart = chapterIndex / chapters.length;
      return chapter.metrics.emotions.sentence_emotions.map(
        (sentence: SentenceEmotions) => ({
          position: chapterStart + sentence.position * (1 / chapters.length),
          chapterTitle: chapter.title,
          text: sentence.text,
          ...sentence.rolling_emotions,
        })
      );
    });
  }, [chapters]);

  // Get the major transitions (magnitude > 1)
  const majorTransitions = useMemo(() => {
    return chapters.flatMap((chapter, chapterIndex) => {
      const chapterStart = chapterIndex / chapters.length;
      return chapter.metrics.emotions.emotional_transitions
        .filter(
          (transition: EmotionalTransition) => transition.change_magnitude > 100
        )
        .map((transition: EmotionalTransition) => ({
          ...transition,
          position: chapterStart + transition.position * (1 / chapters.length),
          chapterTitle: chapter.title,
        }));
    });
  }, [chapters]);

  // Aggregate emotion distribution across all chapters
  const aggregatedEmotionDistribution = useMemo(() => {
    const distribution: EmotionDistribution = {};

    chapters.forEach(chapter => {
      const emotionDist = chapter.metrics.emotions.emotion_distribution || {};
      Object.entries(emotionDist).forEach(([emotion, value]) => {
        const key = emotion as EmotionKey;
        const currentValue = distribution[key] || 0;
        const newValue = typeof value === 'number' ? value : 0;
        distribution[key] = currentValue + newValue;
      });
    });

    // Normalize values
    const total = Object.values(distribution).reduce<number>((sum, val) => {
      const value = typeof val === 'number' ? val : 0;
      return sum + value;
    }, 0);
    if (total > 0) {
      Object.keys(distribution).forEach(key => {
        const emotionKey = key as EmotionKey;
        distribution[emotionKey] = (distribution[emotionKey] || 0) / total;
      });
    }

    return distribution;
  }, [chapters]);

  // Calculate dominant emotion
  const dominantEmotion = useMemo(() => {
    if (Object.keys(aggregatedEmotionDistribution).length === 0)
      return 'joy' as EmotionKey;

    // Find dominant emotion using a more type-safe approach
    let maxEmotion: EmotionKey = 'joy';
    let maxValue = 0;

    Object.entries(aggregatedEmotionDistribution).forEach(
      ([emotion, value]) => {
        const numericValue = typeof value === 'number' ? value : 0;
        if (numericValue > maxValue) {
          maxValue = numericValue;
          maxEmotion = emotion as EmotionKey;
        }
      }
    );

    return maxEmotion;
  }, [aggregatedEmotionDistribution]);

  // Calculate emotional charge
  const emotionalCharge = useMemo(() => {
    let positive = 0;
    let negative = 0;

    chapters.forEach(chapter => {
      positive += chapter.metrics.emotions.charge.positive || 0;
      negative += chapter.metrics.emotions.charge.negative || 0;
    });

    const total = positive + negative;
    return {
      positive: total > 0 ? positive / total : 0.5,
      negative: total > 0 ? negative / total : 0.5,
    };
  }, [chapters]);

  const toggleEmotion = (emotion: EmotionKey) => {
    setSelectedEmotions(prev =>
      prev.includes(emotion)
        ? prev.filter(e => e !== emotion)
        : [...prev, emotion]
    );
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Book-wide Emotional Flow</h3>

      <div className={styles.toggleContainer}>
        {Object.entries(EMOTION_COLORS).map(([emotion, color]) => (
          <button
            key={emotion}
            onClick={() => toggleEmotion(emotion as EmotionKey)}
            className={`${styles.toggleButton} ${
              selectedEmotions.includes(emotion as EmotionKey)
                ? styles.toggleButtonSelected
                : ''
            }`}
            style={{
              backgroundColor: selectedEmotions.includes(emotion as EmotionKey)
                ? color
                : 'transparent',
              borderColor: selectedEmotions.includes(emotion as EmotionKey)
                ? color
                : undefined,
            }}
          >
            {emotion}
          </button>
        ))}
      </div>

      <div className={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart
            data={emotionalFlow}
            margin={{ top: 10, right: 30, left: 0, bottom: 30 }}
          >
            <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
            <XAxis
              dataKey="position"
              tickFormatter={value => `${Math.round(value * 100)}%`}
              label={{ value: 'Story Progress', position: 'bottom', dy: 15 }}
            />
            <YAxis
              tickFormatter={value => `${Math.round(value)}%`}
              domain={[0, 1]}
              label={{
                value: 'Emotional Intensity',
                angle: -90,
                position: 'insideLeft',
                dx: -10,
              }}
            />
            <Tooltip
              content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  const matchingData = emotionalFlow.find(
                    d => d.position === label
                  );
                  return (
                    <div className={styles.tooltipContainer}>
                      <p className={styles.tooltipTitle}>
                        Story Position: {Math.round(Number(label) * 100)}%
                      </p>
                      {matchingData?.chapterTitle && (
                        <p className={styles.tooltipChapter}>
                          {matchingData.chapterTitle}
                        </p>
                      )}
                      <p className={styles.tooltipText}>
                        "{matchingData?.text?.substring(0, 80)}..."
                      </p>
                      <div className={styles.tooltipStats}>
                        {payload.map((entry: any, index: number) => (
                          <div key={index} className={styles.tooltipRow}>
                            <div className={styles.tooltipEmotionName}>
                              <div
                                className={styles.tooltipEmotionIndicator}
                                style={{ backgroundColor: entry.color }}
                              />
                              <span>{entry.name}:</span>
                            </div>
                            <span className={styles.tooltipEmotionValue}>
                              {Math.round(Number(entry.value))}%
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Legend />

            {selectedEmotions.map(emotion => (
              <Area
                key={emotion}
                type="monotone"
                dataKey={emotion}
                stroke={EMOTION_COLORS[emotion]}
                fill={`${EMOTION_COLORS[emotion]}40`}
                activeDot={{ r: 6 }}
                name={emotion}
                strokeWidth={2}
              />
            ))}

            {/* Add reference lines for major emotional transitions */}
            {majorTransitions.map((transition, idx) => (
              <ReferenceLine
                key={idx}
                x={transition.position}
                stroke="#666"
                strokeDasharray="3 3"
                strokeWidth={1}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <div>
        <h4 className={styles.sectionTitle}>Major Emotional Shifts</h4>
        {majorTransitions.length > 0 ? (
          <div className={styles.transitionsList}>
            {majorTransitions.map((transition, idx) => (
              <div key={idx} className={styles.transitionItem}>
                <div className={styles.magnitudeContainer}>
                  <span className={styles.magnitudeValue}>
                    {transition.change_magnitude.toFixed(1)}
                  </span>
                  <span className={styles.magnitudeLabel}>magnitude</span>
                </div>
                <div className={styles.transitionContent}>
                  <p className={styles.chapterLocation}>
                    {transition.chapterTitle}
                  </p>
                  <p className={styles.transitionText}>
                    "{transition.from_text}"
                  </p>
                  <div className={styles.transitionArrow}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 12H19M19 12L13 6M19 12L13 18"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <p className={styles.transitionText}>
                    "{transition.to_text}"
                  </p>
                  <div className={styles.emotionTagsContainer}>
                    {transition.primary_changes.map(
                      (emotion: EmotionKey, i: number) => (
                        <span
                          key={i}
                          className={styles.emotionTag}
                          style={{
                            backgroundColor: EMOTION_COLORS[emotion] || '#666',
                          }}
                        >
                          {emotion}
                        </span>
                      )
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className={styles.emptyState}>
            No major emotional shifts detected in this book.
          </p>
        )}
      </div>

      <div className={styles.infoGrid}>
        <div className={styles.infoCard}>
          <h4 className={styles.sectionTitle}>Emotional Distribution</h4>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={Object.entries(aggregatedEmotionDistribution).map(
                ([emotion, value]) => ({
                  emotion,
                  value: typeof value === 'number' ? Math.min(value, 1) : 0,
                })
              )}
              margin={{ top: 10, right: 10, left: 10, bottom: 40 }}
            >
              <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
              <XAxis
                dataKey="emotion"
                angle={-45}
                textAnchor="end"
                height={60}
              />
              <YAxis
                domain={[0, 1]}
                tickFormatter={value => `${Math.round(value * 100)}%`}
              />
              <Tooltip
                formatter={value => [
                  `${Math.round(Number(value) * 100)}%`,
                  'Intensity',
                ]}
              />
              <Bar dataKey="value">
                {Object.entries(aggregatedEmotionDistribution).map(
                  ([emotion]) => (
                    <Cell
                      key={emotion}
                      fill={EMOTION_COLORS[emotion as EmotionKey] || '#666'}
                    />
                  )
                )}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className={styles.infoCard}>
          <h4 className={styles.sectionTitle}>Emotional Insights</h4>
          <div>
            <div className={`${styles.insightCard} ${styles.insightCardBlue}`}>
              <h5 className={styles.insightTitle}>Dominant Emotion</h5>
              <p>
                <span
                  className={styles.emotionIndicator}
                  style={{
                    backgroundColor: EMOTION_COLORS[dominantEmotion] || '#666',
                  }}
                ></span>
                <span>{dominantEmotion || 'Not available'}</span>
              </p>
            </div>

            <div
              className={`${styles.insightCard} ${styles.insightCardPurple}`}
            >
              <h5 className={styles.insightTitle}>Emotional Charge</h5>
              <div className={styles.statRow}>
                <div className={styles.statItem}>
                  <span
                    className={styles.emotionIndicator}
                    style={{ backgroundColor: EMOTION_COLORS.positive }}
                  ></span>
                  <span>
                    Positive: {(emotionalCharge.positive * 100).toFixed(1)}%
                  </span>
                </div>
                <div className={styles.statItem}>
                  <span
                    className={styles.emotionIndicator}
                    style={{ backgroundColor: EMOTION_COLORS.negative }}
                  ></span>
                  <span>
                    Negative: {(emotionalCharge.negative * 100).toFixed(1)}%
                  </span>
                </div>
              </div>
            </div>

            <div className={`${styles.insightCard} ${styles.insightCardGreen}`}>
              <h5 className={styles.insightTitle}>Writing Insights</h5>
              <ul className={styles.insightsList}>
                <li className={styles.insightItem}>
                  •{' '}
                  {dominantEmotion === 'joy'
                    ? 'Your book has an overall positive tone'
                    : dominantEmotion === 'fear' || dominantEmotion === 'anger'
                      ? 'Your book has high tension and conflict'
                      : 'This book shows emotional complexity'}
                </li>
                <li className={styles.insightItem}>
                  •{' '}
                  {majorTransitions.length > 10
                    ? 'Many emotional shifts create dynamic pacing'
                    : majorTransitions.length > 0
                      ? 'Some emotional shifts add interest'
                      : 'Consistent emotional tone throughout'}
                </li>
                <li className={styles.insightItem}>
                  •{' '}
                  {emotionalCharge.positive > emotionalCharge.negative
                    ? 'Overall positive emotional balance'
                    : 'Stronger negative emotions present'}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ChapterEmotionalAnalysis: React.FC<
  ChapterEmotionalAnalysisProps
> = ({ emotionalData, chapterTitle }) => {
  const [selectedEmotions, setSelectedEmotions] = useState<EmotionKey[]>([
    'joy',
    'sadness',
    'anger',
  ]);

  // Convert emotion distribution to array format for the chart and scale based on selection
  const emotionData = useMemo(() => {
    if (!emotionalData?.emotion_distribution) return [];

    const entries = Object.entries(emotionalData.emotion_distribution).filter(
      ([emotion]) => selectedEmotions.includes(emotion as EmotionKey)
    );

    // Calculate total of selected emotions for scaling
    const selectedTotal = entries.reduce(
      (sum, [_, intensity]) =>
        sum + (typeof intensity === 'number' ? intensity : 0),
      0
    );

    return entries.map(([emotion, intensity]) => ({
      emotion,
      originalIntensity: typeof intensity === 'number' ? intensity : 0,
      intensity:
        selectedTotal > 0
          ? (typeof intensity === 'number' ? intensity : 0) / selectedTotal
          : 0,
      selected: true,
    }));
  }, [emotionalData?.emotion_distribution, selectedEmotions]);

  const toggleEmotion = (emotion: EmotionKey) => {
    setSelectedEmotions(prev =>
      prev.includes(emotion)
        ? prev.filter(e => e !== emotion)
        : [...prev, emotion]
    );
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{chapterTitle} - Emotional Profile</h3>

      <div className={styles.insightRow}>
        <div className={`${styles.insightCard} ${styles.insightCardBlue}`}>
          <h5 className={styles.insightTitle}>Dominant Emotion</h5>
          <div className={styles.emotionBadgeContainer}>
            <span
              className={styles.emotionBadge}
              style={{
                backgroundColor: EMOTION_COLORS[emotionalData.dominant_emotion],
              }}
            >
              {emotionalData.dominant_emotion}
            </span>
          </div>
        </div>

        <div className={`${styles.insightCard} ${styles.insightCardPurple}`}>
          <h5 className={styles.insightTitle}>Emotional Charge</h5>
          <div className={styles.statRow}>
            <div className={styles.statItem}>
              <span
                className={styles.emotionIndicator}
                style={{ backgroundColor: EMOTION_COLORS.positive }}
              ></span>
              <span>
                Positive: {(emotionalData?.charge?.positive || 0).toFixed(1)}%
              </span>
            </div>
            <div className={styles.statItem}>
              <span
                className={styles.emotionIndicator}
                style={{ backgroundColor: EMOTION_COLORS.negative }}
              ></span>
              <span>
                Negative: {(emotionalData?.charge?.negative || 0).toFixed(1)}%
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.toggleContainer}>
        {Object.entries(EMOTION_COLORS).map(([emotion, color]) => (
          <button
            key={emotion}
            onClick={() => toggleEmotion(emotion as EmotionKey)}
            className={`${styles.toggleButton} ${
              selectedEmotions.includes(emotion as EmotionKey)
                ? styles.toggleButtonSelected
                : ''
            }`}
            style={{
              backgroundColor: selectedEmotions.includes(emotion as EmotionKey)
                ? color
                : 'transparent',
              borderColor: selectedEmotions.includes(emotion as EmotionKey)
                ? color
                : undefined,
            }}
          >
            {emotion}
          </button>
        ))}
      </div>

      <div className={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={emotionData}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" opacity={0.2} />
            <XAxis
              dataKey="emotion"
              interval={0}
              tick={{ fontSize: 12 }}
              height={60}
              angle={-45}
              textAnchor="end"
            />
            <YAxis
              domain={[0, 1]}
              tickFormatter={value => `${Math.round(value * 100)}%`}
            />
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const { emotion, intensity, originalIntensity } =
                    payload[0].payload;
                  return (
                    <div className={styles.tooltipContainer}>
                      <p className={styles.tooltipTitle}>
                        <span
                          className={styles.emotionIndicator}
                          style={{
                            backgroundColor:
                              EMOTION_COLORS[emotion as EmotionKey],
                          }}
                        ></span>
                        {emotion}
                      </p>
                      <p className={styles.tooltipValue}>
                        Original: {Math.round(originalIntensity)}%
                      </p>
                      <p className={styles.tooltipValue}>
                        Scaled: {Math.round(intensity * 100)}%
                      </p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Bar dataKey="intensity">
              {emotionData.map(({ emotion }) => (
                <Cell
                  key={emotion}
                  fill={EMOTION_COLORS[emotion as EmotionKey]}
                  opacity={1}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export { BookwideEmotionalFlow };
export default BookwideEmotionalFlow;
