// src/App.tsx
import React, { useEffect, useState } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/Login/Login';
import { AppDispatch } from './store';
import Layout from './components/Layout/Layout';
import About from './pages/About/About';
import Home from './pages/Home';
import Signup from './pages/Signup/Signup';
import Invite from './pages/Invite/Invite';
import { FileDetails } from './pages/FileDetails';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpiner';
import { checkSession } from './store/slices/userAuthSlice';
import PublicRoute from './components/PublicRoute';
import AccountSettingsPage from './pages/AccountSettings/AccountSettings';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AdminDashboard from './pages/Admin/AdminDashboard';
import AdminProtectedRoute from './components/AdminProtectedRoute';
import UserManagement from './pages/UserManagement/UserManagement';
import Bugs from './pages/Bugs/Bugs';
import { RootState } from './store/reducers/rootReducer';
import EditorialAnalysis from './pages/Product/EditorialAnalysis/EditorialAnalysis';
import ManuscriptAnalysis from './pages/Product/ManuscriptAnalysis/ManuscriptAnalysis';
import PromptManagement from './pages/PromptManagement/PromptManagement';
import Submissions from './pages/Submissions/Submissions';
import FeedbackPage from './pages/Feedback/FeedbackPage';
import StoryBeatAnalysis from './pages/Product/StoryBeats/StoryBeats';
import EditorialDashboard from './pages/EditorialDashboard/EditorialDashboard';
import './styles/global.css';
import RoyaltyUpload from './pages/RoyaltyUpload/RoyaltyUpload';
import RoyaltiesSummary from './pages/RoyaltiesSummary/RoyaltiesSummary';
import PredefinedRoyalties from './pages/PredefinedRoyalties/PredefinedRoyalties';
import RoyaltyCalculation from './pages/RoyaltyCalculation/RoyaltyCalculation';
import RoyaltyCalculator from 'pages/Product/RoyaltyCalculator/RoyaltyCalculator';
import JobQueueManagement from './pages/Admin/JobQueueManagement/JobQueueManagement';
// Import Series Wiki components
import CharacterManagement from './pages/SeriesWiki/CharacterManagement';
import CharacterDetail from './pages/SeriesWiki/CharacterDetail';
import CharacterForm from './components/SeriesWiki/Characters/CharacterForm';
import SeriesDashboard from './pages/SeriesWiki/SeriesDashboard';
import SeriesForm from './pages/SeriesWiki/SeriesForm';
import SeriesView from './pages/SeriesWiki/SeriesView';
import { get } from './api/apiUtils';
import LocationDetail from './pages/SeriesWiki/LocationDetail';
import LocationForm from './components/SeriesWiki/Locations/LocationForm'; // Assuming LocationForm handles create/edit

// Wrapper components for route parameters
const CreateCharacterWrapper: React.FC = () => {
  const { seriesId } = useParams<{ seriesId: string }>();
  if (!seriesId) return <div>Series ID is required</div>;
  return <CharacterForm seriesId={seriesId} mode="create" />;
};

const EditCharacterWrapper: React.FC = () => {
  const { seriesId, characterId } = useParams<{
    seriesId: string;
    characterId: string;
  }>();
  const [character, setCharacter] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCharacter = async () => {
      if (!seriesId || !characterId) return;
      try {
        setLoading(true);
        // Use get function directly
        const data = await get(`/wiki/characters/${characterId}/get`);
        setCharacter(data);
      } catch (err) {
        setError('Failed to load character data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCharacter();
  }, [seriesId, characterId]);

  if (!seriesId) return <div>Series ID is required</div>;
  if (loading) return <div>Loading character data...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!character) return <div>Character not found</div>;

  return (
    <CharacterForm seriesId={seriesId} character={character} mode="edit" />
  );
};

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = useSelector(
    (state: RootState) => state.userAuth.isAuthenticated
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(checkSession());
  }, [dispatch]);

  useEffect(() => {
    // Set loading to false once we know the authentication state
    setIsLoading(false);
  }, [isAuthenticated]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Submissions /> : <Home />}
        />
        <Route path="/about" element={<About />} />
        <Route element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/invites/:id" element={<Invite />} />
          <Route
            path="/products/editorial-analysis"
            element={<EditorialAnalysis />}
          />
          <Route
            path="/products/manuscript-analysis"
            element={<ManuscriptAnalysis />}
          />
          <Route path="/products/story-beats" element={<StoryBeatAnalysis />} />
          <Route
            path="/products/royalty-calculator"
            element={<RoyaltyCalculator />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/manuscripts" element={<Submissions />} />
          <Route path="/file/:id" element={<FileDetails />} />
          <Route path="/editorial/:id" element={<EditorialDashboard />} />
          <Route path="/feedback" element={<FeedbackPage />} />
          <Route path="/account-settings" element={<AccountSettingsPage />} />
          <Route path="/royalties" element={<RoyaltiesSummary />} />
          <Route
            path="/royalties/setup"
            element={<PredefinedRoyalties isEdit={false} />}
          />
          <Route
            path="/royalties/setup/:bookId"
            element={<PredefinedRoyalties isEdit={true} />}
          />
          <Route
            path="/royalties/calculate/:bookId"
            element={<RoyaltyCalculation />}
          />
          <Route path="/royalties/upload" element={<RoyaltyUpload />} />

          {/* Series Wiki Routes */}
          <Route path="/series" element={<SeriesDashboard />} />
          <Route path="/series/create" element={<SeriesForm />} />
          <Route path="/series/:seriesId" element={<SeriesView />} />
          <Route
            path="/series/:seriesId/characters"
            element={<CharacterManagement />}
          />
          <Route
            path="/series/:seriesId/characters/:characterId"
            element={<CharacterDetail />}
          />
          <Route
            path="/series/:seriesId/characters/new"
            element={<CreateCharacterWrapper />}
          />
          <Route
            path="/series/:seriesId/characters/:characterId/edit"
            element={<EditCharacterWrapper />}
          />

          {/* Location Routes */}
          <Route
            path="/series/:seriesId/locations/new"
            element={<LocationForm />}
          />
          <Route
            path="/series/:seriesId/locations/:locationId"
            element={<LocationDetail />}
          />
          <Route
            path="/series/:seriesId/locations/:locationId/edit"
            element={<LocationForm />}
          />

          <Route element={<AdminProtectedRoute />}>
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/admin/users" element={<UserManagement />} />
            <Route path="/admin/bugs" element={<Bugs />} />
            <Route path="/admin/prompts" element={<PromptManagement />} />
            <Route path="/admin/job-queue" element={<JobQueueManagement />} />
          </Route>

          {/* Add other protected routes here */}
        </Route>
      </Routes>
    </Layout>
  );
};

export default App;
