import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './RoyaltyCalculation.module.css';
import RoyaltyUpload from 'pages/RoyaltyUpload/RoyaltyUpload';
import { serverEndpoints } from 'api/generated/endpoints';
import {
  TBookRoyaltyStructure,
  TCalculateRoyaltiesResponse,
  TRoyaltyStructureResponse,
} from 'api/generated/types/common';
import { post } from 'api/apiUtils';
import { AlertDialog } from 'components/AlertDialog/AlertDialog';
import Button from 'components/design-system/Button/Button';

const RoyaltyCalculation: React.FC = () => {
  const navigate = useNavigate();
  const { bookId } = useParams<{ bookId: string }>();
  const [error, setError] = useState<string | null>(null);
  const [royaltiesData, setRoyaltiesData] =
    useState<TCalculateRoyaltiesResponse | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [bookRoyaltyStructures, setBookRoyaltyStructures] =
    useState<TBookRoyaltyStructure | null>(null);

  const markContributorMonthlyRoyaltyPaid = async (
    contributorPaidRoyaltyId: string
  ) => {
    try {
      await post(
        serverEndpoints.royaltiesPayMonthlyRoyalty({
          contributorPaidRoyaltyId,
        }),
        {}
      );
      // Update the local state to reflect the payment
      if (royaltiesData) {
        const updatedRoyaltiesOwed = royaltiesData.royaltiesOwed.map(
          contributor => {
            return {
              ...contributor,
              monthlyRoyaltiesOwed: contributor.monthlyRoyaltiesOwed.map(
                monthly => {
                  if (
                    monthly.contributorPaidRoyaltyId ===
                    contributorPaidRoyaltyId
                  ) {
                    return {
                      ...monthly,
                      paid: true,
                    };
                  }
                  return monthly;
                }
              ),
            };
          }
        );

        setRoyaltiesData({
          ...royaltiesData,
          royaltiesOwed: updatedRoyaltiesOwed,
        });
      }
    } catch (error) {
      console.error('Failed to mark royalty as paid: ', error);
      throw error;
    }
  };

  const markAllYearRoyaltiesPaid = async (
    contributorId: string,
    year: number
  ) => {
    try {
      if (!royaltiesData) return;

      const contributor = royaltiesData.royaltiesOwed.find(
        c => c.contributorId === contributorId
      );
      if (!contributor) return;

      // Find all unpaid royalties for this year
      const unpaidRoyaltyIds = contributor.monthlyRoyaltiesOwed
        .filter(monthly => {
          const monthDate = new Date(monthly.month);
          return monthDate.getUTCFullYear() === year && !monthly.paid;
        })
        .map(monthly => monthly.contributorPaidRoyaltyId);

      // Mark each royalty as paid
      for (const id of unpaidRoyaltyIds) {
        await post(
          serverEndpoints.royaltiesPayMonthlyRoyalty({
            contributorPaidRoyaltyId: id,
          }),
          {}
        );
      }

      // Update the local state to reflect all payments
      if (royaltiesData) {
        const updatedRoyaltiesOwed = royaltiesData.royaltiesOwed.map(c => {
          if (c.contributorId === contributorId) {
            return {
              ...c,
              monthlyRoyaltiesOwed: c.monthlyRoyaltiesOwed.map(monthly => {
                const monthDate = new Date(monthly.month);
                if (monthDate.getFullYear() === year) {
                  return {
                    ...monthly,
                    paid: true,
                  };
                }
                return monthly;
              }),
            };
          }
          return c;
        });

        setRoyaltiesData({
          ...royaltiesData,
          royaltiesOwed: updatedRoyaltiesOwed,
        });
      }
    } catch (error) {
      console.error('Failed to mark all royalties as paid: ', error);
      throw error;
    }
  };

  const handleDeleteBook = async () => {
    if (!bookId) return;

    setIsDeleting(true);
    try {
      await post(`/files/book/${bookId}/delete`, {});
      // Redirect to submissions after a brief delay to show success message
      setTimeout(() => {
        navigate('/royalties');
      }, 2000);
    } catch (error) {
      console.error('Error deleting book:', error);
      setError('Failed to delete book. Please try again later.');
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    const getRoyaltyStructures = async () => {
      try {
        const data = await post<TRoyaltyStructureResponse>(
          serverEndpoints.royaltiesListAllRoyaltyStructures(),
          {}
        );
        const bookData = data.bookRoyaltyStructures.find(
          (book: TBookRoyaltyStructure) => book.bookId === bookId
        );
        if (!bookData) {
          setError(`No royalty structures defined for book ${bookId}`);
          return;
        }

        setBookRoyaltyStructures(bookData);
      } catch (error) {
        console.error('Failed to get royalty structures:', error);
        setError('Failed to get royalty structures. Please try again later.');
      }
    };

    if (bookId) {
      try {
        post<TCalculateRoyaltiesResponse>(
          serverEndpoints.royaltiesCalculateRoyalties({ bookId }),
          {}
        )
          .then(response => {
            setRoyaltiesData(response);
          })
          .catch(error => {
            console.error('Failed to calculate royalties:', error);
            setError('Failed to calculate royalties. Please try again later.');
          });
      } catch (error) {
        console.error('Failed to calculate royalties:', error);
        setError('Failed to calculate royalties. Please try again later.');
      }
    }
    if (bookRoyaltyStructures === null) {
      getRoyaltyStructures();
    }
  }, [bookId, bookRoyaltyStructures]);

  return (
    <div className={styles.container}>
      {error && <p className={styles.errorMessage}>{error}</p>}
      {royaltiesData && royaltiesData.royaltiesOwed.length > 0 ? (
        royaltiesData.royaltiesOwed.map(contributor => {
          // Group monthly royalties by year
          const royaltiesByYear: Record<
            number,
            {
              months: typeof contributor.monthlyRoyaltiesOwed;
              totalAmount: number;
              allPaid: boolean;
            }
          > = {};

          contributor.monthlyRoyaltiesOwed.forEach(monthly => {
            const date = new Date(monthly.month);
            const year = date.getUTCFullYear();

            if (!royaltiesByYear[year]) {
              royaltiesByYear[year] = {
                months: [],
                totalAmount: 0,
                allPaid: true,
              };
            }

            royaltiesByYear[year].months.push(monthly);
            royaltiesByYear[year].totalAmount += monthly.amountOwed;
            if (!monthly.paid) {
              royaltiesByYear[year].allPaid = false;
            }
          });

          // Sort years in descending order (newest first)
          const sortedYears = Object.keys(royaltiesByYear)
            .map(Number)
            .sort((a, b) => b - a);

          return (
            <div key={contributor.contributorId}>
              <h3>{contributor.contributorName}</h3>
              {sortedYears.map(year => {
                const yearData = royaltiesByYear[year];
                return (
                  <div key={year} className={styles.yearGroup}>
                    <h4>
                      {year} - Total: ${yearData.totalAmount.toFixed(2)}
                      {!yearData.allPaid && (
                        <Button
                          className={styles.markAllPaidButton}
                          size="small"
                          variant="primary"
                          onClick={() =>
                            markAllYearRoyaltiesPaid(
                              contributor.contributorId,
                              year
                            )
                          }
                        >
                          Mark All {year} Paid
                        </Button>
                      )}
                    </h4>
                    <ul>
                      {yearData.months
                        .sort(
                          (a, b) =>
                            new Date(b.month).getTime() -
                            new Date(a.month).getTime()
                        )
                        .map((monthly, index) => {
                          const date = new Date(monthly.month);
                          return (
                            <li key={index}>
                              <div className={styles.monthlyDetails}>
                                <span>
                                  <strong>Month:</strong>{' '}
                                  {date.toLocaleString('default', {
                                    month: 'long',
                                    timeZone: 'UTC',
                                  })}
                                </span>
                                <span>
                                  <strong>Amount Owed:</strong> $
                                  {monthly.amountOwed.toFixed(2)}
                                </span>
                                <span>
                                  <strong>Paid:</strong>{' '}
                                  {monthly.paid ? 'Yes' : 'No'}
                                </span>
                              </div>
                              <div className={styles.actionButtons}>
                                {!monthly.paid && (
                                  <Button
                                    className={styles.paidButton}
                                    size="small"
                                    variant="secondary"
                                    onClick={() =>
                                      markContributorMonthlyRoyaltyPaid(
                                        monthly.contributorPaidRoyaltyId
                                      )
                                    }
                                  >
                                    Mark Paid
                                  </Button>
                                )}
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                );
              })}
            </div>
          );
        })
      ) : (
        <div className={styles.emptyState}>
          {bookRoyaltyStructures === null ||
          bookRoyaltyStructures.contributorRoyaltyStructures.length === 0 ? (
            <>
              <p>
                No royalty structure found. Please setup your royalty structure
                first before uploading KDP documents.
              </p>
              <Button
                className={styles.setupButton}
                onClick={() => navigate(`/royalties/setup/${bookId}`)}
                size="medium"
                variant="primary"
              >
                Setup Royalty Structure
              </Button>
            </>
          ) : (
            <p>
              No payments found. Upload your KDP document to start calculating
              royalties.
            </p>
          )}
        </div>
      )}
      <RoyaltyUpload />
      <div>
        <Button
          className={styles.deleteButton}
          onClick={() => setShowDeleteDialog(true)}
          disabled={isDeleting}
        >
          {isDeleting ? 'Deleting...' : 'Delete Book'}
        </Button>

        <AlertDialog
          isOpen={showDeleteDialog}
          title="Are you absolutely sure?"
          description={`This will permanently delete this book and all its associated data. This action cannot be undone.`}
          onConfirm={handleDeleteBook}
          onCancel={() => setShowDeleteDialog(false)}
          confirmText="Yes, Delete Book"
          cancelText="Cancel"
          isConfirming={isDeleting}
        />
      </div>
    </div>
  );
};

export default RoyaltyCalculation;
