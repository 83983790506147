import React, { useState } from 'react';
import { TLineEditsAnalysis } from '../../../api/generated/types/common';
import styles from '../styles.module.css';
import { Card } from '../../Card/Card';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Button from '../../design-system/Button/Button';
import { InfoSection } from '../../InfoSection/InfoSection';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { countBy } from 'lodash';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import {
  BookwideEmotionalFlow,
  ChapterEmotionalAnalysis,
} from 'components/EmotionalAnalysis/EmotionalAnalysis';

interface LineEditsAnalysisSectionProps {
  analysis: TLineEditsAnalysis | null;
  isPending: boolean;
  isAnalyzing: boolean;
  onStartAnalysis: () => void;
}

function ActionVerbsChart({ verbs }: { verbs: string[] }) {
  // Count frequency of each verb
  const verbCounts = countBy(verbs);

  // Convert to array and sort by count
  const sortedVerbs = Object.entries(verbCounts)
    .map(([verb, count]) => ({ verb, count: count as number }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 10); // Get top 10

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={sortedVerbs}
        margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="verb"
          angle={-45}
          textAnchor="end"
          height={60}
          interval={0}
        />
        <YAxis allowDecimals={false} />
        <Tooltip />
        <Bar dataKey="count" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
}

function BookwideActionDensity({
  chapters,
}: {
  chapters: TLineEditsAnalysis['chapters'];
}) {
  const data = chapters.map((chapter, index) => ({
    name: chapter.title.startsWith('Chapter')
      ? chapter.title
      : `Chapter ${chapter.title}`,
    actionDensity: chapter.metrics.action_density,
    wordCount: chapter.metrics.word_count,
  }));

  const getBarColor = (actionDensity: number) => {
    if (actionDensity > 100) return '#4CAF50'; // High - Green
    if (actionDensity >= 70) return '#FFA726'; // Moderate - Orange
    return '#EF5350'; // Low - Red
  };

  return (
    <>
      <Card className={styles.listCard}>
        <h4>Book-wide Action Density Analysis</h4>
        <div className={styles.chartContainer}>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 40, bottom: 60 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                angle={-45}
                textAnchor="end"
                height={100}
                interval={0}
              />
              <YAxis
                type="number"
                domain={[0, 'auto']}
                label={{
                  value: 'Action Density',
                  angle: -90,
                  position: 'insideLeft',
                }}
              />
              <Tooltip
                formatter={(value: number, name: string, props: any) => {
                  if (name === 'actionDensity') {
                    return [
                      `${value.toFixed(1)} verbs per 1000 words`,
                      'Action Density',
                    ];
                  }
                  return [value];
                }}
              />
              <Bar dataKey="actionDensity" radius={[4, 4, 0, 0]}>
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={getBarColor(entry.actionDensity)}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Card>

      <Card className={styles.listCard}>
        <h4>Action Density Guide</h4>
        <div className={styles.actionDensityGuide}>
          <section>
            <h5 style={{ color: '#4CAF50' }}>High Action Density</h5>
            <p className={styles.densityRange}>
              (Approximately 100-150+ verbs per 1000 words)
            </p>
            <ul>
              <li>
                <strong>Thriller & Suspense:</strong> These genres likely have
                the highest verb density, potentially exceeding 150 verbs per
                1000 words due to their fast-paced nature.
              </li>
              <li>
                <strong>Action & Adventure:</strong> Similar to thrillers, these
                might have around 130-150 verbs per 1000 words to maintain high
                energy throughout the narrative.
              </li>
            </ul>
          </section>

          <section>
            <h5 style={{ color: '#FFA726' }}>Moderate Action Density</h5>
            <p className={styles.densityRange}>
              (Approximately 70-100 verbs per 1000 words)
            </p>
            <ul>
              <li>
                <strong>Mystery & Detective:</strong> These genres might have
                around 80-100 verbs per 1000 words, balancing action with
                descriptive and investigative elements.
              </li>
              <li>
                <strong>Science Fiction & Fantasy:</strong> Depending on the
                subgenre, these might range from 70-90 verbs per 1000 words,
                mixing action with world-building and character development.
              </li>
            </ul>
          </section>

          <section>
            <h5 style={{ color: '#EF5350' }}>Lower Action Density</h5>
            <p className={styles.densityRange}>
              (Approximately 40-70 verbs per 1000 words)
            </p>
            <ul>
              <li>
                <strong>Romance:</strong> Might have around 50-70 verbs per 1000
                words, focusing more on emotional development than physical
                action.
              </li>
              <li>
                <strong>Historical Fiction:</strong> Possibly around 40-60 verbs
                per 1000 words, emphasizing description and character
                development over action.
              </li>
              <li>
                <strong>Literary Fiction:</strong> Likely on the lower end,
                perhaps 40-50 verbs per 1000 words, prioritizing complex
                sentence structures and descriptive language.
              </li>
            </ul>
          </section>

          <p className={styles.note}>
            <em>
              Note: These ranges are estimates based on general writing
              principles. Actual verb density can vary significantly depending
              on the specific work, author's style, and even within different
              sections of the same piece. The quality and impact of the writing
              depend not just on the quantity of verbs, but also on their
              strength and appropriateness to the context.
            </em>
          </p>
        </div>
      </Card>
    </>
  );
}

const getReadabilityLevel = (
  score: number
): {
  level: string;
  color: string;
  warning?: string;
} => {
  if (score >= 90) return { level: 'Very Easy', color: '#4ade80' }; // green-400
  if (score >= 80) return { level: 'Easy', color: '#86efac' }; // green-300
  if (score >= 70) return { level: 'Fairly Easy', color: '#bbf7d0' }; // green-200
  if (score >= 60) return { level: 'Standard', color: '#3b82f6' }; // blue-500
  if (score >= 50)
    return {
      level: 'Fairly Difficult',
      color: '#fde047',
      warning: 'Consider simplifying some sentences',
    }; // yellow-300
  if (score >= 30)
    return {
      level: 'Difficult',
      color: '#fb923c',
      warning: 'Text may be hard to understand',
    }; // orange-400
  return {
    level: 'Very Confusing',
    color: '#ef4444',
    warning: 'Text might be too complex for most readers',
  }; // red-500
};

export function LineEditsAnalysisSection({
  analysis,
  isPending,
  isAnalyzing,
  onStartAnalysis,
}: LineEditsAnalysisSectionProps) {
  const chapters = analysis?.chapters || [];
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const currentChapter = chapters[currentChapterIndex];

  if (isPending) {
    return (
      <div className={styles.pendingAnalysis}>
        <p>
          Your line-by-line will be ready in a few minutes, hang tight and we'll
          refresh when they are ready.
        </p>
      </div>
    );
  }

  if (!analysis || chapters.length === 0) {
    return (
      <div className={styles.noAnalysis}>
        <InfoSection sectionId="line_edits_analysis">
          <p>
            Line-by-Line Analysis provides detailed insights into your
            manuscript's structure and style on a chapter-by-chapter basis. This
            analysis helps you:
            <ul>
              <li>Track chapter structure and organization</li>
              <li>Monitor word and token counts</li>
              <li>Review chapter titles and subtitles</li>
              <li>Analyze chapter-specific metrics</li>
            </ul>
          </p>
        </InfoSection>
        <p>No line-by-line Analysis available.</p>
        <Button
          onClick={onStartAnalysis}
          disabled={isAnalyzing}
          variant="primary"
          size="medium"
        >
          {isAnalyzing ? 'Analyzing...' : 'Start Analysis'}
        </Button>
      </div>
    );
  }

  const handlePrevChapter = () => {
    setCurrentChapterIndex(prev => Math.max(0, prev - 1));
  };

  const handleNextChapter = () => {
    setCurrentChapterIndex(prev => Math.min(chapters.length - 1, prev + 1));
  };
  console.log(currentChapter.metrics);

  return (
    <div className={styles.analysisContainer}>
      <InfoSection sectionId="line_edits_analysis">
        <p>
          Line-by-Line Analysis provides detailed insights into your
          manuscript's structure and style on a chapter-by-chapter basis. This
          analysis helps you:
          <ul>
            <li>Track chapter structure and organization</li>
            <li>Monitor word and token counts</li>
            <li>Review chapter titles and subtitles</li>
            <li>Analyze chapter-specific metrics</li>
          </ul>
        </p>
      </InfoSection>

      <BookwideEmotionalFlow chapters={chapters} />

      <BookwideActionDensity chapters={chapters} />

      <Card className={styles.listCard}>
        <h4>Chapter-by-Chapter Analysis</h4>
        <div className={styles.chapterNavigation}>
          <button
            onClick={handlePrevChapter}
            disabled={currentChapterIndex === 0}
            className={styles.navButton}
          >
            <ChevronLeft size={24} />
          </button>
          <h3>
            {currentChapter.title.startsWith('Chapter')
              ? currentChapter.title
              : `Chapter ${currentChapter.title}`}
            {currentChapter.subtitle && (
              <span className={styles.subtitle}>
                {' '}
                - {currentChapter.subtitle}
              </span>
            )}
          </h3>
          <button
            onClick={handleNextChapter}
            disabled={currentChapterIndex === chapters.length - 1}
            className={styles.navButton}
          >
            <ChevronRight size={24} />
          </button>
        </div>

        <div className={styles.metricsGrid}>
          <Card className={styles.metricCard}>
            <h4>Word Count</h4>
            <p>{currentChapter.metrics.word_count.toLocaleString()}</p>
          </Card>
          <Card className={styles.metricCard}>
            {(() => {
              const score = currentChapter.metrics.readability_score;
              const { level, color, warning } = getReadabilityLevel(score);
              return (
                <>
                  <h4>
                    Readability Score
                    <InfoTooltip
                      text={`${level}${warning ? ` - ${warning}` : ''}. The Flesch Reading Ease Score measures text readability. Scores range from very easy (>90) to very confusing (<30). Target range is 60-69 for standard reading level.`}
                      position="right"
                    />
                  </h4>
                  <p style={{ color }}>{score.toFixed(1)}</p>
                </>
              );
            })()}
          </Card>
          <Card className={styles.metricCard}>
            <h4>Grade Level</h4>
            <p>{currentChapter.metrics.grade_level}</p>
          </Card>
          <Card className={styles.metricCard}>
            <h4>Reading Time</h4>
            <p>
              {(currentChapter.metrics.reading_time / 60).toFixed(1)} minutes
            </p>
          </Card>
          <Card className={styles.metricCard}>
            <h4>
              Action Density{' '}
              <InfoTooltip
                text="verbs per 1000 words"
                position="right"
                symbol="doubledagger"
              />
            </h4>

            <p>{currentChapter.metrics.action_density.toFixed(1)}</p>
          </Card>
          {/* TODO: Add this back in when the dialogue data is better */}
          {/* <Card className={styles.metricCard}>
          <h4>
            Dialogue Ratio
            <InfoTooltip
              text="percentage of dialogue in text"
              position="right"
              symbol="pilcrow"
            />
          </h4>
          <p>{(currentChapter.metrics.dialogue_ratio * 100).toFixed(1)}%</p>
        </Card> */}
        </div>

        {currentChapter.metrics.action_verbs.length > 0 && (
          <div className={styles.listsContainer}>
            <Card className={styles.listCard}>
              <h4>Action Verbs</h4>
              <div className={styles.chartContainer}>
                <ActionVerbsChart verbs={currentChapter.metrics.action_verbs} />
              </div>
            </Card>
          </div>
        )}
        <ChapterEmotionalAnalysis
          emotionalData={currentChapter.metrics.emotions}
          chapterTitle={currentChapter.title}
        />
      </Card>
    </div>
  );
}
