// src/pages/Admin/JobQueueManagement/components/JobDetails.tsx
import React from 'react';
import { useGetJobDetailsQuery } from '../../../../api/generated/jobQueueApi';
import styles from './JobDetails.module.css';
import { getStatusBadgeClass } from './JobList';

interface JobDetailsProps {
  jobId: string;
  onRetry: (jobId: string) => void;
  onCancel: (jobId: string) => void;
  isRetrying: boolean;
  isCancelling: boolean;
  formatDate: (date: string) => string;
}

const JobDetails: React.FC<JobDetailsProps> = ({
  jobId,
  onRetry,
  onCancel,
  isRetrying,
  isCancelling,
  formatDate,
}) => {
  const { data: job, isLoading, isError } = useGetJobDetailsQuery(jobId);

  if (isLoading) {
    return <div className={styles.loading}>Loading job details...</div>;
  }

  if (isError || !job) {
    return <div className={styles.error}>Failed to load job details</div>;
  }

  // Determine if job can be retried or cancelled
  const canRetry = job.status === 'failed' || job.status === 'canceled';
  const canCancel =
    job.status === 'blocked' ||
    job.status === 'pending' ||
    job.status === 'processing';

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Job Details</h3>
        <span
          className={`${styles.statusBadge} ${getStatusBadgeClass(job.status)}`}
        >
          {job.status}
        </span>
      </div>

      <div className={styles.content}>
        <div className={styles.section}>
          <h4>Basic Information</h4>
          <div className={styles.infoGrid}>
            <div className={styles.infoItem}>
              <span className={styles.label}>Job Name</span>
              <span className={styles.value}>{job.name}</span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.label}>Job ID</span>
              <span className={styles.value}>{job.id}</span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.label}>Created At</span>
              <span className={styles.value}>{formatDate(job.createdAt)}</span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.label}>Updated At</span>
              <span className={styles.value}>{formatDate(job.updatedAt)}</span>
            </div>
            {job.pendingAt && (
              <div className={styles.infoItem}>
                <span className={styles.label}>Started At</span>
                <span className={styles.value}>
                  {formatDate(job.pendingAt)}
                </span>
              </div>
            )}
            {job.scheduledAt && (
              <div className={styles.infoItem}>
                <span className={styles.label}>Scheduled At</span>
                <span className={styles.value}>
                  {formatDate(job.scheduledAt)}
                </span>
              </div>
            )}
            <div className={styles.infoItem}>
              <span className={styles.label}>Retry Count</span>
              <span className={styles.value}>
                {job.retryNum} / {job.maxRetries}
              </span>
            </div>
            <div className={styles.infoItem}>
              <span className={styles.label}>Priority</span>
              <span className={styles.value}>{job.priority}</span>
            </div>
            {job.fifoKey && (
              <div className={styles.infoItem}>
                <span className={styles.label}>FIFO Key</span>
                <span className={styles.value}>{job.fifoKey}</span>
              </div>
            )}
          </div>
        </div>

        {job.payload && (
          <div className={styles.section}>
            <h4>Payload</h4>
            <pre className={styles.codeBlock}>
              {JSON.stringify(job.payload, null, 2)}
            </pre>
          </div>
        )}

        {job.executionDetails && (
          <div className={styles.section}>
            <h4>Execution Details</h4>
            <pre className={styles.codeBlock}>{job.executionDetails}</pre>
          </div>
        )}

        {job.status === 'failed' && (
          <div className={styles.section}>
            <h4>Error</h4>
            <div className={styles.errorMessage}>
              <p className={styles.errorTitle}>Execution failed</p>
              {job.executionDetails && (
                <pre className={styles.errorStack}>{job.executionDetails}</pre>
              )}
            </div>
          </div>
        )}

        <div className={styles.actions}>
          {canRetry && (
            <button
              className={`${styles.actionButton} ${styles.retryButton}`}
              onClick={() => onRetry(job.id)}
              disabled={isRetrying}
            >
              {isRetrying ? 'Retrying...' : 'Retry Job'}
            </button>
          )}
          {canCancel && (
            <button
              className={`${styles.actionButton} ${styles.cancelButton}`}
              onClick={() => onCancel(job.id)}
              disabled={isCancelling}
            >
              {isCancelling ? 'Cancelling...' : 'Cancel Job'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
