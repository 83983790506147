// src/pages/Product/ManuscriptAnalysis/ManuscriptAnalysis.tsx
import { Star, BookOpen, LineChart, Users, Brain } from 'lucide-react';
import styles from '../EditorialAnalysis/EditorialAnalysis.module.css'; // Reusing the same styles
import ImageCarousel, {
  CarouselImage,
} from '../../../components/ImageCarousel/ImageCarousel';

const ManuscriptAnalysis = () => {
  const exampleImages: CarouselImage[] = [
    {
      src: '/images/woven-character-evaluation.png',
      alt: 'Character evaluation analysis showing relationship mapping and development arcs',
      caption: 'Deep character analysis with relationship mapping',
    },
    {
      src: '/images/woven-overall-evaluation.png',
      alt: 'Overall manuscript evaluation dashboard with key metrics and scores',
      caption: 'Comprehensive manuscript evaluation overview',
    },
    {
      src: '/images/woven-plot-evaluation.png',
      alt: 'Plot structure evaluation showing story arc and pacing analysis',
      caption: 'Plot structure and pacing visualization',
    },
  ];

  return (
    <div className={styles.container}>
      {/* Hero Section */}
      <div className={styles.hero}>
        <h1 className={styles.heroTitle}>Manuscript Analysis</h1>
        <p className={styles.heroSubtitle}>
          Advanced manuscript evaluation to identify promising works and assess
          literary merit
        </p>
      </div>

      {/* Key Features */}
      <div className={styles.features}>
        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <Star className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Comprehensive Scoring</h3>
          </div>
          <p className={styles.featureDescription}>
            Receive an overall manuscript score based on multiple factors,
            helping agents and publishers prioritize their review process
            effectively.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <Users className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Character Analysis</h3>
          </div>
          <p className={styles.featureDescription}>
            Evaluate character development, relationships, and arc progression
            throughout the manuscript with detailed insights.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <LineChart className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Pacing Assessment</h3>
          </div>
          <p className={styles.featureDescription}>
            Analyze story pacing, tension curves, and narrative flow to ensure
            engaging reader experience from start to finish.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <Brain className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Plot Development</h3>
          </div>
          <p className={styles.featureDescription}>
            Get insights into plot structure, narrative coherence, and story arc
            development throughout your manuscript.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <BookOpen className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Promises</h3>
          </div>
          <p className={styles.featureDescription}>
            Check what promises readers are expecting and whether or not each is
            fulfilled in a satisfactory way.
          </p>
        </div>
      </div>

      {/* How to Use Section */}
      <div className={styles.howToUse}>
        <h2 className={styles.sectionTitle}>
          Making the Most of Your Manuscript Analysis
        </h2>

        <div className={styles.content}>
          <p className={styles.introText}>
            Our manuscript analysis tool provides comprehensive insights into
            your work's strengths and areas for improvement. We evaluate
            multiple aspects of your manuscript to help you understand its
            market potential and literary merit.
          </p>

          <div className={styles.usageGrid}>
            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>1. Overall Manuscript Score</h3>
              <p>
                Each manuscript receives a comprehensive score based on multiple
                factors including plot development, character depth, writing
                style, and market potential. This score helps agents and
                publishers quickly identify promising works.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>2. Character Development</h3>
              <p>
                Get detailed analysis of your characters' depth, growth, and
                relationships. Our tool evaluates character arcs, dialogue
                patterns, and emotional development throughout the story.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>3. Narrative Structure</h3>
              <p>
                Understand how your plot unfolds with detailed pacing analysis,
                tension mapping, and story arc evaluation. Identify potential
                pacing issues and ensure your story maintains reader engagement.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>4. Literary Elements</h3>
              <p>
                Receive insights on various literary elements including theme
                development, symbolism, and writing style. Understand how these
                elements contribute to your manuscript's overall impact.
              </p>
            </div>
          </div>

          <div className={styles.exampleContainer}>
            <ImageCarousel images={exampleImages} autoAdvanceInterval={5000} />
          </div>
        </div>
      </div>

      {/* Additional Info Section */}
      <div className={styles.howToUse}>
        <h2 className={styles.sectionTitle}>For Agents and Publishers</h2>
        <div className={styles.content}>
          <p className={styles.introText}>
            Our manuscript analysis tool is designed to streamline your
            submission review process. By providing objective analysis of key
            literary elements, you can quickly identify promising manuscripts
            and focus your valuable time on the most potential-rich submissions.
          </p>

          <div className={styles.usageGrid}>
            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>Efficient Screening</h3>
              <p>
                Use our overall manuscript score to quickly identify submissions
                that meet your quality thresholds and genre requirements.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>Detailed Reports</h3>
              <p>
                Access comprehensive reports on each manuscript, including
                detailed breakdowns of plot, character development, and market
                potential.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>Market Analysis</h3>
              <p>
                Understand how each manuscript aligns with current market trends
                and reader preferences in its genre.
              </p>
            </div>

            <div className={`${styles.featureCard} ${styles.comingSoonCard}`}>
              <h3 className={styles.usageTitle}>Batch Processing</h3>
              <p>
                Process multiple manuscripts simultaneously and compare their
                scores and analyses to efficiently manage your submission queue.
              </p>
              <div className={styles.comingSoonBadge}>Coming Soon</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManuscriptAnalysis;
