// src/pages/SeriesWiki/CharacterManagement.tsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TCharacterResponse } from '../../api/generated/types/common';
import { get } from '../../api/apiUtils';
import './CharacterManagement.css';

const CharacterManagement: React.FC = () => {
  const { seriesId } = useParams<{ seriesId: string }>();
  const navigate = useNavigate();
  const [characters, setCharacters] = useState<TCharacterResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterRole, setFilterRole] = useState<string>('all');

  useEffect(() => {
    const fetchCharacters = async () => {
      try {
        setLoading(true);
        const data = await get<TCharacterResponse[]>(
          `/wiki/characters/by-series/${seriesId || ''}`
        );
        setCharacters(data);
        setError(null);
      } catch (err) {
        console.error('Error fetching characters:', err);
        setError('Failed to load characters. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (seriesId) {
      fetchCharacters();
    }
  }, [seriesId]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleRoleFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterRole(e.target.value);
  };

  const filteredCharacters = characters.filter(character => {
    const matchesSearch =
      character.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      character.aliases?.some((alias: string) =>
        alias.toLowerCase().includes(searchTerm.toLowerCase())
      );

    const matchesRole = filterRole === 'all' || character.role === filterRole;

    return matchesSearch && matchesRole;
  });

  const handleAddCharacter = () => {
    navigate(`/series/${seriesId}/characters/new`);
  };

  const handleViewCharacter = (characterId: string) => {
    navigate(`/series/${seriesId}/characters/${characterId}`);
  };

  if (loading) {
    return <div className="loading">Loading characters...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="character-management">
      <div className="character-management-header">
        <h1>Characters</h1>
        <button onClick={handleAddCharacter} className="add-character-btn">
          Add Character
        </button>
      </div>

      <div className="character-filters">
        <input
          type="text"
          placeholder="Search characters..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />

        <select
          value={filterRole}
          onChange={handleRoleFilter}
          className="role-filter"
        >
          <option value="all">All Roles</option>
          <option value="protagonist">Protagonist</option>
          <option value="antagonist">Antagonist</option>
          <option value="supporting">Supporting</option>
          <option value="mentor">Mentor</option>
          <option value="sidekick">Sidekick</option>
          <option value="villain">Villain</option>
        </select>
      </div>

      <div className="character-grid">
        {filteredCharacters.map(character => (
          <div
            key={character.id}
            className="character-card"
            onClick={() => handleViewCharacter(character.id)}
          >
            <h3>{character.name}</h3>
            {character.role && (
              <span className="character-role">{character.role}</span>
            )}
            <p className="character-summary">
              {character.background
                ? character.background.substring(0, 150) + '...'
                : 'No background available'}
            </p>
            {character.aliases && character.aliases.length > 0 && (
              <div className="character-aliases">
                Also known as: {character.aliases.join(', ')}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CharacterManagement;
