import { TMarketplace } from 'api/generated/types/common';

export const marketplaceToString = (marketplace: TMarketplace): string => {
  switch (marketplace) {
    case 'amazonCom':
      return 'United States';
    case 'amazonUk':
      return 'United Kingdom';
    case 'amazonCa':
      return 'Canada';
    case 'amazonAu':
      return 'Australia';
    case 'amazonIn':
      return 'India';
    case 'amazonJp':
      return 'Japan';
    case 'amazonEs':
      return 'Spain';
    case 'amazonFr':
      return 'France';
    case 'amazonDe':
      return 'Germany';
    case 'amazonIt':
      return 'Italy';
    case 'amazonBr':
      return 'Brazil';
    case 'amazonNl':
      return 'Netherlands';
    case 'amazonMx':
      return 'Mexico';
  }
};
