import React from 'react';
import { CollapsibleSection } from '../CollapsibleSection/CollapsibleSection';
import { ScoredSections } from '../ScoredSection/ScoredSections';
import styles from './EvaluationSection.module.css';
import { TScoredSection } from '../../api/generated/types/common';
import { Download } from 'lucide-react';

type BaseEvaluation = {
  verdict: boolean;
  confidence: number;
  scoredSections?: TScoredSection[];
};

type OverallEvaluation = BaseEvaluation & {
  assessment: string;
  score: number;
  supportingArgument: string;
  opposingArgument: string;
  editorialLetterLink: string;
  commentsLink: string;
};

type EvaluationSectionProps = {
  sectionName: string;
  evaluation: OverallEvaluation | BaseEvaluation | null;
  isOpen: boolean;
  onToggle: () => void;
  showVerdict: boolean;
};

const EvaluationSection: React.FC<EvaluationSectionProps> = ({
  sectionName,
  evaluation,
  isOpen,
  onToggle,
  showVerdict,
}) => {
  if (!evaluation) return null;

  // Helper to format confidence as percentage
  const formatConfidence = (confidence: number) => `${confidence}%`;

  // Helper to determine confidence class
  const getConfidenceClass = (confidence: number) => {
    if (confidence >= 0.8) return styles.confidenceHigh;
    if (confidence >= 0.6) return styles.confidenceMedium;
    return styles.confidenceLow;
  };

  const DownloadLink = ({ url, label }: { url: string; label: string }) => (
    <a
      href={url}
      className={styles.downloadLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Download size={16} />
      <span>{label}</span>
    </a>
  );

  return (
    <CollapsibleSection title={sectionName} isOpen={isOpen} onToggle={onToggle}>
      <div className={styles.contentWrapper}>
        {'editorialLetterLink' in evaluation &&
          evaluation.editorialLetterLink && (
            <div className={styles.downloadLinks}>
              <DownloadLink
                url={evaluation.editorialLetterLink}
                label="Download Editorial Letter"
              />
              {evaluation.commentsLink && (
                <DownloadLink
                  url={evaluation.commentsLink}
                  label="Download Comments"
                />
              )}
            </div>
          )}
        <div className={styles.evaluationHeader}>
          {showVerdict && (
            <p className={styles.evaluationItem}>
              <strong>Verdict:</strong>{' '}
              <span>{evaluation.verdict ? '👍' : '👎'}</span>
            </p>
          )}
          <p className={styles.confidenceWrapper}>
            <strong>Confidence:</strong>{' '}
            <span className={getConfidenceClass(evaluation.confidence)}>
              {formatConfidence(evaluation.confidence)}
            </span>
          </p>
        </div>

        {'score' in evaluation && (
          <p className={styles.evaluationItem}>
            <strong>Score:</strong> {evaluation.score}
          </p>
        )}

        {'assessment' in evaluation && (
          <p className={styles.evaluationItem}>
            <strong>Assessment:</strong> {evaluation.assessment}
          </p>
        )}

        {'supportingArgument' in evaluation && (
          <p className={styles.evaluationItem}>
            <strong>Supporting Argument:</strong>{' '}
            {evaluation.supportingArgument}
          </p>
        )}

        {'opposingArgument' in evaluation && (
          <p className={styles.evaluationItem}>
            <strong>Opposing Argument:</strong> {evaluation.opposingArgument}
          </p>
        )}

        {evaluation.scoredSections && (
          <ScoredSections sections={evaluation.scoredSections} />
        )}
      </div>
    </CollapsibleSection>
  );
};

export default EvaluationSection;
