// src/pages/Admin/JobQueueManagement/components/JobQueueStats.tsx
import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { JobQueueStats as JobQueueStatsType } from '../../../../api/generated/jobQueueApi';
import styles from './JobQueueStats.module.css';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface JobQueueStatsProps {
  stats?: JobQueueStatsType;
  isLoading: boolean;
  isError: boolean;
}

const JobQueueStats: React.FC<JobQueueStatsProps> = ({
  stats,
  isLoading,
  isError,
}) => {
  if (isLoading) {
    return <div className={styles.loading}>Loading statistics...</div>;
  }

  if (isError || !stats) {
    return <div className={styles.error}>Failed to load statistics</div>;
  }

  // Prepare throughput chart data
  const throughputData: ChartData<'line'> = {
    labels: stats.throughputPerHour.map(item => {
      const date = new Date(item.timestamp);
      return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
    }),
    datasets: [
      {
        label: 'Jobs Processed',
        data: stats.throughputPerHour.map(item => item.count),
        borderColor: 'var(--color-primary-green)',
        backgroundColor: 'rgba(44, 95, 45, 0.1)',
        tension: 0.4,
        fill: true,
      },
    ],
  };

  // Prepare failure rate chart data
  const failureRateData: ChartData<'line'> = {
    labels: stats.failureRatePerHour.map(item => {
      const date = new Date(item.timestamp);
      return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
    }),
    datasets: [
      {
        label: 'Failure Rate (%)',
        data: stats.failureRatePerHour.map(item => item.rate * 100), // Convert to percentage
        borderColor: 'var(--color-error)',
        backgroundColor: 'rgba(220, 38, 38, 0.1)',
        tension: 0.4,
        fill: true,
      },
    ],
  };

  // Chart options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 5,
        },
      },
      x: {
        ticks: {
          maxTicksLimit: 8,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          boxWidth: 10,
          padding: 10,
        },
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (context: any) {
            const label = context.dataset.label || '';
            const value = context.parsed.y;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.statsGrid}>
        <div className={styles.statCard}>
          <h3>Total Jobs</h3>
          <p className={styles.statValue}>{stats.total}</p>
        </div>
        <div className={styles.statCard}>
          <h3>Completed</h3>
          <p className={`${styles.statValue} ${styles.completed}`}>
            {stats.completed}
          </p>
        </div>
        <div className={styles.statCard}>
          <h3>Failed</h3>
          <p className={`${styles.statValue} ${styles.failed}`}>
            {stats.failed}
          </p>
        </div>
        <div className={styles.statCard}>
          <h3>Queued</h3>
          <p className={`${styles.statValue} ${styles.queued}`}>
            {stats.queued}
          </p>
        </div>
        <div className={styles.statCard}>
          <h3>Pending</h3>
          <p className={`${styles.statValue} ${styles.pending}`}>
            {stats.pending}
          </p>
        </div>
        <div className={styles.statCard}>
          <h3>Avg. Wait Time</h3>
          <p className={styles.statValue}>
            {stats.averageWaitTimeSeconds.toFixed(2)}s
          </p>
        </div>
      </div>

      <div className={styles.chartsContainer}>
        <div className={styles.chartWrapper}>
          <h3>Throughput</h3>
          <div className={styles.chart}>
            <Line data={throughputData} options={chartOptions} />
          </div>
        </div>
        <div className={styles.chartWrapper}>
          <h3>Failure Rate</h3>
          <div className={styles.chart}>
            <Line data={failureRateData} options={chartOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobQueueStats;
