// src/pages/Submissions.tsx
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FileUploader } from '../../components/FileUploader';
import { FileList } from '../../components/FileList';
import { post } from '../../api/apiUtils';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpiner';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import styles from './Submissions.module.css';
import { RootState } from '../../store/reducers/rootReducer';
import InfoTooltip from '../../components/InfoTooltip/InfoTooltip';
import { TConfirmUploadResponse, TAPIBook } from 'api/generated/types/common';

const Submissions: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state: RootState) => state.userAuth.user);
  const orgId = user?.organizationId!;
  const [books, setBooks] = useState<TAPIBook[]>([]);
  const [success, setSuccess] = useState<{ success: boolean | undefined }>({
    success: undefined,
  });

  const handleFileUpload = (response: TConfirmUploadResponse) => {
    if (response.tag !== 'confirmUploadBooks') {
      throw new Error(
        'Unexpected response type. Expected confirmUploadRoyalties.'
      );
    }
    // Now TypeScript knows response is IConfirmUploadBooks
    setBooks(
      response.contents.books.sort(
        (a, b) =>
          new Date(b.lastUploadDate).getTime() -
          new Date(a.lastUploadDate).getTime()
      )
    );
  };

  const handleUploadError = (error: string) => {
    console.error('Upload error:', error);
    setSuccess({ success: false });
  };

  useEffect(() => {
    try {
      post<{ books: TAPIBook[] }>(`/files/${orgId}/all`, {})
        .then(response => {
          // Sort the books so the most recently uploaded is first
          setBooks(
            response.books.sort(
              (a, b) =>
                new Date(b.lastUploadDate).getTime() -
                new Date(a.lastUploadDate).getTime()
            )
          );
        })
        .catch(error => {
          console.error('Error fetching files:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      console.error('Error fetching files:', error);
      setIsLoading(false);
    }
  }, [orgId]);

  const topFiveScores = (files: TAPIBook[]) => {
    if (!files || files.length === 0) return [];
    return [...files]
      .filter(file => file.status === 'bookPending')
      .sort((a, b) => (b.overallScore || 0) - (a.overallScore || 0))
      .slice(0, 5);
  };

  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        <div className={styles.workingContainer}>
          <div className={styles.titleContainer}>
            <h2 className={styles.sectionTitle}>All Manuscripts</h2>
            <InfoTooltip
              text="Manuscripts you're working on. One way or another."
              position="right"
              symbol="dagger"
            />
          </div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <FileList files={books} linkLocation="editorial" />
          )}
        </div>
        <div>
          <FileUploader
            onFileUpload={handleFileUpload}
            onUploadError={handleUploadError}
            existingFiles={books}
            allowedFileTypes={['.docx']}
            filePurpose="bookEval"
          />
          {!success && <ErrorMessage message={'File upload failed'} />}
        </div>
      </div>
      <div className={styles.grid}>
        <div>
          <div className={styles.titleContainer}>
            <h2 className={styles.sectionTitle}>Best Pending Manuscripts</h2>
            <InfoTooltip
              text="Pending manuscripts with the highest overall rating. These are where you should start your search."
              position="right"
              symbol="doubledagger"
            />
          </div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <FileList files={topFiveScores(books)} linkLocation="file" />
          )}
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Submissions;
