import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  TCharacterRelationshipResponse,
  TCharacterResponse,
  TLocationResponse,
  TSeriesRequest,
  TAPIBook,
  TListAllFilesResponse,
  TGenre,
} from '../../api/generated/types/common';
import { get, post } from '../../api/apiUtils';
import './SeriesView.css';
import Button from 'components/design-system/Button/Button';
import seriesApi from 'services/api/seriesApi';
import { Combobox } from 'components/design-system/Combobox/Combobox';
import CharacterRelationshipGraph from 'components/CharacterRelationshipGraph/CharacterRelationshipGraph';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers/rootReducer';

interface SeriesData extends TSeriesRequest {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  books?: TAPIBook[];
}

interface LocationWithChildren extends TLocationResponse {
  children: LocationWithChildren[];
}

const SeriesView: React.FC = () => {
  const navigate = useNavigate();
  const { seriesId } = useParams<{ seriesId: string }>();
  const user = useSelector((state: RootState) => state.userAuth.user);
  const orgId = user?.organizationId!;
  const [searchParams, setSearchParams] = useSearchParams();
  const [formData, setFormData] = useState<SeriesData | null>(null);
  const [originalData, setOriginalData] = useState<SeriesData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>(
    searchParams.get('tab') || 'basic'
  );
  const [saving, setSaving] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  // Character management state
  const [characters, setCharacters] = useState<TCharacterResponse[]>([]);
  const [characterRelationships, setCharacterRelationships] = useState<
    TCharacterRelationshipResponse[]
  >([]);
  const [characterLoading, setCharacterLoading] = useState<boolean>(false);
  const [characterError, setCharacterError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterRole, setFilterRole] = useState<string>('all');
  const [showCharacterRelationships, setShowCharacterRelationships] =
    useState<boolean>(false);

  // Location management state
  const [locations, setLocations] = useState<TLocationResponse[]>([]);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [locationError, setLocationError] = useState<string | null>(null);
  const [locationSearchTerm, setLocationSearchTerm] = useState<string>('');
  const [filterLocationType, setFilterLocationType] = useState<string>('all');

  // Book management state
  const [availableBooks, setAvailableBooks] = useState<TAPIBook[]>([]);
  const [bookLoading, setBookLoading] = useState<boolean>(false);
  const [bookError, setBookError] = useState<string | null>(null);
  const [bookSearchTerm, setBookSearchTerm] = useState<string>('');
  const [addingBook, setAddingBook] = useState<boolean>(false);
  const [removingBook, setRemovingBook] = useState<boolean>(false);

  // Track if there are unsaved changes
  const hasChanges = useMemo(() => {
    if (!formData || !originalData) return false;
    return JSON.stringify(formData) !== JSON.stringify(originalData);
  }, [formData, originalData]);

  const fetchCharacters = useCallback(async () => {
    if (!seriesId) return;
    try {
      setCharacterLoading(true);
      const data = await get<TCharacterResponse[]>(
        `/wiki/characters/by-series/${seriesId}`
      );
      setCharacters(data);
      setCharacterError(null);
    } catch (err) {
      console.error('Error fetching characters:', err);
      setCharacterError('Failed to load characters. Please try again later.');
    } finally {
      setCharacterLoading(false);
    }
  }, [seriesId]);

  const fetchCharacterRelationships = useCallback(async () => {
    if (!seriesId) return;
    try {
      setCharacterLoading(true);
      const data = await get<TCharacterRelationshipResponse[]>(
        `/wiki/characters/by-series/${seriesId}/relationships`
      );
      setCharacterRelationships(data);
      setCharacterError(null);
    } catch (err) {
      console.error('Error fetching character relationships:', err);
      setCharacterError(
        'Failed to load character relationships. Please try again later.'
      );
    } finally {
      setCharacterLoading(false);
    }
  }, [seriesId]);

  const fetchLocations = useCallback(async () => {
    if (!seriesId) return;
    try {
      setLocationLoading(true);
      const data = await get<TLocationResponse[]>(
        `/wiki/locations/by-series/${seriesId}`
      );
      setLocations(data);
      setLocationError(null);
    } catch (err) {
      console.error('Error fetching locations:', err);
      setLocationError('Failed to load locations. Please try again later.');
    } finally {
      setLocationLoading(false);
    }
  }, [seriesId]);

  const fetchAvailableBooks = useCallback(async () => {
    try {
      setBookLoading(true);
      // Fetch all books that can be added to the series
      const data = await post<TListAllFilesResponse>(`/files/${orgId}/all`, {});
      setAvailableBooks(Array.isArray(data.books) ? data.books : []);
      setBookError(null);
    } catch (err) {
      console.error('Error fetching available books:', err);
      setBookError('Failed to load available books. Please try again later.');
      setAvailableBooks([]);
    } finally {
      setBookLoading(false);
    }
  }, [orgId]);

  const handleAddBookToSeries = async (bookId: string) => {
    if (!seriesId) return;
    try {
      setAddingBook(true);
      await post(`/wiki/series/${seriesId}/add/${bookId}`, {});
      await fetchAvailableBooks();
      await fetchSeriesData(seriesId);
      setBookError(null);
    } catch (err) {
      console.error('Error adding book to series:', err);
      setBookError('Failed to add book to series. Please try again later.');
    } finally {
      setAddingBook(false);
    }
  };

  const handleRemoveBookFromSeries = async (bookId: string) => {
    if (!seriesId) return;
    try {
      setRemovingBook(true);
      await post(`/wiki/series/${seriesId}/remove/${bookId}`, {});
      await fetchAvailableBooks();
      await fetchSeriesData(seriesId);
      setBookError(null);
    } catch (err) {
      console.error('Error removing book from series:', err);
      setBookError(
        'Failed to remove book from series. Please try again later.'
      );
    } finally {
      setRemovingBook(false);
    }
  };

  useEffect(() => {
    if (seriesId) {
      fetchSeriesData(seriesId);
      if (activeTab === 'characters') {
        fetchCharacters();
        fetchCharacterRelationships();
      } else if (activeTab === 'locations') {
        fetchLocations();
      } else if (activeTab === 'books') {
        fetchAvailableBooks();
      }
    }
  }, [
    seriesId,
    activeTab,
    fetchCharacters,
    fetchCharacterRelationships,
    fetchLocations,
    fetchAvailableBooks,
  ]);

  useEffect(() => {
    setSearchParams(prev => {
      const newParams = new URLSearchParams(prev);
      newParams.set('tab', activeTab);
      return newParams;
    });
  }, [activeTab, setSearchParams]);

  const fetchSeriesData = async (id: string) => {
    try {
      const series = await seriesApi.getSeries(id);
      const seriesData = {
        id: series.id,
        title: series.title,
        description: series.description,
        genre: series.genre,
        settingPhysical: series.settingPhysical,
        settingCultural: series.settingCultural,
        settingMagicSystem: series.settingMagicSystem,
        books: series.books,
      };
      setFormData(seriesData);
      setOriginalData(seriesData);
    } catch (err) {
      setError('Failed to load series data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!formData || !isEditing) return;
    const { name, value } = e.target;
    setFormData(prev => (prev ? { ...prev, [name]: value } : null));
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setShowCharacterRelationships(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData || !seriesId) return;

    setSaving(true);
    setError(null);

    try {
      const updateData: Partial<TSeriesRequest> = {
        title: formData.title,
        description: formData.description,
        genre: formData.genre,
        settingPhysical: formData.settingPhysical,
        settingCultural: formData.settingCultural,
        settingMagicSystem: formData.settingMagicSystem,
      };
      const responseObject = await seriesApi.updateSeries(seriesId, updateData);
      setOriginalData(responseObject);
      setIsEditing(false);
    } catch (err) {
      setError('Failed to save series');
      console.error(err);
    } finally {
      setSaving(false);
    }
  };

  const handleStartEditing = () => {
    setIsEditing(true);
  };

  const handleStopEditing = () => {
    if (hasChanges) {
      if (
        window.confirm(
          'You have unsaved changes. Are you sure you want to stop editing?'
        )
      ) {
        setFormData(originalData);
        setIsEditing(false);
      }
    } else {
      setIsEditing(false);
    }
  };

  const handleCreateCharacter = () => {
    if (!seriesId) return;
    navigate(`/series/${seriesId}/characters/new?returnTab=${activeTab}`);
  };

  const handleViewCharacter = (characterId: string) => {
    if (!seriesId) return;
    navigate(
      `/series/${seriesId}/characters/${characterId}?returnTab=${activeTab}`
    );
  };

  const handleCreateLocation = () => {
    if (!seriesId) return;
    navigate(`/series/${seriesId}/locations/new?returnTab=${activeTab}`);
  };

  const handleViewLocation = (locationId: string) => {
    if (!seriesId) return;
    navigate(
      `/series/${seriesId}/locations/${locationId}?returnTab=${activeTab}`
    );
  };

  const filteredCharacters = characters.filter(character => {
    const matchesSearch =
      character.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      character.aliases?.some(alias =>
        alias.toLowerCase().includes(searchTerm.toLowerCase())
      );

    const matchesRole = filterRole === 'all' || character.role === filterRole;

    return matchesSearch && matchesRole;
  });

  const organizeLocationsHierarchy = (
    locations: TLocationResponse[]
  ): LocationWithChildren[] => {
    const locationMap = new Map<string, LocationWithChildren>();
    const rootLocations: LocationWithChildren[] = [];

    // First pass: create enhanced location objects with children arrays
    locations.forEach(loc => {
      locationMap.set(loc.id, { ...loc, children: [] });
    });

    // Second pass: organize into hierarchy
    locations.forEach(loc => {
      const enhancedLoc = locationMap.get(loc.id)!;
      if (loc.parentLocation) {
        const parent = locationMap.get(loc.parentLocation);
        if (parent) {
          parent.children.push(enhancedLoc);
        } else {
          rootLocations.push(enhancedLoc);
        }
      } else {
        rootLocations.push(enhancedLoc);
      }
    });

    return rootLocations;
  };

  const LocationCard: React.FC<{
    location: LocationWithChildren;
    onViewLocation: (id: string) => void;
    depth?: number;
  }> = ({ location, onViewLocation, depth = 0 }) => (
    <div className={`location-card depth-${depth}`}>
      <div
        className="location-info"
        onClick={() => onViewLocation(location.id)}
      >
        <h3>{location.name}</h3>
        {location.locationType && (
          <div className="location-type">{location.locationType}</div>
        )}
        <p className="location-description">
          {location.description ||
            location.geography ||
            'No description available.'}
        </p>
      </div>
      {location.children.length > 0 && (
        <div className="nested-locations">
          {location.children.map(child => (
            <LocationCard
              key={child.id}
              location={child}
              onViewLocation={onViewLocation}
              depth={depth + 1}
            />
          ))}
        </div>
      )}
    </div>
  );

  const filteredLocations = useMemo(() => {
    const filtered = locations.filter(location => {
      return location.name
        .toLowerCase()
        .includes(locationSearchTerm.toLowerCase());
    });
    return organizeLocationsHierarchy(filtered);
  }, [locations, locationSearchTerm]);

  const filteredSeriesBooks = useMemo(() => {
    return originalData?.books?.filter(book =>
      book.title.toLowerCase().includes(bookSearchTerm.toLowerCase())
    );
  }, [bookSearchTerm, originalData]);

  const filteredAvailableBooks = useMemo(() => {
    // Filter available books that are not already in the series
    const seriesBookIds = originalData?.books?.map(book => book.id) || [];
    return Array.isArray(availableBooks)
      ? availableBooks
          .filter(book => !seriesBookIds.includes(book.id))
          .filter(book =>
            book.title.toLowerCase().includes(bookSearchTerm.toLowerCase())
          )
      : [];
  }, [availableBooks, originalData?.books, bookSearchTerm]);

  const formatGenreLabel = (genre: TGenre): string => {
    // Convert camelCase to space-separated words and capitalize first letter of each word
    return genre
      .replace(/([A-Z])/g, ' $1') // Add space before capital letters
      .replace(/^./, str => str.toUpperCase()) // Capitalize first letter
      .trim(); // Remove any leading/trailing spaces
  };

  const LITERARY_GENRES = (
    [
      'actionAdventure',
      'childrens',
      'contemporary',
      'crime',
      'drama',
      'fantasy',
      'historical',
      'horror',
      'literary',
      'mystery',
      'poetry',
      'romance',
      'scienceFiction',
      'thriller',
      'western',
      'youngAdult',
    ] as TGenre[]
  ).map(genre => ({
    label: formatGenreLabel(genre),
    value: genre,
  }));

  if (loading) {
    return <div className="loading">Loading series data...</div>;
  }

  if (!formData) {
    return <div className="error-message">Series not found</div>;
  }

  return (
    <div className="series-form-container">
      <div className="series-form-header">
        <div className="header-content">
          <h1>{formData.title}</h1>
          {isEditing ? (
            <Button
              variant="secondary"
              onClick={handleStopEditing}
              className="edit-toggle active"
            >
              {hasChanges ? 'Discard Changes' : 'Stop Editing'}
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={handleStartEditing}
              className="edit-toggle"
            >
              Edit Series
            </Button>
          )}
        </div>
        <Button
          variant="secondary"
          onClick={() => navigate('/series')}
          className="back-button"
        >
          Back to Series List
        </Button>
      </div>

      {error && <div className="error-message">{error}</div>}

      <form onSubmit={handleSubmit} className="series-form">
        <div className="tabs">
          <Button
            variant={activeTab === 'basic' ? 'primary' : 'secondary'}
            onClick={() => handleTabChange('basic')}
            className={`tab ${activeTab === 'basic' ? 'active' : ''}`}
          >
            Basics
          </Button>
          <Button
            variant={activeTab === 'details' ? 'primary' : 'secondary'}
            onClick={() => handleTabChange('details')}
            className={`tab ${activeTab === 'details' ? 'active' : ''}`}
          >
            Setting
          </Button>
          <Button
            variant={activeTab === 'characters' ? 'primary' : 'secondary'}
            onClick={() => handleTabChange('characters')}
            className={`tab ${activeTab === 'characters' ? 'active' : ''}`}
          >
            Characters
          </Button>
          <Button
            variant={activeTab === 'locations' ? 'primary' : 'secondary'}
            onClick={() => handleTabChange('locations')}
            className={`tab ${activeTab === 'locations' ? 'active' : ''}`}
          >
            Locations
          </Button>
          <Button
            variant={activeTab === 'books' ? 'primary' : 'secondary'}
            onClick={() => handleTabChange('books')}
            className={`tab ${activeTab === 'books' ? 'active' : ''}`}
          >
            Books
          </Button>
        </div>

        <div className="tab-content">
          {activeTab === 'basic' && (
            <div className="tab-panel">
              <div className="form-group">
                <label htmlFor="title">Series Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  className="form-control"
                  disabled={!isEditing}
                />
              </div>

              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description || ''}
                  onChange={handleInputChange}
                  className="form-control"
                  rows={5}
                  disabled={!isEditing}
                />
              </div>

              <div className="form-group">
                <label htmlFor="genre">Primary Genre</label>
                <Combobox
                  id="genre"
                  name="genre"
                  value={formData.genre || ''}
                  onChange={(value: string) => {
                    if (!isEditing) return;
                    setFormData(prev =>
                      prev ? { ...prev, genre: value as TGenre } : null
                    );
                  }}
                  options={LITERARY_GENRES}
                  disabled={!isEditing}
                  placeholder="Select a genre..."
                />
              </div>
            </div>
          )}

          {activeTab === 'details' && (
            <div className="tab-panel">
              <div className="form-group">
                <label htmlFor="settingPhysical">Physical Setting</label>
                <textarea
                  id="settingPhysical"
                  name="settingPhysical"
                  value={formData.settingPhysical || ''}
                  onChange={handleInputChange}
                  className="form-control"
                  rows={4}
                  placeholder="Describe the physical environment, geography, and locations of your series"
                  disabled={!isEditing}
                />
              </div>

              <div className="form-group">
                <label htmlFor="settingCultural">Cultural Setting</label>
                <textarea
                  id="settingCultural"
                  name="settingCultural"
                  value={formData.settingCultural || ''}
                  onChange={handleInputChange}
                  className="form-control"
                  rows={4}
                  placeholder="Describe the societies, cultures, and civilizations in your series"
                  disabled={!isEditing}
                />
              </div>

              <div className="form-group">
                <label htmlFor="settingMagicSystem">Magical Setting</label>
                <textarea
                  id="settingMagicSystem"
                  name="settingMagicSystem"
                  value={formData.settingMagicSystem || ''}
                  onChange={handleInputChange}
                  className="form-control"
                  rows={4}
                  placeholder="Describe the magical or supernatural elements of your series"
                  disabled={!isEditing}
                />
              </div>
            </div>
          )}

          {activeTab === 'characters' && (
            <div className="tab-panel">
              <div className="create-section-header">
                <Button
                  variant="primary"
                  onClick={handleCreateCharacter}
                  className="create-btn"
                >
                  Create New Character
                </Button>
                <Button
                  variant="secondary"
                  onClick={() =>
                    setShowCharacterRelationships(!showCharacterRelationships)
                  }
                  className="relationship-btn"
                >
                  {showCharacterRelationships
                    ? 'Hide Character Relationships'
                    : 'Show Character Relationships'}
                </Button>
                <div className="character-filters">
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Search characters..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                  />
                  <select
                    className="role-filter"
                    value={filterRole}
                    onChange={e => setFilterRole(e.target.value)}
                  >
                    <option value="all">All Roles</option>
                    <option value="protagonist">Protagonist</option>
                    <option value="antagonist">Antagonist</option>
                    <option value="supporting">Supporting</option>
                    <option value="mentor">Mentor</option>
                    <option value="sidekick">Sidekick</option>
                    <option value="villain">Villain</option>
                  </select>
                </div>
              </div>

              {characterError && (
                <div className="error-message">{characterError}</div>
              )}

              {showCharacterRelationships && !characterLoading && (
                <div className="character-relationship-graph-wrapper">
                  <CharacterRelationshipGraph
                    characters={characters}
                    relationships={characterRelationships}
                    onClose={() => setShowCharacterRelationships(false)}
                    onRelationshipsUpdate={updatedRelationships =>
                      setCharacterRelationships(updatedRelationships)
                    }
                  />
                </div>
              )}

              {characterLoading ? (
                <div className="loading">Loading characters...</div>
              ) : (
                <div className="characters-grid">
                  {filteredCharacters.map(character => (
                    <div
                      key={character.id}
                      className="character-card"
                      onClick={() => handleViewCharacter(character.id)}
                    >
                      <div className="character-info">
                        <h3>{character.name}</h3>
                        {character.aliases && character.aliases.length > 0 && (
                          <div className="character-aliases">
                            Also known as: {character.aliases.join(', ')}
                          </div>
                        )}
                        <div className="character-role">
                          {character.role || 'Unknown Role'}
                        </div>
                        <p className="character-description">
                          {character.background ||
                            character.physicalDescription ||
                            'No description available.'}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {activeTab === 'locations' && (
            <div className="tab-panel">
              <div className="create-section-header">
                <Button
                  variant="primary"
                  onClick={handleCreateLocation}
                  className="create-btn"
                >
                  Create New Location
                </Button>
                <div className="location-filters">
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Search locations..."
                    value={locationSearchTerm}
                    onChange={e => setLocationSearchTerm(e.target.value)}
                  />
                  <select
                    className="type-filter"
                    value={filterLocationType}
                    onChange={e => setFilterLocationType(e.target.value)}
                  >
                    <option value="all">All Types</option>
                    <option value="city">City</option>
                    <option value="country">Country</option>
                    <option value="region">Region</option>
                    <option value="continent">Continent</option>
                    <option value="planet">Planet</option>
                    <option value="realm">Realm</option>
                    <option value="building">Building</option>
                    <option value="landmark">Landmark</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>

              {locationError && (
                <div className="error-message">{locationError}</div>
              )}

              {locationLoading ? (
                <div className="loading">Loading locations...</div>
              ) : (
                <div className="locations-grid">
                  {filteredLocations.map(location => (
                    <LocationCard
                      key={location.id}
                      location={location}
                      onViewLocation={handleViewLocation}
                    />
                  ))}
                </div>
              )}
            </div>
          )}

          {activeTab === 'books' && (
            <div className="tab-panel">
              <div className="book-management-header">
                <h2>Books in this Series</h2>
                <div className="book-filters">
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Search books..."
                    value={bookSearchTerm}
                    onChange={e => setBookSearchTerm(e.target.value)}
                  />
                </div>
              </div>

              {bookError && <div className="error-message">{bookError}</div>}

              {bookLoading ? (
                <div className="loading">Loading books...</div>
              ) : (
                <div className="books-container">
                  <div className="books-section">
                    <h3>Books in Series</h3>
                    {filteredSeriesBooks?.length === 0 ? (
                      <div className="no-books-message">
                        No books in this series yet.
                      </div>
                    ) : (
                      <div className="books-grid">
                        {filteredSeriesBooks?.map(book => (
                          <div key={book.id} className="book-card">
                            <div className="book-info">
                              <h4>{book.title}</h4>
                              <div className="book-meta">
                                <span>
                                  Last updated:{' '}
                                  {new Date(
                                    book.lastUploadDate
                                  ).toLocaleDateString()}
                                </span>
                              </div>
                            </div>
                            <Button
                              variant="secondary"
                              onClick={() =>
                                handleRemoveBookFromSeries(book.id)
                              }
                              className="remove-book-btn"
                              disabled={removingBook}
                            >
                              {removingBook
                                ? 'Removing...'
                                : 'Remove from Series'}
                            </Button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="books-section">
                    <h3>Available Books</h3>
                    {filteredAvailableBooks.length === 0 ? (
                      <div className="no-books-message">
                        No additional books available to add.
                      </div>
                    ) : (
                      <div className="books-grid">
                        {filteredAvailableBooks.map(book => (
                          <div key={book.id} className="book-card">
                            <div className="book-info">
                              <h4>{book.title}</h4>
                              <div className="book-meta">
                                <span>
                                  Last updated:{' '}
                                  {new Date(
                                    book.lastUploadDate
                                  ).toLocaleDateString()}
                                </span>
                              </div>
                            </div>
                            <Button
                              variant="primary"
                              onClick={() => handleAddBookToSeries(book.id)}
                              className="add-book-btn"
                              disabled={addingBook}
                            >
                              {addingBook ? 'Adding...' : 'Add to Series'}
                            </Button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {isEditing && (
          <div className="form-footer">
            <div className="form-actions">
              <Button
                variant="secondary"
                onClick={handleStopEditing}
                className="cancel-button"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={saving}
                className="submit-button"
              >
                {saving ? 'Saving...' : 'Save Changes'}
              </Button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default SeriesView;
