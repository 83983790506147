import React from 'react';
import { CollapsibleSection } from '../../CollapsibleSection/CollapsibleSection';
import StoryBeatVisualizer from '../../StoryBeatVisualizer/StoryBeatVisualizer';
import { TStoryBeats } from '../../../api/generated/types/common';
import { InfoSection } from '../../InfoSection/InfoSection';
import Button from '../../design-system/Button/Button';
import styles from '../styles.module.css';

interface StoryBeatSectionProps {
  analysis: TStoryBeats | null;
  isPending: boolean;
  isAnalyzing: boolean;
  isOpen: boolean;
  onToggle: () => void;
  onStartAnalysis: () => void;
}

export const StoryBeatSection: React.FC<StoryBeatSectionProps> = ({
  analysis,
  isPending,
  isAnalyzing,
  isOpen,
  onToggle,
  onStartAnalysis,
}) => {
  return (
    <CollapsibleSection
      title="Story Beat Analysis"
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <InfoSection sectionId="storyBeats">
        <p>
          This section maps out the key narrative moments and their emotional
          impact throughout your story. This visualization helps identify
          pacing, dramatic arcs, and potential areas where the story's rhythm
          could be enhanced. Each beat is analyzed for its emotional resonance
          and narrative significance, helping you understand how your story
          flows and where it might benefit from adjustment.
        </p>
      </InfoSection>
      {isPending ? (
        <p className={styles.pendingAnalysis}>
          Your insights will be ready in a few minutes, hang tight and we'll
          refresh when they are ready.
        </p>
      ) : analysis ? (
        <div className={styles.analysisContainer}>
          <StoryBeatVisualizer data={analysis} />
        </div>
      ) : (
        <div className={styles.noAnalysis}>
          <p>No story beat analysis available.</p>
          <Button
            onClick={onStartAnalysis}
            disabled={isAnalyzing}
            variant="primary"
            size="medium"
          >
            {isAnalyzing ? 'Analyzing...' : 'Start Analysis'}
          </Button>
        </div>
      )}
    </CollapsibleSection>
  );
};
