// src/components/SeriesWiki/Locations/LocationForm.tsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { post, put, get } from '../../../api/apiUtils';
import Button from '../../../components/design-system/Button/Button';
import {
  TLocationRequest,
  TLocationResponse,
  TLocationType,
} from '../../../api/generated/types/common';
import './LocationForm.css'; // Create this CSS file, reuse styles from CharacterForm

const defaultFormData: Omit<TLocationRequest, 'seriesId'> = {
  name: '',
  locationType: null,
  parentLocation: null,
  geography: null,
  climate: null,
  description: null,
  history: null,
  culture: null,
  politics: null,
  economy: null,
  firstAppearance: null,
  mapReference: null,
};

const LocationForm: React.FC = () => {
  const { seriesId, locationId } = useParams<{
    seriesId: string;
    locationId?: string;
  }>();
  const navigate = useNavigate();
  const mode = locationId ? 'edit' : 'create';
  const [formData, setFormData] = useState<TLocationRequest>({
    ...defaultFormData,
    seriesId: seriesId!,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // State for dropdown options
  const [parentLocationOptions, setParentLocationOptions] = useState<
    TLocationResponse[]
  >([]);

  // Fetch data for dropdowns and pre-fill form in edit mode
  useEffect(() => {
    const fetchData = async () => {
      if (!seriesId) return;
      setLoading(true);
      try {
        // Fetch locations for parent dropdown (exclude self in edit mode)
        const allLocations = await get<TLocationResponse[]>(
          `/wiki/locations/by-series/${seriesId}`
        );
        setParentLocationOptions(
          allLocations.filter(loc => loc.id !== locationId)
        );

        // If editing, fetch current location data
        if (mode === 'edit' && locationId) {
          const currentLocation = await get<TLocationResponse>(
            `/wiki/locations/${locationId}/get`
          );
          // Map Response to Request shape - they might be identical or slightly different
          setFormData({
            seriesId: currentLocation.seriesId, // Ensure seriesId matches route param
            name: currentLocation.name,
            locationType: currentLocation.locationType,
            parentLocation: currentLocation.parentLocation,
            geography: currentLocation.geography,
            climate: currentLocation.climate,
            description: currentLocation.description,
            history: currentLocation.history,
            culture: currentLocation.culture,
            politics: currentLocation.politics,
            economy: currentLocation.economy,
            firstAppearance: currentLocation.firstAppearance, // Assuming BookId is string UUID
            mapReference: currentLocation.mapReference,
          });
        }
        setError(null);
      } catch (err) {
        console.error('Error loading form data:', err);
        setError('Failed to load necessary data for the form.');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [seriesId, locationId, mode]);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      // Handle empty string from select as null
      [name]: value === '' ? null : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!seriesId) return;
    setLoading(true);
    setError(null);

    try {
      if (mode === 'create') {
        const locationId = await post<string>(
          '/wiki/locations/create',
          formData
        );
        navigate(`/series/${seriesId}/locations/${locationId}`);
      } else {
        const savedLocation = await put<TLocationResponse>(
          `/wiki/locations/${locationId}/update`,
          formData
        );
        navigate(`/series/${seriesId}/locations/${savedLocation.id}`);
      }
    } catch (error: any) {
      console.error('Error saving location:', error);
      setError(error?.message || 'Failed to save location.');
    } finally {
      setLoading(false);
    }
  };

  // TODO: Get actual LocationType values from common.ts or define manually
  const locationTypes: TLocationType[] = [
    'country',
    'region',
    'city',
    'town',
    'building',
    'landmark',
    'natural',
    'fictional',
  ];

  if (loading && mode === 'edit')
    return <div className="loading-indicator">Loading location data...</div>; // Only show full load for edit

  return (
    <div className="location-form-container">
      {' '}
      {/* Reuse styles */}
      <h1>{mode === 'create' ? 'Create New Location' : 'Edit Location'}</h1>
      {error && <div className="form-error-message">{error}</div>}{' '}
      {/* Reuse styles */}
      <form onSubmit={handleSubmit} className="entity-form">
        {' '}
        {/* Reuse styles */}
        {/* Group fields logically */}
        <section className="form-section">
          <h2>Basic Information</h2>
          <div className="form-group">
            <label htmlFor="name">
              Name <span className="required">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={formData.name}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          <div className="form-group">
            <label htmlFor="locationType">Location Type</label>
            <select
              id="locationType"
              name="locationType"
              value={formData.locationType || ''}
              onChange={handleInputChange}
              disabled={loading}
            >
              <option value="">Select type...</option>
              {locationTypes.map(type => (
                <option key={type} value={type}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="parentLocation">Parent Location</label>
            <select
              id="parentLocation"
              name="parentLocation"
              value={formData.parentLocation || ''}
              onChange={handleInputChange}
              disabled={loading || parentLocationOptions.length === 0}
            >
              <option value="">None</option>
              {parentLocationOptions.map(loc => (
                <option key={loc.id} value={loc.id}>
                  {loc.name}
                </option>
              ))}
            </select>
            {parentLocationOptions.length === 0 && (
              <small> No other locations available.</small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="firstAppearance">First Appearance (Book)</label>
            <select
              id="firstAppearance"
              name="firstAppearance"
              value={formData.firstAppearance || ''}
              onChange={handleInputChange}
              disabled={loading /*|| bookOptions.length === 0*/}
            >
              <option value="">Unknown / Not Applicable</option>
              {/* {bookOptions.map(book => (
                        <option key={book.id} value={book.id}>{book.title}</option>
                    ))} */}
              {/* Add placeholder if bookOptions logic isn't ready */}
              <option value="" disabled>
                Book selection not implemented yet
              </option>
            </select>
          </div>
        </section>
        <section className="form-section">
          <h2>Details</h2>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              rows={5}
              value={formData.description || ''}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          <div className="form-group">
            <label htmlFor="geography">Geography</label>
            <input
              type="text"
              id="geography"
              name="geography"
              value={formData.geography || ''}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          <div className="form-group">
            <label htmlFor="climate">Climate</label>
            <input
              type="text"
              id="climate"
              name="climate"
              value={formData.climate || ''}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          {/* Add inputs/textareas for history, culture, politics, economy, mapReference */}
          <div className="form-group">
            <label htmlFor="history">History</label>
            <textarea
              id="history"
              name="history"
              rows={3}
              value={formData.history || ''}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          <div className="form-group">
            <label htmlFor="culture">Culture</label>
            <textarea
              id="culture"
              name="culture"
              rows={3}
              value={formData.culture || ''}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          {/* ... other fields ... */}
          <div className="form-group">
            <label htmlFor="mapReference">Map Reference</label>
            <input
              type="text"
              id="mapReference"
              name="mapReference"
              value={formData.mapReference || ''}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
        </section>
        <div className="form-actions">
          <Button
            variant="secondary"
            onClick={() => navigate(-1)}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading
              ? 'Saving...'
              : mode === 'create'
                ? 'Create Location'
                : 'Update Location'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LocationForm;
