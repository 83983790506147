// src/pages/SeriesWiki/CharacterDetail.tsx
import React, { useState, useEffect } from 'react';
import {
  useParams,
  Link,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { get, del } from '../../api/apiUtils';
import Button from '../../components/design-system/Button/Button';
import { TCharacterResponse } from '../../api/generated/types/common';
import './CharacterDetail.css';

const CharacterDetail: React.FC = () => {
  const { seriesId, characterId } = useParams<{
    seriesId: string;
    characterId: string;
  }>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnTab = searchParams.get('returnTab') || 'characters';
  const [character, setCharacter] = useState<TCharacterResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCharacter = async () => {
      try {
        const data = await get<TCharacterResponse>(
          `/wiki/characters/${characterId}/get`
        );
        setCharacter(data);
      } catch (err) {
        console.error('Error fetching character:', err);
        setError('Failed to load character data');
      } finally {
        setLoading(false);
      }
    };

    if (characterId) {
      fetchCharacter();
    }
  }, [characterId]);

  const handleDelete = async () => {
    if (
      !character ||
      !window.confirm('Are you sure you want to delete this character?')
    ) {
      return;
    }

    try {
      await del(`/wiki/characters/${character.id}/delete`);
      navigate(`/series/${seriesId}?tab=${returnTab}`);
    } catch (err) {
      console.error('Error deleting character:', err);
      setError('Failed to delete character');
    }
  };

  if (loading) {
    return <div className="loading">Loading character details...</div>;
  }

  if (error || !character) {
    return (
      <div className="error-message">{error || 'Character not found'}</div>
    );
  }

  return (
    <div className="character-detail">
      <div className="character-header">
        <div className="header-content">
          <h1>{character.name}</h1>
          <div className="character-role">
            {character.role || 'Unknown Role'}
          </div>
        </div>
        <div className="header-actions">
          <Link to={`/series/${seriesId}?tab=${returnTab}`}>
            <Button variant="secondary">Back to Series</Button>
          </Link>
          <Link
            to={`/series/${seriesId}/characters/${characterId}/edit?returnTab=${returnTab}`}
          >
            <Button variant="primary">Edit Character</Button>
          </Link>
          <Button variant="danger" onClick={handleDelete}>
            Delete Character
          </Button>
        </div>
      </div>

      <div className="character-sections">
        <section className="character-section">
          <h2>Basic Information</h2>
          {character.aliases && character.aliases.length > 0 && (
            <div className="info-group">
              <h3>Also Known As</h3>
              <p>{character.aliases.join(', ')}</p>
            </div>
          )}

          {character.age && (
            <div className="info-group">
              <h3>Age</h3>
              <p>{character.age}</p>
            </div>
          )}

          {character.gender && (
            <div className="info-group">
              <h3>Gender</h3>
              <p>{character.gender}</p>
            </div>
          )}

          {character.occupation && (
            <div className="info-group">
              <h3>Occupation</h3>
              <p>{character.occupation}</p>
            </div>
          )}
        </section>

        <section className="character-section">
          <h2>Timeline</h2>
          {character.birthDate && (
            <div className="info-group">
              <h3>Birth Date</h3>
              <p>{character.birthDate}</p>
            </div>
          )}

          {character.deathDate && (
            <div className="info-group">
              <h3>Death Date</h3>
              <p>{character.deathDate}</p>
            </div>
          )}

          {character.firstAppearance && (
            <div className="info-group">
              <h3>First Appearance</h3>
              <p>{character.firstAppearance}</p>
            </div>
          )}
        </section>

        <section className="character-section">
          <h2>Character Details</h2>
          {character.physicalDescription && (
            <div className="info-group">
              <h3>Physical Description</h3>
              <p>{character.physicalDescription}</p>
            </div>
          )}

          {character.personality && (
            <div className="info-group">
              <h3>Personality</h3>
              <p>{character.personality}</p>
            </div>
          )}

          {character.background && (
            <div className="info-group">
              <h3>Background</h3>
              <p>{character.background}</p>
            </div>
          )}
        </section>

        <section className="character-section">
          <h2>Character Development</h2>
          {character.goals && character.goals.length > 0 && (
            <div className="info-group">
              <h3>Goals</h3>
              <ul className="character-goals">
                {character.goals.map((goal: string, index: number) => (
                  <li key={index}>{goal}</li>
                ))}
              </ul>
            </div>
          )}

          {character.fears && character.fears.length > 0 && (
            <div className="info-group">
              <h3>Fears</h3>
              <ul className="character-fears">
                {character.fears.map((fear: string, index: number) => (
                  <li key={index}>{fear}</li>
                ))}
              </ul>
            </div>
          )}

          {character.characterArc && (
            <div className="info-group">
              <h3>Character Arc</h3>
              <p>{character.characterArc}</p>
            </div>
          )}
        </section>

        {character.notes && (
          <section className="character-section">
            <h2>Additional Notes</h2>
            <p>{character.notes}</p>
          </section>
        )}
      </div>
    </div>
  );
};

export default CharacterDetail;
