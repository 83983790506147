// Autogenerated, edits will not be preserved

export const serverEndpoints = {
  // GET
  robots(): string {
    return `/robots.txt`;
  },

  // POST
  feedback(): string {
    return `/feedback`;
  },

  // GET
  home(): string {
    return `/`;
  },

  // POST
  signup(): string {
    return `/signup`;
  },

  // POST
  signin(): string {
    return `/signin`;
  },

  // POST
  logout(): string {
    return `/logout`;
  },

  // POST
  generateResetPasswordCode(): string {
    return `/generate-reset-password-code`;
  },

  // POST
  resetPassword(): string {
    return `/reset-password`;
  },

  // GET
  getInitialData(): string {
    return `/get-initial-data`;
  },

  // GET
  dashboardData(): string {
    return `/dashboard-data`;
  },

  // POST
  startEvaluation(): string {
    return `/start-evaluation`;
  },

  // POST
  filesBook(pathPieces: { bookId: string }): string {
    return `/files/book/${pathPieces.bookId}`;
  },

  // POST
  filesDeleteBook(pathPieces: { bookId: string }): string {
    return `/files/book/${pathPieces.bookId}/delete`;
  },

  // POST
  filesBookVersion(pathPieces: { bookVersionId: string }): string {
    return `/files/book/version/${pathPieces.bookVersionId}`;
  },

  // POST
  filesListAllFiles(pathPieces: { organizationId: string }): string {
    return `/files/${pathPieces.organizationId}/all`;
  },

  // POST
  filesGetPresignedURL(pathPieces: { organizationId: string }): string {
    return `/files/${pathPieces.organizationId}/get-presigned-url`;
  },

  // POST
  filesConfirmUpload(pathPieces: { fileId: string }): string {
    return `/files/${pathPieces.fileId}/confirm-upload`;
  },

  // POST
  filesActionBook(pathPieces: { bookId: string }): string {
    return `/files/book/${pathPieces.bookId}/action`;
  },

  // POST
  royaltiesCalculateRoyalties(pathPieces: { bookId: string }): string {
    return `/royalties/calculate/${pathPieces.bookId}`;
  },

  // POST
  royaltiesSetupRoyaltyStructure(): string {
    return `/royalties/configure`;
  },

  // POST
  royaltiesListAllRoyaltyStructures(): string {
    return `/royalties/list`;
  },

  // POST
  royaltiesPayMonthlyRoyalty(pathPieces: {
    contributorPaidRoyaltyId: string;
  }): string {
    return `/royalties/${pathPieces.contributorPaidRoyaltyId}/pay`;
  },

  // POST
  updateUserProfile(): string {
    return `/users/update-profile`;
  },

  // POST
  changePassword(): string {
    return `/users/change-password`;
  },

  // POST
  deleteAccount(): string {
    return `/users/delete-account`;
  },

  // POST
  inviteUserToOrg(): string {
    return `/users/invite-to-organization`;
  },

  // POST
  organizationDetails(): string {
    return `/organization`;
  },

  // POST
  actionInvite(pathPieces: { inviteId: string }): string {
    return `/invites/${pathPieces.inviteId}/action`;
  },

  // POST
  invite(pathPieces: { inviteId: string }): string {
    return `/invites/${pathPieces.inviteId}`;
  },

  // POST
  listInvites(): string {
    return `/invites`;
  },

  // POST
  createCheckoutSession(): string {
    return `/create-checkout-session`;
  },

  // POST
  stripeWebhook(): string {
    return `/stripe/webhook`;
  },

  // POST
  webhooksNewEmailWebhook(): string {
    return `/webhooks/new-email`;
  },

  // POST
  adminAdminNewUser(): string {
    return `/admin/new-user`;
  },

  // POST
  adminBugs(): string {
    return `/admin/bugs`;
  },

  // POST
  adminAllUsers(): string {
    return `/admin/users`;
  },

  // POST
  adminUsageStats(): string {
    return `/admin/usage`;
  },

  // POST
  adminProvideSupport(): string {
    return `/admin/provide-support`;
  },

  // POST
  adminEndProvideSupport(): string {
    return `/admin/provide-support/end-session`;
  },

  // POST
  adminPrompts(): string {
    return `/admin/prompts`;
  },

  // POST
  adminJobStats(): string {
    return `/admin/job-queue/stats`;
  },

  // POST
  adminJobsList(): string {
    return `/admin/job-queue/jobs`;
  },

  // POST
  adminFetchJob(pathPieces: { queuedSubscriberId: string }): string {
    return `/admin/job-queue/jobs/${pathPieces.queuedSubscriberId}`;
  },

  // POST
  adminRetryJob(pathPieces: { queuedSubscriberId: string }): string {
    return `/admin/job-queue/jobs/${pathPieces.queuedSubscriberId}/retry`;
  },

  // POST
  adminCancelJob(pathPieces: { queuedSubscriberId: string }): string {
    return `/admin/job-queue/jobs/${pathPieces.queuedSubscriberId}/cancel`;
  },

  // GET
  seriesSeriesList(): string {
    return `/wiki/series/list`;
  },

  // POST
  seriesSeriesCreate(): string {
    return `/wiki/series/create`;
  },

  // GET
  seriesSeriesSingle(pathPieces: { seriesId: string }): string {
    return `/wiki/series/${pathPieces.seriesId}/get`;
  },

  // PUT
  seriesSeriesUpdate(pathPieces: { seriesId: string }): string {
    return `/wiki/series/${pathPieces.seriesId}/update`;
  },

  // DELETE
  seriesSeriesDelete(pathPieces: { seriesId: string }): string {
    return `/wiki/series/${pathPieces.seriesId}/delete`;
  },

  // POST
  seriesAddBookToSeries(pathPieces: {
    seriesId: string;
    bookId: string;
  }): string {
    return `/wiki/series/${pathPieces.seriesId}/add/${pathPieces.bookId}`;
  },

  // POST
  seriesRemoveBookFromSeries(pathPieces: {
    seriesId: string;
    bookId: string;
  }): string {
    return `/wiki/series/${pathPieces.seriesId}/remove/${pathPieces.bookId}`;
  },

  // POST
  charactersCharactersCreate(): string {
    return `/wiki/characters/create`;
  },

  // GET
  charactersCharacterSingle(pathPieces: { characterId: string }): string {
    return `/wiki/characters/${pathPieces.characterId}/get`;
  },

  // PUT
  charactersCharacterUpdate(pathPieces: { characterId: string }): string {
    return `/wiki/characters/${pathPieces.characterId}/update`;
  },

  // DELETE
  charactersCharacterDelete(pathPieces: { characterId: string }): string {
    return `/wiki/characters/${pathPieces.characterId}/delete`;
  },

  // GET
  charactersCharactersBySeries(pathPieces: { seriesId: string }): string {
    return `/wiki/characters/by-series/${pathPieces.seriesId}`;
  },

  // GET
  charactersCharactersBySeriesRelationships(pathPieces: {
    seriesId: string;
  }): string {
    return `/wiki/characters/by-series/${pathPieces.seriesId}/relationships`;
  },

  // PUT
  characterRelationshipsCharacterRelationshipUpdate(pathPieces: {
    characterRelationshipId: string;
  }): string {
    return `/wiki/character-relationships/${pathPieces.characterRelationshipId}/update`;
  },

  // DELETE
  characterRelationshipsCharacterRelationshipDelete(pathPieces: {
    characterRelationshipId: string;
  }): string {
    return `/wiki/character-relationships/${pathPieces.characterRelationshipId}/delete`;
  },

  // POST
  locationsLocationsCreate(): string {
    return `/wiki/locations/create`;
  },

  // GET
  locationsLocationSingle(pathPieces: { locationId: string }): string {
    return `/wiki/locations/${pathPieces.locationId}/get`;
  },

  // PUT
  locationsLocationUpdate(pathPieces: { locationId: string }): string {
    return `/wiki/locations/${pathPieces.locationId}/update`;
  },

  // DELETE
  locationsLocationDelete(pathPieces: { locationId: string }): string {
    return `/wiki/locations/${pathPieces.locationId}/delete`;
  },

  // GET
  locationsLocationsBySeries(pathPieces: { seriesId: string }): string {
    return `/wiki/locations/by-series/${pathPieces.seriesId}`;
  },
};
