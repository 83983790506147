import React from 'react';
import { TBookDetails } from '../../../api/generated/types/common';
import styles from './Basic.module.css';
import { UploadedFileData } from '../../../mocks/data/fileData';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/rootReducer';

interface BasicDetailsProps {
  currentVersionDetails: TBookDetails;
  fileDetails: UploadedFileData;
  handleManuscriptAction: (
    action: 'bookAccepted' | 'bookRejected'
  ) => Promise<void>;
  isAcceptingOrRejecting: boolean;
  displayActions?: boolean;
}

export const BasicDetails: React.FC<BasicDetailsProps> = ({
  currentVersionDetails,
  fileDetails,
  handleManuscriptAction,
  isAcceptingOrRejecting,
  displayActions = true,
}) => {
  const isAdmin = useSelector(
    (state: RootState) => !!state.userAuth.user?.admin
  );

  const statusToHuman = (
    status: 'bookAccepted' | 'bookRejected' | 'bookPending' | 'bookPublished'
  ) => {
    switch (status) {
      case 'bookAccepted':
        return 'Accepted';
      case 'bookRejected':
        return 'Rejected';
      case 'bookPending':
        return 'Pending';
      case 'bookPublished':
        return 'Published';
      default:
        return 'Unknown';
    }
  };

  return (
    <ul className={styles.detailsList}>
      {currentVersionDetails.currentStatus &&
        currentVersionDetails.currentStatus !== 'bookPending' && (
          <li className={styles.detailsItem}>
            <strong>Status:</strong>{' '}
            {statusToHuman(currentVersionDetails.currentStatus)}
          </li>
        )}
      <li className={styles.detailsItem}>
        <strong>Title:</strong> {fileDetails.title}
      </li>
      <li className={styles.detailsItem}>
        <strong>Upload Date:</strong>{' '}
        {new Date(fileDetails.uploadDate).toLocaleDateString()}
      </li>
      {currentVersionDetails.author && (
        <li className={styles.detailsItem}>
          <strong>Author:</strong> {currentVersionDetails.author}
        </li>
      )}
      {currentVersionDetails.wordCount && (
        <li className={styles.detailsItem}>
          <strong>Word Count:</strong>{' '}
          {currentVersionDetails.wordCount.toLocaleString()}
        </li>
      )}
      {currentVersionDetails.chapterCount && (
        <li className={styles.detailsItem}>
          <strong>Chapter Count:</strong> {currentVersionDetails.chapterCount}
        </li>
      )}
      {currentVersionDetails.tokenCount && isAdmin && (
        <li className={styles.detailsItem}>
          <strong>Token Count:</strong>{' '}
          {currentVersionDetails.tokenCount.toLocaleString()}
        </li>
      )}
      {currentVersionDetails.firstLine && (
        <li className={styles.detailsItem}>
          <strong>Opening:</strong> {currentVersionDetails.firstLine}
        </li>
      )}
      {displayActions && (
        <div className={styles.actionButtons}>
          <button
            onClick={() => handleManuscriptAction('bookAccepted')}
            disabled={isAcceptingOrRejecting}
            className={`${styles.actionButton} ${styles.acceptButton}`}
          >
            {isAcceptingOrRejecting ? 'Processing...' : 'Accept'}
          </button>
          <button
            onClick={() => handleManuscriptAction('bookRejected')}
            disabled={isAcceptingOrRejecting}
            className={`${styles.actionButton} ${styles.rejectButton}`}
          >
            {isAcceptingOrRejecting ? 'Processing...' : 'Reject'}
          </button>
        </div>
      )}
    </ul>
  );
};
