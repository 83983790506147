import React from 'react';
import { CollapsibleSection } from '../../CollapsibleSection/CollapsibleSection';
import { NGramAnalysisViewer } from '../../NGramVisualizer/NGramVisualizer';
import { TAuthorNGrams } from '../../../api/generated/types/common';
import { InfoSection } from '../../InfoSection/InfoSection';
import Button from '../../design-system/Button/Button';
import styles from '../styles.module.css';

interface NGramAnalysisSectionProps {
  title: string;
  analysis: TAuthorNGrams | null;
  isPending: boolean;
  isAnalyzing: boolean;
  isOpen: boolean;
  onToggle: () => void;
  onStartAnalysis: () => void;
  allowRerun?: boolean;
}

export const NGramAnalysisSection: React.FC<NGramAnalysisSectionProps> = ({
  title,
  analysis,
  isPending,
  isAnalyzing,
  isOpen,
  onToggle,
  onStartAnalysis,
  allowRerun = true,
}) => {
  return (
    <CollapsibleSection title={title} isOpen={isOpen} onToggle={onToggle}>
      <InfoSection
        sectionId={`ngram_${title.toLowerCase().replace(/\s+/g, '_')}`}
      >
        <p>
          N-Gram Analysis provides deep insights into your writing style and
          patterns. It examines sequences of words (n-grams) to identify
          recurring phrases, stylistic habits, and potential areas for
          improvement. This analysis can help you:
          <ul>
            <li>Identify overused phrases or word combinations</li>
            <li>Understand your unique writing patterns</li>
            <li>Compare your style against established authors</li>
            <li>Find opportunities to vary your language</li>
          </ul>
        </p>
      </InfoSection>
      {isPending ? (
        <p className={styles.pendingAnalysis}>
          Your insights will be ready in a few minutes, hang tight and we'll
          refresh when they are ready.
        </p>
      ) : analysis ? (
        <div className={styles.analysisContainer}>
          {typeof analysis === 'string' ? (
            <pre className={styles.analysisContent}>{analysis}</pre>
          ) : (
            <NGramAnalysisViewer data={analysis} />
          )}
          {allowRerun && (
            <Button
              onClick={onStartAnalysis}
              disabled={isAnalyzing}
              variant="secondary"
              size="medium"
            >
              {isAnalyzing ? 'Analyzing...' : 'Re-run Analysis'}
            </Button>
          )}
        </div>
      ) : (
        <div className={styles.noAnalysis}>
          <p>No analysis available.</p>
          <Button
            onClick={onStartAnalysis}
            disabled={isAnalyzing}
            variant="primary"
            size="medium"
          >
            {isAnalyzing ? 'Analyzing...' : 'Start Analysis'}
          </Button>
        </div>
      )}
    </CollapsibleSection>
  );
};
