import React from 'react';
import {
  Calculator,
  DollarSign,
  FileSpreadsheet,
  PieChart,
  Users,
} from 'lucide-react';
import styles from '../EditorialAnalysis/EditorialAnalysis.module.css';

const RoyaltyCalculator = () => {
  return (
    <div className={styles.container}>
      {/* Hero Section */}
      <div className={styles.hero}>
        <h1 className={styles.heroTitle}>Royalty Calculator</h1>
        <p className={styles.heroSubtitle}>
          Streamline your royalty calculations across multiple publishing
          platforms with our intelligent royalty management system
        </p>
      </div>

      {/* Key Features */}
      <div className={styles.features}>
        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <FileSpreadsheet className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Multi-Platform Integration</h3>
          </div>
          <p className={styles.featureDescription}>
            Import sales data from KDP, IngramSpark, Draft2Digital, and other
            major platforms for unified royalty tracking and calculation.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <Calculator className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Automated Calculations</h3>
          </div>
          <p className={styles.featureDescription}>
            Let our system handle complex royalty calculations, including
            varying rates, currencies, and payment structures across different
            platforms.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <Users className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Author Management</h3>
          </div>
          <p className={styles.featureDescription}>
            Easily manage multiple authors and their respective royalty
            agreements, ensuring accurate and timely payments for everyone.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <DollarSign className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Payment Tracking</h3>
          </div>
          <p className={styles.featureDescription}>
            Keep track of royalty payments, generate payment reports, and
            maintain a clear history of all transactions.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <PieChart className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Sales Analytics</h3>
          </div>
          <p className={styles.featureDescription}>
            Gain insights into sales performance across platforms with detailed
            analytics and visual reporting tools.
          </p>
          <div className={styles.comingSoonBadge}>Coming Soon</div>
        </div>
      </div>

      {/* How It Works Section */}
      <div className={styles.howToUse}>
        <h2 className={styles.sectionTitle}>Simplifying Royalty Management</h2>

        <div className={styles.content}>
          <p className={styles.introText}>
            Our Royalty Calculator transforms the complex task of managing
            author royalties into a streamlined, automated process that saves
            you time and reduces errors.
          </p>

          <div className={styles.usageGrid}>
            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>1. Data Import</h3>
              <p>
                Simply upload your sales reports from various platforms, and our
                system automatically recognizes and processes the data in the
                correct format.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>2. Author Assignment</h3>
              <p>
                Match sales data with author profiles and their specific royalty
                agreements, ensuring accurate calculation of payments for each
                author.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>3. Calculation</h3>
              <p>
                The system automatically processes sales data according to each
                platform's specific royalty structure and author agreements.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>4. Reporting</h3>
              <p>
                Generate detailed reports showing sales performance, royalty
                calculations, and payment histories for each author and
                platform.
              </p>
            </div>
          </div>

          <div className={styles.exampleContainer}>
            <img
              src="/images/royalty-dashboard.png"
              alt="Royalty calculation dashboard showing sales data and payment tracking"
              className={styles.exampleImage}
            />
            <p className={styles.imageCaption}>
              Example dashboard showing consolidated royalty calculations across
              multiple platforms
            </p>
          </div>
        </div>
      </div>

      {/* For Publishers Section */}
      <div className={styles.howToUse}>
        <h2 className={styles.sectionTitle}>For Publishers</h2>
        <div className={styles.content}>
          <p className={styles.introText}>
            Whether you're managing a few authors or a large catalog, our
            Royalty Calculator helps you maintain accurate, transparent, and
            efficient royalty management.
          </p>

          <div className={styles.usageGrid}>
            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>Time Savings</h3>
              <p>
                Automate the tedious process of consolidating sales data and
                calculating royalties across multiple platforms.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>Error Reduction</h3>
              <p>
                Minimize calculation errors and ensure consistent application of
                royalty rates and payment terms.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>Transparency</h3>
              <p>
                Provide authors with clear, detailed reports of their sales and
                royalty calculations across all platforms.
              </p>
            </div>

            <div className={`${styles.featureCard} ${styles.comingSoonCard}`}>
              <h3 className={styles.usageTitle}>Author Portal</h3>
              <p>
                Give authors direct access to their sales data and royalty
                information through a dedicated portal.
              </p>
              <div className={styles.comingSoonBadge}>Coming Soon</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoyaltyCalculator;
