// src/pages/Admin/JobQueueManagement/JobQueueManagement.tsx
import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import {
  useListJobsQuery,
  useGetJobQueueStatsQuery,
  useRetryJobMutation,
  useCancelJobMutation,
} from '../../../api/generated/jobQueueApi';
import AdminDashboardLayout from '../../../components/AdminDashboardLayout/AdminDashboardLayout';
import JobQueueStats from './components/JobQueueStats';
import JobList from './components/JobList';
import JobDetails from './components/JobDetails';
import JobFilters from './components/JobFilters';
import styles from './JobQueueManagement.module.css';
import { TQueuedSubscriberStatus } from 'api/generated/types/common';

const JobQueueManagement: React.FC = () => {
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);
  const [filters, setFilters] = useState({
    status: undefined as TQueuedSubscriberStatus | undefined,
    search: '',
    page: 1,
    limit: 20,
    startDate: undefined as string | undefined,
    endDate: undefined as string | undefined,
  });
  const [timeframe, setTimeframe] = useState('24h');

  // Fetch jobs with current filters
  const {
    data: jobsData,
    isLoading: isJobsLoading,
    isError: isJobsError,
    refetch: refetchJobs,
  } = useListJobsQuery(filters);

  // Fetch job queue statistics
  const {
    data: statsData,
    isLoading: isStatsLoading,
    isError: isStatsError,
    refetch: refetchStats,
  } = useGetJobQueueStatsQuery({ timeframe });

  // Mutations for job actions
  const [retryJob, { isLoading: isRetrying }] = useRetryJobMutation();
  const [cancelJob, { isLoading: isCancelling }] = useCancelJobMutation();

  // Handle job selection
  const handleJobSelect = (jobId: string) => {
    setSelectedJobId(jobId);
  };

  // Handle job retry
  const handleRetryJob = async (jobId: string) => {
    try {
      await retryJob({ jobId });
      refetchJobs();
      refetchStats();
    } catch (error) {
      console.error('Failed to retry job:', error);
    }
  };

  // Handle job cancellation
  const handleCancelJob = async (jobId: string) => {
    try {
      await cancelJob(jobId);
      refetchJobs();
      refetchStats();
    } catch (error) {
      console.error('Failed to cancel job:', error);
    }
  };

  // Handle filter changes
  const handleFilterChange = (newFilters: Partial<typeof filters>) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters,
      page: newFilters.status !== prevFilters.status ? 1 : prevFilters.page,
    }));
  };

  // Handle timeframe change for stats
  const handleTimeframeChange = (newTimeframe: string) => {
    setTimeframe(newTimeframe);
  };

  // Format date for display
  const formatDate = (dateString: string) => {
    try {
      return format(parseISO(dateString), 'MMM d, yyyy h:mm a');
    } catch (error) {
      return dateString;
    }
  };

  return (
    <AdminDashboardLayout>
      <div className={styles.container}>
        <h1 className={styles.title}>Job Queue Management</h1>
        <p className={styles.description}>
          Monitor and manage background jobs in the system.
        </p>

        {/* Statistics Dashboard */}
        <section className={styles.statsSection}>
          <div className={styles.sectionTitle}>
            <h2>Queue Overview</h2>
            <div className={styles.timeframeSelector}>
              <label>Timeframe:</label>
              <select
                value={timeframe}
                onChange={e => handleTimeframeChange(e.target.value)}
                className={styles.select}
              >
                <option value="1h">Last hour</option>
                <option value="6h">Last 6 hours</option>
                <option value="24h">Last 24 hours</option>
                <option value="7d">Last 7 days</option>
                <option value="30d">Last 30 days</option>
              </select>
            </div>
          </div>
          <JobQueueStats
            stats={statsData}
            isLoading={isStatsLoading}
            isError={isStatsError}
          />
        </section>

        {/* Job Management Section */}
        <section className={styles.jobsSection}>
          <h2 className={styles.sectionTitle}>Job Management</h2>

          {/* Filters */}
          <JobFilters filters={filters} onFilterChange={handleFilterChange} />

          <div className={styles.jobsContainer}>
            {/* Job List */}
            <div className={styles.jobList}>
              <JobList
                jobs={jobsData?.jobs || []}
                isLoading={isJobsLoading}
                isError={isJobsError}
                selectedJobId={selectedJobId}
                onJobSelect={handleJobSelect}
                formatDate={formatDate}
                total={jobsData?.total || 0}
                page={filters.page}
                limit={filters.limit}
                onPageChange={(page: number) => handleFilterChange({ page })}
              />
            </div>

            {/* Job Details */}
            <div className={styles.jobDetails}>
              {selectedJobId ? (
                <JobDetails
                  jobId={selectedJobId}
                  onRetry={handleRetryJob}
                  onCancel={handleCancelJob}
                  isRetrying={isRetrying}
                  isCancelling={isCancelling}
                  formatDate={formatDate}
                />
              ) : (
                <div className={styles.noJobSelected}>
                  <p>Select a job to view details</p>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </AdminDashboardLayout>
  );
};

export default JobQueueManagement;
