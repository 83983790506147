// src/pages/EditorialDashboard/EditorialDashboard.tsx
import React, { useState, useEffect } from 'react';
import styles from './EditorialDashboard.module.css';
import { Edit, FileText, CheckSquare, BookOpen, Settings } from 'lucide-react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  TBookDetails,
  TLlmModel,
  TStartEvaluationParams,
} from '../../api/generated/types/common';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpiner';
import { post } from '../../api/apiUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import { UploadedFileData } from '../../mocks/data/fileData';
import { NGramAnalysisSection } from '../../components/Analysis/NGramAnalysis/NGramAnalysis';
import { Evaluation } from '../../components/Analysis/Evaluation/Evaluation';
import { StoryBeatSection } from '../../components/Analysis/StoryBeats/StoryBeats';
import { AlertDialog } from '../../components/AlertDialog/AlertDialog';
import { BasicDetails } from '../../components/Analysis/Basic/Basic';
import { LineEditsAnalysisSection } from '../../components/Analysis/LineEdits/LineEditsAnalysis';
import { serverEndpoints } from 'api/generated/endpoints';
import Button from 'components/design-system/Button/Button';

const DASHBOARD_TABS = [
  { id: 'developmental', label: 'Developmental', icon: Edit },
  { id: 'line', label: 'Line-by-Line', icon: FileText },
  { id: 'copy', label: 'Copy Edits', icon: CheckSquare },
  { id: 'proofread', label: 'Proofread', icon: BookOpen },
  { id: 'settings', label: 'Settings', icon: Settings },
] as const;

export default function EditorialDashboard() {
  const [activeTab, setActiveTab] = useState('developmental');
  const navigate = useNavigate();
  const location = useLocation();

  // Initialize active tab from URL query parameter
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get('tab');
    if (tabParam && DASHBOARD_TABS.some(tab => tab.id === tabParam)) {
      setActiveTab(tabParam);
    }
  }, [location.search]);

  const changeTab = (tabId: string) => {
    setActiveTab(tabId);
    navigate({ search: `?tab=${tabId}` }, { replace: true });
  };

  const { id } = useParams<{ id: string }>();
  const [fileDetails, setFileDetails] = useState<UploadedFileData | null>(null);
  const [currentVersionDetails, setCurrentVersionDetails] =
    useState<TBookDetails | null>(null);
  const [llmModel, setLlmModel] = useState<TLlmModel>('o3-mini');
  const llmModelOptions: TLlmModel[] = [
    'gpt-4o-mini',
    'o1',
    'o1-mini',
    'o3-mini',
  ];
  const [error, setError] = useState<string | null>(null);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [isAuthorNGramAnalyzing, setIsAuthorNGramAnalyzing] = useState(false);
  const [isStoryBeatAnalyzing, setIsStoryBeatAnalyzing] = useState(false);
  const [isLineEditsAnalyzing, setIsLineEditsAnalyzing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isAcceptingOrRejecting, setIsAcceptingOrRejecting] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      post<UploadedFileData>(`/files/book/${id}`, {})
        .then(response => {
          setFileDetails(response);
          setCurrentVersionDetails(response.mostRecentDetails);
          setError(null);
        })
        .catch(error => {
          console.error('Error fetching file details:', error);
          setError('Failed to load file details. Please try again later.');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    // Only start polling if there's a pending analysis for the active tab
    const hasPendingAnalysis = () => {
      if (!currentVersionDetails) return false;

      switch (activeTab) {
        case 'developmental':
          return (
            currentVersionDetails.evaluationPending ||
            currentVersionDetails.storyBeatAnalysisPending
          );
        case 'line':
          return currentVersionDetails.lineEditAnalysisPending;
        case 'copy':
          return (
            currentVersionDetails.authorNGramAnalysisPending ||
            currentVersionDetails.characterNGramAnalysisPending
          );
        default:
          return false;
      }
    };

    if (!hasPendingAnalysis() || !currentVersionDetails) return;

    // Set up polling interval
    const pollInterval = setInterval(() => {
      const versionId = currentVersionDetails.versionId;
      if (versionId) {
        setIsLoading(true);
        post<TBookDetails>(`/files/book/version/${versionId}`, {})
          .then(response => {
            setCurrentVersionDetails(response);
            setError(null);
          })
          .catch(error => {
            console.error('Error fetching version details:', error);
            setError('Failed to load version details. Please try again later.');
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }, 30000); // Poll every 30 seconds

    // Cleanup function to clear interval
    return () => {
      clearInterval(pollInterval);
    };
  }, [activeTab, currentVersionDetails]);

  const isAdmin = useSelector(
    (state: RootState) => !!state.userAuth.user?.admin
  );

  const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedModel = event.target.value as TLlmModel;
    setLlmModel(selectedModel);
  };

  const handleEvaluationSubmit = async () => {
    if (!id || !currentVersionDetails) return;

    setIsEvaluating(true);
    try {
      const params: TStartEvaluationParams = {
        bookVersionId: currentVersionDetails.versionId,
        evaluationType: 'Evaluation',
        llmModel: llmModel,
      };
      const endpoint = serverEndpoints.startEvaluation();
      await post(endpoint, params);
      await post<TBookDetails>(
        `/files/book/version/${currentVersionDetails.versionId}`,
        {}
      )
        .then(response => {
          setCurrentVersionDetails(response);
          setError(null);
        })
        .catch(error => {
          console.error('Error fetching version details:', error);
          setError('Failed to load version details. Please try again later.');
        });
    } catch (error) {
      console.error('Error submitting evaluation:', error);
      setError('Failed to submit evaluation. Please try again later.');
    } finally {
      setIsEvaluating(false);
    }
  };

  const handleAuthorNGramAnalysis = async () => {
    if (!id || !currentVersionDetails) return;

    setIsAuthorNGramAnalyzing(true);
    try {
      const params: TStartEvaluationParams = {
        bookVersionId: currentVersionDetails.versionId,
        evaluationType: 'AuthorNGram',
        llmModel: null,
      };
      const endpoint = serverEndpoints.startEvaluation();
      await post(endpoint, params);
      await post<TBookDetails>(
        `/files/book/version/${currentVersionDetails.versionId}`,
        {}
      )
        .then(response => {
          setCurrentVersionDetails(response);
          setError(null);
        })
        .catch(error => {
          console.error('Error fetching version details:', error);
          setError('Failed to load version details. Please try again later.');
        });
    } catch (error) {
      console.error('Error submitting author NGram analysis:', error);
      setError(
        'Failed to submit author NGram analysis. Please try again later.'
      );
    } finally {
      setIsAuthorNGramAnalyzing(false);
    }
  };

  const handleStoryBeatAnalysis = async () => {
    if (!id || !currentVersionDetails) return;

    setIsStoryBeatAnalyzing(true);
    try {
      const params: TStartEvaluationParams = {
        bookVersionId: currentVersionDetails.versionId,
        evaluationType: 'StoryBeats',
        llmModel: llmModel,
      };
      const endpoint = serverEndpoints.startEvaluation();
      await post(endpoint, params);
      await post<TBookDetails>(
        `/files/book/version/${currentVersionDetails.versionId}`,
        {}
      )
        .then(response => {
          setCurrentVersionDetails(response);
          setError(null);
        })
        .catch(error => {
          console.error('Error fetching version details:', error);
          setError('Failed to load version details. Please try again later.');
        });
    } catch (error) {
      console.error('Error submitting story beat analysis:', error);
      setError('Failed to submit story beat analysis. Please try again later.');
    } finally {
      setIsStoryBeatAnalyzing(false);
    }
  };

  const handleLineEditsAnalysis = async () => {
    if (!id || !currentVersionDetails) return;

    setIsLineEditsAnalyzing(true);
    try {
      const params: TStartEvaluationParams = {
        bookVersionId: currentVersionDetails.versionId,
        evaluationType: 'LineEdits',
        llmModel: null,
      };
      const endpoint = serverEndpoints.startEvaluation();
      await post(endpoint, params);
      await post<TBookDetails>(
        `/files/book/version/${currentVersionDetails.versionId}`,
        {}
      )
        .then(response => {
          setCurrentVersionDetails(response);
          setError(null);
        })
        .catch(error => {
          console.error('Error fetching version details:', error);
          setError('Failed to load version details. Please try again later.');
        });
    } catch (error) {
      console.error('Error submitting line-by-line analysis:', error);
      setError(
        'Failed to submit line-by-line analysis. Please try again later.'
      );
    } finally {
      setIsLineEditsAnalyzing(false);
    }
  };

  const handleManuscriptAction = async (
    action: 'bookAccepted' | 'bookRejected'
  ) => {
    if (!id) return;

    setIsAcceptingOrRejecting(true);
    try {
      await post(`/files/book/${id}/action`, {
        action,
      });
      // Optionally refresh the file details after action
      await post<UploadedFileData>(`/files/book/${id}`, {})
        .then(response => {
          setFileDetails(response);
          setCurrentVersionDetails(response.mostRecentDetails);
          setError(null);
        })
        .catch(error => {
          console.error('Error fetching file details:', error);
          setError('Failed to load file details. Please try again later.');
        });
    } catch (error) {
      console.error('Error processing manuscript action:', error);
      setError('Failed to process manuscript action. Please try again later.');
    } finally {
      setIsAcceptingOrRejecting(false);
    }
  };
  const handleDeleteBook = async () => {
    if (!id) return;

    setIsDeleting(true);
    try {
      await post(`/files/book/${id}/delete`, {});
      // Redirect to submissions after a brief delay to show success message
      setTimeout(() => {
        navigate('/manuscripts');
      }, 2000);
    } catch (error) {
      console.error('Error deleting book:', error);
      setError('Failed to delete book. Please try again later.');
    } finally {
      setIsDeleting(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!fileDetails || !currentVersionDetails) {
    return (
      <div className={styles.errorMessage}>No file details available.</div>
    );
  }

  return (
    <div className="container">
      <h1 className={styles.title}>Book Development Dashboard</h1>
      {error && <p className={styles.errorMessage}>{error}</p>}
      <BasicDetails
        currentVersionDetails={currentVersionDetails}
        fileDetails={fileDetails}
        handleManuscriptAction={handleManuscriptAction}
        isAcceptingOrRejecting={isAcceptingOrRejecting}
        displayActions={false}
      />

      <div className={styles.tabs}>
        {DASHBOARD_TABS.map(tab => (
          <button
            key={tab.id}
            className={`${styles.tabButton} ${activeTab === tab.id ? styles.active : ''} ${tab.id === 'settings' ? styles.settings : ''}`}
            onClick={() => changeTab(tab.id)}
          >
            <tab.icon className={styles.icon} />
            <span className={styles.tabLabel}>{tab.label}</span>
          </button>
        ))}
      </div>
      <div className={styles.tabContent}>
        {activeTab === 'developmental' && (
          <div className={styles.analysisSections}>
            <nav className={styles.sectionNav}>
              <div className={styles.sectionLink}>Jump to</div>
              {DASHBOARD_TABS.filter(tab => tab.id === 'developmental').map(
                tab => (
                  <button
                    key={tab.id}
                    onClick={() =>
                      document
                        .getElementById('evaluation')
                        ?.scrollIntoView({ behavior: 'smooth' })
                    }
                    className={styles.sectionLink}
                  >
                    Evaluation
                  </button>
                )
              )}
              {DASHBOARD_TABS.filter(tab => tab.id === 'developmental').map(
                tab => (
                  <button
                    key={tab.id}
                    onClick={() =>
                      document
                        .getElementById('story-beats')
                        ?.scrollIntoView({ behavior: 'smooth' })
                    }
                    className={styles.sectionLink}
                  >
                    Story Beats
                  </button>
                )
              )}
            </nav>

            <section id="evaluation" className={styles.section}>
              <Evaluation
                evaluation={currentVersionDetails.evaluation}
                isPending={currentVersionDetails.evaluationPending}
                isEvaluating={isEvaluating}
                isOpen={true}
                onToggle={() => {}}
                onStartEvaluation={handleEvaluationSubmit}
                showVerdict={false}
              />
            </section>

            <section id="story-beats" className={styles.section}>
              <StoryBeatSection
                analysis={currentVersionDetails.storyBeatAnalysis}
                isPending={currentVersionDetails.storyBeatAnalysisPending}
                isAnalyzing={isStoryBeatAnalyzing}
                isOpen={true}
                onToggle={() => {}}
                onStartAnalysis={handleStoryBeatAnalysis}
              />
            </section>
          </div>
        )}
        {activeTab === 'line' && (
          <div className={styles.tabContent}>
            <h2>Line-by-Line Analysis</h2>
            <LineEditsAnalysisSection
              analysis={currentVersionDetails?.lineEditAnalysis}
              isPending={
                currentVersionDetails?.lineEditAnalysisPending || false
              }
              isAnalyzing={isLineEditsAnalyzing}
              onStartAnalysis={handleLineEditsAnalysis}
            />
          </div>
        )}
        {activeTab === 'copy' && (
          <NGramAnalysisSection
            title="Author NGram Analysis"
            analysis={currentVersionDetails.authorNGramAnalysis}
            isPending={currentVersionDetails.authorNGramAnalysisPending}
            isAnalyzing={isAuthorNGramAnalyzing}
            isOpen={true}
            onToggle={() => {}}
            onStartAnalysis={handleAuthorNGramAnalysis}
          />
        )}
        {activeTab === 'proofread' && <p>Coming Soon</p>}
        {activeTab === 'settings' && (
          <>
            {isAdmin && (
              <div className={styles.modelSelector}>
                <label htmlFor="modelSelect">Model: </label>
                <select
                  id="modelSelect"
                  value={llmModel}
                  onChange={handleModelChange}
                >
                  {llmModelOptions.map(model => (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className={styles.deleteSection}>
              <h2 className={styles.sectionTitle}>Danger Zone</h2>
              <p className={styles.deleteWarning}>
                Deleting this book will permanently remove it and all its
                versions. This action cannot be undone.
              </p>

              <Button
                className={styles.deleteButton}
                onClick={() => setShowDeleteDialog(true)}
                disabled={isDeleting}
              >
                {isDeleting ? 'Deleting...' : 'Delete Book'}
              </Button>

              <AlertDialog
                isOpen={showDeleteDialog}
                title="Are you absolutely sure?"
                description={`This will permanently delete "${fileDetails.title}" and all its associated data. This action cannot be undone.`}
                onConfirm={handleDeleteBook}
                onCancel={() => setShowDeleteDialog(false)}
                confirmText="Yes, Delete Book"
                cancelText="Cancel"
                isConfirming={isDeleting}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
