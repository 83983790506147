// src/pages/SeriesWiki/SeriesDashboard.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { seriesApi } from '../../services/api/seriesApi';
import './SeriesDashboard.css';
import { TSeriesResponse } from 'api/generated/types/common';

const SeriesDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [seriesList, setSeriesList] = useState<TSeriesResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchSeries = async () => {
      try {
        setLoading(true);
        const data = await seriesApi.getAllSeries();
        setSeriesList(data);
        setError(null);
      } catch (err) {
        setError('Failed to load series. Please try again later.');
        console.error('Error fetching series:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchSeries();
  }, []);

  // Filter series based on search
  const filteredSeries = seriesList.filter(
    series =>
      series.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (series.description &&
        series.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const handleCreateSeries = () => {
    navigate('/series/create');
  };

  const handleViewSeries = (seriesId: string) => {
    navigate(`/series/${seriesId}`);
  };

  return (
    <div className="series-dashboard-container">
      <div className="series-dashboard-header">
        <h1>Literary Series Wiki</h1>
        <p className="series-dashboard-description">
          Welcome to your Series Wiki! This is where you can organize and manage
          your literary series. Create a new series to start documenting your
          world, characters, and storylines. Each series can contain detailed
          information about its setting, timeline, themes, and characters.
        </p>
      </div>

      <div className="series-dashboard-actions">
        <button className="create-series-btn" onClick={handleCreateSeries}>
          Create New Series
        </button>
      </div>

      <div className="series-dashboard-search">
        <input
          type="text"
          className="series-search-input"
          placeholder="Search series by title or description..."
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>

      {loading ? (
        <div className="loading-indicator">Loading series...</div>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : filteredSeries.length === 0 ? (
        <div className="empty-state">
          {searchQuery ? (
            <>
              <h2>No matching series found</h2>
              <p>Try adjusting your search terms or create a new series.</p>
              <button
                className="create-series-btn"
                onClick={handleCreateSeries}
              >
                Create New Series
              </button>
            </>
          ) : (
            <>
              <h2>Welcome to your Series Wiki</h2>
              <p>
                Track your literary series, manage characters, settings, and
                plotlines in one place. Get started by creating your first
                series.
              </p>
              <button
                className="create-series-btn"
                onClick={handleCreateSeries}
              >
                Create Your First Series
              </button>
            </>
          )}
        </div>
      ) : (
        <div className="series-grid">
          {filteredSeries
            .sort(
              (a, b) =>
                new Date(b.updatedAt).getTime() -
                new Date(a.updatedAt).getTime()
            )
            .map(series => (
              <div
                key={series.id}
                className="series-card"
                onClick={() => handleViewSeries(series.id)}
              >
                <h2>{series.title}</h2>
                <p className="series-description">{series.description}</p>
                <div className="series-meta">
                  {series.genre && (
                    <span className="series-genre">{series.genre}</span>
                  )}
                  <span className="series-stats">
                    {/* TODO */}
                    {/* Books: {series.bookCount || 0} */}
                    Books: 0
                  </span>
                  <span className="series-stats">
                    {/* TODO */}
                    {/* Characters: {series.characterCount || 0} */}
                    Characters: 0
                  </span>
                </div>
                <div className="series-updated">
                  Last updated:{' '}
                  {new Date(series.updatedAt).toLocaleDateString()}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SeriesDashboard;
