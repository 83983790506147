// src/pages/Admin/JobQueueManagement/components/JobFilters.tsx
import React, { useState } from 'react';
import styles from './JobFilters.module.css';
import { TQueuedSubscriberStatus } from 'api/generated/types/common';

interface FiltersType {
  status?: TQueuedSubscriberStatus;
  search: string;
  page: number;
  limit: number;
  startDate?: string;
  endDate?: string;
}

interface JobFiltersProps {
  filters: FiltersType;
  onFilterChange: (filters: Partial<FiltersType>) => void;
}

const JobFilters: React.FC<JobFiltersProps> = ({ filters, onFilterChange }) => {
  const [searchInput, setSearchInput] = useState(filters.search);
  const [startDate, setStartDate] = useState(filters.startDate || '');
  const [endDate, setEndDate] = useState(filters.endDate || '');

  // Handle search input change with debounce
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInput(value);
  };

  // Apply search filter when user stops typing
  const handleSearchSubmit = () => {
    onFilterChange({ search: searchInput, page: 1 });
  };

  // Handle status filter change
  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    onFilterChange({
      status: value ? (value as TQueuedSubscriberStatus) : undefined,
      page: 1,
    });
  };

  // Handle date filter changes
  const handleDateChange = (type: 'start' | 'end', value: string) => {
    if (type === 'start') {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  // Apply date filters
  const applyDateFilters = () => {
    onFilterChange({
      startDate: startDate || undefined,
      endDate: endDate || undefined,
      page: 1,
    });
  };

  // Reset all filters
  const resetFilters = () => {
    setSearchInput('');
    setStartDate('');
    setEndDate('');
    onFilterChange({
      status: undefined,
      search: '',
      startDate: undefined,
      endDate: undefined,
      page: 1,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.filterRow}>
        {/* Search input */}
        <div className={styles.filterGroup}>
          <label htmlFor="search" className={styles.filterLabel}>
            Search
          </label>
          <div className={styles.searchInputWrapper}>
            <input
              id="search"
              type="text"
              className={styles.searchInput}
              placeholder="Search by job name or ID..."
              value={searchInput}
              onChange={handleSearchChange}
              onKeyDown={e => e.key === 'Enter' && handleSearchSubmit()}
            />
            <button
              className={styles.searchButton}
              onClick={handleSearchSubmit}
            >
              Search
            </button>
          </div>
        </div>

        {/* Status filter */}
        <div className={styles.filterGroup}>
          <label htmlFor="status" className={styles.filterLabel}>
            Status
          </label>
          <select
            id="status"
            className={styles.select}
            value={filters.status || ''}
            onChange={handleStatusChange}
          >
            <option value="">All Statuses</option>
            <option value="statusCompleted">Completed</option>
            <option value="statusFailed">Failed</option>
            <option value="statusBlocked">Blocked</option>
            <option value="statusPending">Pending</option>
            <option value="statusCanceled">Cancelled</option>
            <option value="statusScheduled">Scheduled</option>
            <option value="statusPaused">Paused</option>
            <option value="statusProcessing">Processing</option>
          </select>
        </div>

        {/* Date range filters */}
        <div className={styles.filterGroup}>
          <label className={styles.filterLabel}>Date Range</label>
          <div className={styles.dateInputs}>
            <input
              type="date"
              className={styles.dateInput}
              value={startDate}
              onChange={e => handleDateChange('start', e.target.value)}
              placeholder="Start date"
            />
            <span className={styles.dateSeparator}>to</span>
            <input
              type="date"
              className={styles.dateInput}
              value={endDate}
              onChange={e => handleDateChange('end', e.target.value)}
              placeholder="End date"
            />
            <button className={styles.applyButton} onClick={applyDateFilters}>
              Apply
            </button>
          </div>
        </div>
      </div>

      {/* Results per page and reset filters */}
      <div className={styles.filterRow}>
        <div className={styles.filterGroup}>
          <label htmlFor="limit" className={styles.filterLabel}>
            Results per page
          </label>
          <select
            id="limit"
            className={styles.select}
            value={filters.limit}
            onChange={e =>
              onFilterChange({ limit: Number(e.target.value), page: 1 })
            }
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>

        <button className={styles.resetButton} onClick={resetFilters}>
          Reset Filters
        </button>
      </div>
    </div>
  );
};

export default JobFilters;
