// src/components/AdminDashboardLayout.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AdminDashboardLayout.module.css';

interface AdminDashboardLayoutProps {
  children: React.ReactNode;
}

const AdminDashboardLayout: React.FC<AdminDashboardLayoutProps> = ({
  children,
}) => {
  return (
    <div className={styles.adminDashboard}>
      <nav className={styles.sidebar}>
        <ul>
          <li>
            <Link to="/admin">Dashboard</Link>
          </li>
          <li>
            <Link to="/admin/users">User Management</Link>
          </li>
          <li>
            <Link to="/admin/bugs">Bug Tracking</Link>
          </li>
          <li>
            <Link to="/admin/prompts">Prompt Management</Link>
          </li>
          <li>
            <Link to="/admin/job-queue">Job Queue Management</Link>
          </li>
        </ul>
      </nav>
      <main className={styles.content}>{children}</main>
    </div>
  );
};

export default AdminDashboardLayout;
