// src/pages/Feedback/FeedbackPage.tsx
import React from 'react';
import styles from './FeedbackPage.module.css';
import FeedbackForm from '../../components/Feedback/Feedback';

const FeedbackPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Share Your Feedback</h1>
      <p className={styles.description}>
        Your feedback helps us build better tools for storytellers. Let us know
        what features you'd like to see or what problems you're encountering.
      </p>
      <FeedbackForm subtitle="" />
    </div>
  );
};

export default FeedbackPage;
