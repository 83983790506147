import React, { useMemo, useState, useEffect, useRef } from 'react';
import Graph from 'react-vis-network-graph';
import './CharacterRelationshipGraph.css';
import {
  TCharacterRelationshipResponse,
  TCharacterResponse,
  TRelationshipType,
  TCharacterRelationshipRequest,
} from '../../api/generated/types/common';
import { serverEndpoints } from '../../api/generated/endpoints';
import { del, put } from '../../api/apiUtils';

interface CharacterRelationshipGraphProps {
  characters: TCharacterResponse[];
  relationships: TCharacterRelationshipResponse[];
  onClose: () => void;
  onRelationshipsUpdate?: (
    relationships: TCharacterRelationshipResponse[]
  ) => void;
}

// Helper functions for color management
const getRelationshipColor = (type: string | null): string => {
  if (!type) return '#9e9e9e'; // gray-400

  switch (type) {
    case 'parent':
    case 'child':
      return '#2c5f2d'; // primary-green
    case 'sibling':
      return '#4caf50'; // success
    case 'friend':
    case 'ally':
      return '#2196f3'; // info
    case 'enemy':
    case 'rival':
      return '#f44336'; // error
    case 'romantic':
      return '#ff8a80'; // error-light
    case 'mentor':
      return '#ffd700'; // accent-gold
    default:
      return '#9e9e9e'; // gray-400
  }
};

const getRoleColor = (role: string | null): string => {
  if (!role) return '#757575'; // gray-600

  switch (role) {
    case 'protagonist':
      return '#2c5f2d'; // primary-green
    case 'antagonist':
    case 'villain':
      return '#f44336'; // error
    case 'supporting':
      return '#2196f3'; // info
    case 'mentor':
      return '#ffd700'; // accent-gold
    case 'sidekick':
      return '#4caf50'; // success
    default:
      return '#757575'; // gray-600
  }
};

const formatRelationshipType = (type: string): string => {
  return type.charAt(0).toUpperCase() + type.slice(1);
};

const formatRole = (role: string): string => {
  return role.charAt(0).toUpperCase() + role.slice(1);
};

// Define interfaces for the graph library
interface Node {
  id: string;
  label: string;
  title?: string;
  color?: {
    background?: string;
    border?: string;
    highlight?: {
      background?: string;
      border?: string;
    };
    hover?: {
      background?: string;
      border?: string;
    };
  };
  font?: {
    color?: string;
    size?: number;
  };
  borderWidth?: number;
  borderWidthSelected?: number;
  shape?: string;
  size?: number;
}

interface Edge {
  id: string;
  from: string;
  to: string;
  label?: string;
  title?: string;
  width?: number;
  color?: {
    color?: string;
    highlight?: string;
    hover?: string;
  };
  font?: {
    size?: number;
    color?: string;
    strokeWidth?: number;
    strokeColor?: string;
    background?: string;
    align?: string;
  };
  smooth?: {
    type?: string;
    roundness?: number;
  };
  physics?: boolean;
}

interface Options {
  nodes?: {
    borderWidth?: number;
    shadow?: boolean;
    shapeProperties?: {
      borderRadius?: number;
    };
  };
  edges?: {
    arrows?: {
      to?: { enabled: boolean };
      from?: { enabled: boolean };
    };
    smooth?: {
      type?: string;
      roundness?: number;
    };
    shadow?: boolean;
  };
  physics?: {
    enabled?: boolean;
    barnesHut?: {
      gravitationalConstant?: number;
      centralGravity?: number;
      springLength?: number;
      springConstant?: number;
      damping?: number;
    };
  };
  interaction?: {
    hover?: boolean;
    tooltipDelay?: number;
    zoomView?: boolean;
    dragView?: boolean;
  };
  autoResize?: boolean;
}

const CharacterRelationshipGraph: React.FC<CharacterRelationshipGraphProps> = ({
  characters,
  relationships,
  onClose,
  onRelationshipsUpdate,
}) => {
  const [containerSize, setContainerSize] = useState({
    width: '100%',
    height: '80vh',
  });
  const [highlightedNode, setHighlightedNode] = useState<string | null>(null);
  const [highlightedEdge, setHighlightedEdge] = useState<string | null>(null);
  const networkRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // State for context menu
  interface ContextMenuState {
    visible: boolean;
    x: number;
    y: number;
    edgeId: string | null;
    relationship: TCharacterRelationshipResponse | null;
    showUpdateOptions: boolean;
    selectedRelationshipType: TRelationshipType | null;
  }

  const [contextMenu, setContextMenu] = useState<ContextMenuState>({
    visible: false,
    x: 0,
    y: 0,
    edgeId: null,
    relationship: null,
    showUpdateOptions: false,
    selectedRelationshipType: null,
  });

  // Create nodes and edges from character and relationship data
  const { nodes, edges } = useMemo(() => {
    // Create nodes from characters
    const nodes: Node[] = characters.map(char => ({
      id: char.id,
      label: char.name,
      title: `${char.name}${char.role ? ` (${char.role})` : ''}`,
      color: {
        background: getRoleColor(char.role),
        border: '#ffffff', // white
        highlight: {
          background: getRoleColor(char.role),
          border: '#ffffff', // white
        },
        hover: {
          background: getRoleColor(char.role),
          border: '#ffffff', // white
        },
      },
      font: {
        color: '#212121', // gray-800
        size: 14,
      },
      borderWidth: 2,
      borderWidthSelected: 4,
      shape: 'dot',
      size: 20, // Increased size for better visibility
    }));

    // Calculate max interaction count for normalization
    const maxInteractionCount = Math.max(
      ...relationships.map(rel => rel.interactionCount || 1),
      1
    );

    // Create edges from relationships
    const edges: Edge[] = relationships.map(rel => {
      const edgeColor = getRelationshipColor(rel.relationshipType);
      const width = ((rel.interactionCount || 1) / maxInteractionCount) * 5 + 1;

      return {
        id: `${rel.characterId1}-${rel.characterId2}`,
        from: rel.characterId1,
        to: rel.characterId2,
        label: rel.relationshipType || 'Unknown',
        title: `${rel.relationshipType || 'Unknown'}${rel.interactionCount ? ` - ${rel.interactionCount} interactions` : ''}`,
        width: width,
        color: {
          color: edgeColor,
          highlight: edgeColor,
          hover: edgeColor,
        },
        font: {
          size: 12,
          color: '#212121', // gray-800
          strokeWidth: 0,
          strokeColor: '#ffffff', // white
          background: 'rgba(255, 255, 255, 0.8)',
          align: 'middle',
        },
        smooth: {
          type: 'curvedCW',
          roundness: 0.2,
        },
        physics: true,
      };
    });

    return { nodes, edges };
  }, [characters, relationships]);

  // Update the network when nodes or edges change
  useEffect(() => {
    if (networkRef.current) {
      networkRef.current.setData({ nodes, edges });
      networkRef.current.redraw();
    }
  }, [nodes, edges]);

  // Apply highlighting to nodes and edges
  useEffect(() => {
    if (!networkRef.current) return;

    // Reset all nodes and edges to default appearance
    nodes.forEach(node => {
      if (networkRef.current.body.nodes[node.id]) {
        networkRef.current.body.nodes[node.id].setOptions({
          borderWidth: 2,
          borderWidthSelected: 4,
        });
      }
    });

    edges.forEach(edge => {
      if (networkRef.current.body.edges[edge.id]) {
        networkRef.current.body.edges[edge.id].setOptions({
          width: edge.width,
        });
      }
    });

    // Highlight the selected node and its connected edges
    if (highlightedNode) {
      // Highlight the node
      if (networkRef.current.body.nodes[highlightedNode]) {
        networkRef.current.body.nodes[highlightedNode].setOptions({
          borderWidth: 3,
          borderWidthSelected: 5,
        });
      }

      // Highlight connected edges
      edges.forEach(edge => {
        if (edge.from === highlightedNode || edge.to === highlightedNode) {
          if (networkRef.current.body.edges[edge.id]) {
            networkRef.current.body.edges[edge.id].setOptions({
              width: (edge.width || 1) * 2,
            });
          }
        }
      });
    }

    // Highlight the selected edge
    if (highlightedEdge && networkRef.current.body.edges[highlightedEdge]) {
      networkRef.current.body.edges[highlightedEdge].setOptions({
        width:
          (networkRef.current.body.edges[highlightedEdge].options.width || 1) *
          2,
      });
    }
  }, [highlightedNode, highlightedEdge, nodes, edges]);

  // Update container size when window resizes
  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        setContainerSize({
          width: '100%',
          height: '80vh',
        });

        // If network exists, resize it
        if (networkRef.current) {
          networkRef.current.setSize('100%', '80vh');
          networkRef.current.redraw();
          networkRef.current.fit();
        }
      }
    };

    // Initial size update
    updateSize();

    // Set up resize listener
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  // Hide context menu when clicking outside
  useEffect(() => {
    const handleClickOutside = () => {
      setContextMenu(prev => ({ ...prev, visible: false }));
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Add direct event listener for right-click on the canvas
  useEffect(() => {
    if (!networkRef.current) return;

    const handleContextMenu = (event: MouseEvent) => {
      // Prevent default browser context menu
      event.preventDefault();

      // Get the position relative to the canvas
      const canvas = networkRef.current.canvas.frame.canvas;
      const rect = canvas.getBoundingClientRect();
      const pos = {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      };

      // Get the edge at the position
      const edgeId = networkRef.current.getEdgeAt(pos);

      if (edgeId) {
        // Find the relationship
        const relationship = relationships.find(rel => {
          const edge1 = `${rel.characterId1}-${rel.characterId2}`;
          const edge2 = `${rel.characterId2}-${rel.characterId1}`;
          return edgeId === edge1 || edgeId === edge2;
        });

        if (relationship) {
          // Show the context menu
          setContextMenu({
            visible: true,
            x: event.clientX,
            y: event.clientY,
            edgeId: edgeId,
            relationship: relationship,
            showUpdateOptions: false,
            selectedRelationshipType: relationship.relationshipType,
          });
          return;
        }
      }

      // Hide context menu if not on an edge
      setContextMenu(prev => ({ ...prev, visible: false }));
    };

    // Add the event listener to the canvas
    const canvas = networkRef.current.canvas.frame.canvas;
    canvas.addEventListener('contextmenu', handleContextMenu);

    // Clean up
    return () => {
      if (networkRef.current && networkRef.current.canvas) {
        const canvas = networkRef.current.canvas.frame.canvas;
        canvas.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, [relationships]);

  // Network options
  const options: Options = {
    nodes: {
      borderWidth: 2,
      shadow: true,
      shapeProperties: {
        borderRadius: 10,
      },
    },
    edges: {
      arrows: {
        to: { enabled: false },
        from: { enabled: false },
      },
      smooth: {
        type: 'curvedCW',
        roundness: 0.2,
      },
      shadow: true,
    },
    physics: {
      enabled: true,
      barnesHut: {
        gravitationalConstant: -5000,
        centralGravity: 0.3,
        springLength: 150,
        springConstant: 0.04,
        damping: 0.09,
      },
    },
    interaction: {
      hover: true,
      tooltipDelay: 200,
      zoomView: true,
      dragView: true,
    },
    autoResize: true,
  };

  // Event handlers
  const events = {
    selectNode: (params: { nodes: string[] }) => {
      if (params.nodes.length > 0) {
        setHighlightedNode(params.nodes[0]);
      }
    },
    deselectNode: () => {
      setHighlightedNode(null);
    },
    selectEdge: (params: { edges: string[] }) => {
      if (params.edges.length > 0) {
        setHighlightedEdge(params.edges[0]);
      }
    },
    deselectEdge: () => {
      setHighlightedEdge(null);
    },
    hoverNode: (params: { node: string }) => {
      setHighlightedNode(params.node);
    },
    blurNode: () => {
      setHighlightedNode(null);
    },
    hoverEdge: (params: { edge: string }) => {
      setHighlightedEdge(params.edge);
    },
    blurEdge: () => {
      setHighlightedEdge(null);
    },
  };

  // Extract unique character roles and relationship types for the legend
  const uniqueRoles = useMemo(() => {
    const roles = new Set<string>();
    characters.forEach(char => {
      if (char.role) {
        roles.add(char.role);
      }
    });
    return Array.from(roles);
  }, [characters]);

  const uniqueRelationshipTypes = useMemo(() => {
    const types = new Set<string>();
    relationships.forEach(rel => {
      if (rel.relationshipType) {
        types.add(rel.relationshipType);
      }
    });
    return Array.from(types);
  }, [relationships]);

  return (
    <div className="character-relationship-graph">
      <div className="graph-header">
        <h2>Character Relationships</h2>
        <div className="graph-controls">
          <button
            className="close-graph-btn"
            onClick={onClose}
            aria-label="Hide relationship graph"
          >
            Hide Relationships
          </button>
        </div>
      </div>

      <div className="graph-container" ref={containerRef}>
        {/* @ts-ignore */}
        <Graph
          graph={{ nodes, edges }}
          options={options}
          events={events}
          style={{ width: containerSize.width, height: containerSize.height }}
          getNetwork={(network: any) => {
            // Store network reference for direct manipulation
            networkRef.current = network;

            // Ensure the network canvas is properly sized
            setTimeout(() => {
              if (networkRef.current) {
                networkRef.current.setSize(
                  containerSize.width,
                  containerSize.height
                );
                networkRef.current.redraw();
                networkRef.current.fit();
              }
            }, 100);
          }}
        />

        {/* Context Menu */}
        {contextMenu.visible && (
          <div
            className="edge-context-menu"
            style={{
              position: 'absolute',
              top: `${contextMenu.y}px`,
              left: `${contextMenu.x}px`,
              backgroundColor: '#ffffff',
              border: '1px solid #e0e0e0',
              borderRadius: '4px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
              padding: '8px 0',
              zIndex: 1000,
              minWidth: '150px',
            }}
          >
            <div
              style={{
                padding: '4px 12px',
                fontWeight: 'bold',
                borderBottom: '1px solid #f5f5f5',
              }}
            >
              Relationship Options
            </div>
            <div
              style={{
                padding: '8px 12px',
                cursor: 'pointer',
                transition: 'background-color 0.2s ease',
              }}
              onMouseEnter={e => {
                e.currentTarget.style.backgroundColor = '#f5f5f5';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.backgroundColor = 'transparent';
              }}
              onClick={e => {
                // Prevent the click from propagating to the document
                e.stopPropagation();

                // Toggle update options
                setContextMenu(prev => ({
                  ...prev,
                  showUpdateOptions: !prev.showUpdateOptions,
                  selectedRelationshipType:
                    (prev.relationship
                      ?.relationshipType as TRelationshipType) || null,
                }));
              }}
            >
              Update Relationship
            </div>

            {contextMenu.showUpdateOptions && (
              <div
                className="update-options"
                style={{ padding: '8px 12px' }}
                onClick={e => e.stopPropagation()} // Prevent clicks inside update options from closing the menu
              >
                <label
                  htmlFor="relationshipType"
                  style={{
                    display: 'block',
                    marginBottom: '4px',
                    fontSize: '14px',
                  }}
                >
                  Relationship Type:
                </label>
                <select
                  id="relationshipType"
                  value={contextMenu.selectedRelationshipType || ''}
                  onChange={e => {
                    setContextMenu(prev => ({
                      ...prev,
                      selectedRelationshipType: e.target
                        .value as TRelationshipType,
                    }));
                  }}
                  style={{
                    width: '100%',
                    padding: '6px',
                    borderRadius: '4px',
                    border: '1px solid var(--color-gray-300)',
                    marginBottom: '8px',
                    backgroundColor: 'white',
                  }}
                >
                  <option value="">Select Type</option>
                  <option value="parent">Parent</option>
                  <option value="child">Child</option>
                  <option value="sibling">Sibling</option>
                  <option value="friend">Friend</option>
                  <option value="enemy">Enemy</option>
                  <option value="romantic">Romantic</option>
                  <option value="mentor">Mentor</option>
                  <option value="ally">Ally</option>
                  <option value="rival">Rival</option>
                </select>

                <button
                  onClick={e => {
                    // Prevent the click from propagating
                    e.stopPropagation();

                    if (
                      contextMenu.relationship?.id &&
                      contextMenu.selectedRelationshipType
                    ) {
                      // Prepare the update request
                      const updateData: TCharacterRelationshipRequest = {
                        seriesId: contextMenu.relationship.seriesId,
                        characterId1: contextMenu.relationship.characterId1,
                        characterId2: contextMenu.relationship.characterId2,
                        relationshipType: contextMenu.selectedRelationshipType,
                        interactionCount:
                          contextMenu.relationship.interactionCount,
                      };

                      // Call the update API
                      put<TCharacterRelationshipResponse[]>(
                        serverEndpoints.characterRelationshipsCharacterRelationshipUpdate(
                          {
                            characterRelationshipId:
                              contextMenu.relationship.id,
                          }
                        ),
                        updateData
                      )
                        .then(updatedRelationships => {
                          console.log('Relationship updated successfully');

                          // Update the relationships in the parent component if callback provided
                          if (onRelationshipsUpdate) {
                            onRelationshipsUpdate(updatedRelationships);
                          }
                        })
                        .catch(error => {
                          console.error(
                            'Failed to update relationship:',
                            error
                          );
                        });

                      // Close the context menu
                      setContextMenu(prev => ({ ...prev, visible: false }));
                    }
                  }}
                  style={{
                    backgroundColor: 'var(--color-primary-green)',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    padding: '6px 12px',
                    cursor: 'pointer',
                    fontSize: '14px',
                    width: '100%',
                  }}
                >
                  Save Changes
                </button>
              </div>
            )}

            <div
              className="context-menu-item delete-item"
              style={{
                padding: '8px 12px',
                cursor: 'pointer',
                borderTop: '1px solid #f5f5f5',
                color: '#f44336',
                fontWeight: 'bold',
                transition: 'background-color 0.2s ease',
              }}
              onMouseEnter={e => {
                e.currentTarget.style.backgroundColor = '#ffebee';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.backgroundColor = 'transparent';
              }}
              onClick={() => {
                console.log(
                  'Delete relationship clicked for edge:',
                  contextMenu.edgeId
                );
                console.log('Relationship:', contextMenu.relationship);

                // Call the delete API if we have a relationship ID
                if (contextMenu.relationship?.id) {
                  del<TCharacterRelationshipResponse[]>(
                    serverEndpoints.characterRelationshipsCharacterRelationshipDelete(
                      {
                        characterRelationshipId: contextMenu.relationship.id,
                      }
                    )
                  )
                    .then(updatedRelationships => {
                      console.log('Relationship deleted successfully');

                      // Update the relationships in the parent component if callback provided
                      if (onRelationshipsUpdate) {
                        onRelationshipsUpdate(updatedRelationships);
                      }
                    })
                    .catch(error => {
                      console.error('Failed to delete relationship:', error);
                    });
                }

                setContextMenu(prev => ({ ...prev, visible: false }));
              }}
            >
              Delete Relationship
            </div>
          </div>
        )}
      </div>

      <div className="graph-legend">
        <div className="legend-section">
          <h3>Character Roles</h3>
          <div className="legend-items">
            {uniqueRoles.length > 0 ? (
              uniqueRoles.map(role => (
                <div className="legend-item" key={`role-${role}`}>
                  <div
                    className="legend-color"
                    style={{ backgroundColor: getRoleColor(role) }}
                  ></div>
                  <span>{formatRole(role)}</span>
                </div>
              ))
            ) : (
              <div className="legend-empty-message">
                No character roles defined. Add roles to characters for color
                coding.
              </div>
            )}
          </div>
        </div>

        <div className="legend-section">
          <h3>Relationship Types</h3>
          <div className="legend-items">
            {uniqueRelationshipTypes.length > 0 ? (
              uniqueRelationshipTypes.map(type => (
                <div className="legend-item" key={`rel-${type}`}>
                  <div
                    className="legend-color legend-line"
                    style={{ backgroundColor: getRelationshipColor(type) }}
                  ></div>
                  <span>{formatRelationshipType(type)}</span>
                </div>
              ))
            ) : (
              <div className="legend-empty-message">
                No relationship types defined. Add relationship types for color
                coding.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CharacterRelationshipGraph;
