import React from 'react';
import { Activity, GitBranch, GitMerge, Workflow, Map } from 'lucide-react';
import styles from '../EditorialAnalysis/EditorialAnalysis.module.css';

const StoryBeatAnalysis = () => {
  return (
    <div className={styles.container}>
      {/* Hero Section */}
      <div className={styles.hero}>
        <h1 className={styles.heroTitle}>Story Beat Analysis</h1>
        <p className={styles.heroSubtitle}>
          Map and analyze your story's rhythm, pacing, and narrative connections
          with sophisticated beat tracking
        </p>
      </div>

      {/* Key Features */}
      <div className={styles.features}>
        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <Activity className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Beat Mapping</h3>
          </div>
          <p className={styles.featureDescription}>
            Visualize your story's progression through character-driven and
            external events, revealing the underlying rhythm of your narrative.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <GitBranch className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Connection Analysis</h3>
          </div>
          <p className={styles.featureDescription}>
            Understand how beats connect and flow using "And Then," "But," and
            "Therefore" transitions, with strength ratings for each connection.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <GitMerge className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Narrative Balance</h3>
          </div>
          <p className={styles.featureDescription}>
            Track the balance between character-driven and external plot events,
            ensuring your story maintains engaging momentum.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <Workflow className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Story Flow</h3>
          </div>
          <p className={styles.featureDescription}>
            Identify patterns in your narrative progression and ensure smooth
            transitions between key story moments.
          </p>
        </div>

        <div className={styles.featureCard}>
          <div className={styles.featureHeader}>
            <Map className={styles.featureIcon} size={24} />
            <h3 className={styles.featureTitle}>Beat Sequencing</h3>
          </div>
          <p className={styles.featureDescription}>
            Analyze the sequence and spacing of your story beats to optimize
            pacing and maintain reader engagement.
          </p>
        </div>
      </div>

      {/* How It Works Section */}
      <div className={styles.howToUse}>
        <h2 className={styles.sectionTitle}>
          Understanding Your Story's Rhythm
        </h2>

        <div className={styles.content}>
          <p className={styles.introText}>
            Our Story Beat Analysis tool provides a visual map of your
            narrative's progression, helping you understand and optimize your
            story's structure, pacing, and connections.
          </p>

          <div className={styles.usageGrid}>
            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>1. Beat Identification</h3>
              <p>
                The tool automatically identifies and categorizes key story
                moments as either character-driven or external events, plotting
                them on an intuitive timeline visualization.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>2. Connection Mapping</h3>
              <p>
                Understand how your story beats connect using three fundamental
                transition types: "And Then" (sequential), "But" (conflict), and
                "Therefore" (causation), each rated for strength and impact.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>3. Pattern Analysis</h3>
              <p>
                Discover patterns in your narrative structure, including the
                distribution of character vs. external events and the rhythm of
                your story's progression.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>4. Flow Optimization</h3>
              <p>
                Use the visual beat map to identify potential pacing issues,
                strengthen narrative connections, and ensure smooth story
                progression.
              </p>
            </div>
          </div>

          <div className={styles.exampleContainer}>
            <img
              src="/images/woven-story-beats.png"
              alt="Story beat analysis visualization showing character and external events"
              className={styles.exampleImage}
            />
            <p className={styles.imageCaption}>
              Example beat map showing the interplay between character-driven
              and external story events
            </p>
          </div>
        </div>
      </div>

      {/* For Writers Section */}
      <div className={styles.howToUse}>
        <h2 className={styles.sectionTitle}>For Writers</h2>
        <div className={styles.content}>
          <p className={styles.introText}>
            Whether you're plotting a new story or refining an existing
            manuscript, our Story Beat Analysis tool helps you understand and
            optimize your narrative structure.
          </p>

          <div className={styles.usageGrid}>
            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>Story Architecture</h3>
              <p>
                Visualize your story's structural foundation and ensure it
                supports your narrative goals effectively.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>Pacing Refinement</h3>
              <p>
                Use beat distribution analysis to identify and correct pacing
                issues before they impact reader engagement.
              </p>
            </div>

            <div className={styles.usageItem}>
              <h3 className={styles.usageTitle}>Character Integration</h3>
              <p>
                Balance character-driven moments with external events to create
                a rich, engaging narrative experience.
              </p>
            </div>

            <div className={`${styles.featureCard} ${styles.comingSoonCard}`}>
              <h3 className={styles.usageTitle}>Collaborative Analysis</h3>
              <p>
                Share beat maps with editors and writing groups to facilitate
                focused, productive story discussions.
              </p>
              <div className={styles.comingSoonBadge}>Coming Soon</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoryBeatAnalysis;
