import { get, post, put, del } from '../../api/apiUtils';
import {
  TSeriesRequest,
  TSeriesResponse,
} from '../../api/generated/types/common';

export const seriesApi = {
  getAllSeries: async (): Promise<TSeriesResponse[]> => {
    return get<TSeriesResponse[]>(`/wiki/series/list`);
  },

  getSeries: async (id: string): Promise<TSeriesResponse> => {
    return get<TSeriesResponse>(`/wiki/series/${id}/get`);
  },

  createSeries: async (
    seriesData: TSeriesRequest
  ): Promise<TSeriesResponse> => {
    return post<TSeriesResponse>(`/wiki/series/create`, seriesData);
  },

  updateSeries: async (
    id: string,
    seriesData: Partial<TSeriesRequest>
  ): Promise<TSeriesResponse> => {
    return put<TSeriesResponse>(`/wiki/series/${id}/update`, seriesData);
  },

  deleteSeries: async (id: string): Promise<void> => {
    return del(`/wiki/series/${id}/delete`);
  },
};

export default seriesApi;
