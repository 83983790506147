// src/components/SeriesWiki/Characters/CharacterForm.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post, put } from '../../../api/apiUtils';
import {
  TCharacterRequest,
  TCharacterResponse,
} from '../../../api/generated/types/common';
import './CharacterForm.css';
import Button from 'components/design-system/Button/Button';

interface CharacterFormProps {
  seriesId: string;
  character?: TCharacterResponse;
  mode: 'create' | 'edit';
}

const CharacterForm: React.FC<CharacterFormProps> = ({
  seriesId,
  character,
  mode,
}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<TCharacterRequest>({
    seriesId,
    name: character?.name || '',
    aliases: character?.aliases || null,
    age: character?.age || null,
    birthDate: character?.birthDate || null,
    deathDate: character?.deathDate || null,
    gender: character?.gender || null,
    occupation: character?.occupation || null,
    role: character?.role || null,
    firstAppearance: character?.firstAppearance || null,
    physicalDescription: character?.physicalDescription || null,
    personality: character?.personality || null,
    background: character?.background || null,
    goals: character?.goals || null,
    fears: character?.fears || null,
    characterArc: character?.characterArc || null,
    notes: character?.notes || null,
  });

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prev: TCharacterRequest) => ({
      ...prev,
      [name]: value || null,
    }));
  };

  const handleArrayInputChange = (
    field: 'goals' | 'fears' | 'aliases',
    index: number,
    value: string
  ) => {
    const currentArray = formData[field] || [];
    const newArray = [...currentArray];
    newArray[index] = value;
    setFormData(prev => ({
      ...prev,
      [field]: newArray.length > 0 ? newArray : null,
    }));
  };

  const handleAddArrayItem = (field: 'goals' | 'fears' | 'aliases') => {
    const currentArray = formData[field] || [];
    setFormData(prev => ({
      ...prev,
      [field]: [...currentArray, ''],
    }));
  };

  const handleRemoveArrayItem = (
    field: 'goals' | 'fears' | 'aliases',
    index: number
  ) => {
    const currentArray = formData[field] || [];
    const newArray = [...currentArray];
    newArray.splice(index, 1);
    setFormData(prev => ({
      ...prev,
      [field]: newArray.length > 0 ? newArray : null,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (mode === 'create') {
        await post('/wiki/characters/create', formData);
      } else {
        await put(`/wiki/characters/${character?.id}/update`, formData);
      }
      navigate(`/series/${seriesId}?tab=characters`);
    } catch (error) {
      console.error('Error saving character:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="character-form">
      <div className="form-section">
        <h2>Basic Information</h2>
        <div className="form-group">
          <label htmlFor="name">Name *</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Aliases</label>
          {(formData.aliases || []).map((alias: string, index: number) => (
            <div key={index} className="array-input-group">
              <input
                type="text"
                value={alias}
                onChange={e =>
                  handleArrayInputChange('aliases', index, e.target.value)
                }
              />
              <button
                type="button"
                onClick={() => handleRemoveArrayItem('aliases', index)}
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => handleAddArrayItem('aliases')}
            className="add-button"
          >
            Add Alias
          </button>
        </div>

        <div className="form-group">
          <label htmlFor="role">Role</label>
          <select
            id="role"
            name="role"
            value={formData.role || ''}
            onChange={handleInputChange}
          >
            <option value="">Select a role</option>
            <option value="protagonist">Protagonist</option>
            <option value="antagonist">Antagonist</option>
            <option value="supporting">Supporting</option>
            <option value="mentor">Mentor</option>
            <option value="sidekick">Sidekick</option>
            <option value="villain">Villain</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="age">Age</label>
          <input
            type="text"
            id="age"
            name="age"
            value={formData.age || ''}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="gender">Gender</label>
          <input
            type="text"
            id="gender"
            name="gender"
            value={formData.gender || ''}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="occupation">Occupation</label>
          <input
            type="text"
            id="occupation"
            name="occupation"
            value={formData.occupation || ''}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="form-section">
        <h2>Character Details</h2>
        <div className="form-group">
          <label htmlFor="physicalDescription">Physical Description</label>
          <textarea
            id="physicalDescription"
            name="physicalDescription"
            value={formData.physicalDescription || ''}
            onChange={handleInputChange}
            rows={4}
          />
        </div>

        <div className="form-group">
          <label htmlFor="personality">Personality</label>
          <textarea
            id="personality"
            name="personality"
            value={formData.personality || ''}
            onChange={handleInputChange}
            rows={4}
          />
        </div>

        <div className="form-group">
          <label htmlFor="background">Background</label>
          <textarea
            id="background"
            name="background"
            value={formData.background || ''}
            onChange={handleInputChange}
            rows={4}
          />
        </div>
      </div>

      <div className="form-section">
        <h2>Character Development</h2>
        <div className="form-group">
          <label>Goals</label>
          {(formData.goals || []).map((goal, index) => (
            <div key={index} className="array-input-group">
              <input
                type="text"
                value={goal}
                onChange={e =>
                  handleArrayInputChange('goals', index, e.target.value)
                }
              />
              <button
                type="button"
                onClick={() => handleRemoveArrayItem('goals', index)}
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => handleAddArrayItem('goals')}
            className="add-button"
          >
            Add Goal
          </button>
        </div>

        <div className="form-group">
          <label>Fears</label>
          {(formData.fears || []).map((fear, index) => (
            <div key={index} className="array-input-group">
              <input
                type="text"
                value={fear}
                onChange={e =>
                  handleArrayInputChange('fears', index, e.target.value)
                }
              />
              <button
                type="button"
                onClick={() => handleRemoveArrayItem('fears', index)}
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => handleAddArrayItem('fears')}
            className="add-button"
          >
            Add Fear
          </button>
        </div>

        <div className="form-group">
          <label htmlFor="characterArc">Character Arc</label>
          <textarea
            id="characterArc"
            name="characterArc"
            value={formData.characterArc || ''}
            onChange={handleInputChange}
            rows={4}
          />
        </div>
      </div>

      <div className="form-section">
        <h2>Timeline</h2>
        <div className="form-group">
          <label htmlFor="birthDate">Birth Date</label>
          <input
            type="text"
            id="birthDate"
            name="birthDate"
            value={formData.birthDate || ''}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="deathDate">Death Date</label>
          <input
            type="text"
            id="deathDate"
            name="deathDate"
            value={formData.deathDate || ''}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="firstAppearance">First Appearance</label>
          <input
            type="text"
            id="firstAppearance"
            name="firstAppearance"
            value={formData.firstAppearance || ''}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="form-section">
        <h2>Additional Information</h2>
        <div className="form-group">
          <label htmlFor="notes">Notes</label>
          <textarea
            id="notes"
            name="notes"
            value={formData.notes || ''}
            onChange={handleInputChange}
            rows={4}
          />
        </div>
      </div>

      <div className="form-actions">
        <Button variant="secondary" onClick={() => navigate(-1)}>
          Cancel
        </Button>
        <button type="submit" className="submit-button">
          {mode === 'create' ? 'Create Character' : 'Update Character'}
        </button>
      </div>
    </form>
  );
};

export default CharacterForm;
