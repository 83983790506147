export interface Country {
  numericCode: number;
  englishName: string;
  alpha2: string; // two-character code
  alpha3: string; // three-character code
}

// Recreation of Haskell's Country mapping
export const countryList = [
  { numericCode: 4, englishName: 'Afghanistan', alpha2: 'AF', alpha3: 'AFG' },
  {
    numericCode: 248,
    englishName: 'Åland Islands',
    alpha2: 'AX',
    alpha3: 'ALA',
  },
  { numericCode: 8, englishName: 'Albania', alpha2: 'AL', alpha3: 'ALB' },
  { numericCode: 12, englishName: 'Algeria', alpha2: 'DZ', alpha3: 'DZA' },
  {
    numericCode: 16,
    englishName: 'American Samoa',
    alpha2: 'AS',
    alpha3: 'ASM',
  },
  { numericCode: 20, englishName: 'Andorra', alpha2: 'AD', alpha3: 'AND' },
  { numericCode: 24, englishName: 'Angola', alpha2: 'AO', alpha3: 'AGO' },
  { numericCode: 660, englishName: 'Anguilla', alpha2: 'AI', alpha3: 'AIA' },
  { numericCode: 10, englishName: 'Antarctica', alpha2: 'AQ', alpha3: 'ATA' },
  {
    numericCode: 28,
    englishName: 'Antigua and Barbuda',
    alpha2: 'AG',
    alpha3: 'ATG',
  },
  { numericCode: 32, englishName: 'Argentina', alpha2: 'AR', alpha3: 'ARG' },
  { numericCode: 51, englishName: 'Armenia', alpha2: 'AM', alpha3: 'ARM' },
  { numericCode: 533, englishName: 'Aruba', alpha2: 'AW', alpha3: 'ABW' },
  { numericCode: 36, englishName: 'Australia', alpha2: 'AU', alpha3: 'AUS' },
  { numericCode: 40, englishName: 'Austria', alpha2: 'AT', alpha3: 'AUT' },
  { numericCode: 31, englishName: 'Azerbaijan', alpha2: 'AZ', alpha3: 'AZE' },
  { numericCode: 44, englishName: 'Bahamas', alpha2: 'BS', alpha3: 'BHS' },
  { numericCode: 48, englishName: 'Bahrain', alpha2: 'BH', alpha3: 'BHR' },
  { numericCode: 50, englishName: 'Bangladesh', alpha2: 'BD', alpha3: 'BGD' },
  { numericCode: 52, englishName: 'Barbados', alpha2: 'BB', alpha3: 'BRB' },
  { numericCode: 112, englishName: 'Belarus', alpha2: 'BY', alpha3: 'BLR' },
  { numericCode: 56, englishName: 'Belgium', alpha2: 'BE', alpha3: 'BEL' },
  { numericCode: 84, englishName: 'Belize', alpha2: 'BZ', alpha3: 'BLZ' },
  { numericCode: 204, englishName: 'Benin', alpha2: 'BJ', alpha3: 'BEN' },
  { numericCode: 60, englishName: 'Bermuda', alpha2: 'BM', alpha3: 'BMU' },
  { numericCode: 64, englishName: 'Bhutan', alpha2: 'BT', alpha3: 'BTN' },
  {
    numericCode: 68,
    englishName: 'Bolivia (Plurinational State of)',
    alpha2: 'BO',
    alpha3: 'BOL',
  },
  {
    numericCode: 535,
    englishName: 'Bonaire, Sint Eustatius and Saba',
    alpha2: 'BQ',
    alpha3: 'BES',
  },
  {
    numericCode: 70,
    englishName: 'Bosnia and Herzegovina',
    alpha2: 'BA',
    alpha3: 'BIH',
  },
  { numericCode: 72, englishName: 'Botswana', alpha2: 'BW', alpha3: 'BWA' },
  {
    numericCode: 74,
    englishName: 'Bouvet Island',
    alpha2: 'BV',
    alpha3: 'BVT',
  },
  { numericCode: 76, englishName: 'Brazil', alpha2: 'BR', alpha3: 'BRA' },
  {
    numericCode: 86,
    englishName: 'British Indian Ocean Territory',
    alpha2: 'IO',
    alpha3: 'IOT',
  },
  {
    numericCode: 96,
    englishName: 'Brunei Darussalam',
    alpha2: 'BN',
    alpha3: 'BRN',
  },
  { numericCode: 100, englishName: 'Bulgaria', alpha2: 'BG', alpha3: 'BGR' },
  {
    numericCode: 854,
    englishName: 'Burkina Faso',
    alpha2: 'BF',
    alpha3: 'BFA',
  },
  { numericCode: 108, englishName: 'Burundi', alpha2: 'BI', alpha3: 'BDI' },
  { numericCode: 116, englishName: 'Cambodia', alpha2: 'KH', alpha3: 'KHM' },
  { numericCode: 120, englishName: 'Cameroon', alpha2: 'CM', alpha3: 'CMR' },
  { numericCode: 124, englishName: 'Canada', alpha2: 'CA', alpha3: 'CAN' },
  { numericCode: 132, englishName: 'Cabo Verde', alpha2: 'CV', alpha3: 'CPV' },
  {
    numericCode: 136,
    englishName: 'Cayman Islands',
    alpha2: 'KY',
    alpha3: 'CYM',
  },
  {
    numericCode: 140,
    englishName: 'Central African Republic',
    alpha2: 'CF',
    alpha3: 'CAF',
  },
  { numericCode: 148, englishName: 'Chad', alpha2: 'TD', alpha3: 'TCD' },
  { numericCode: 152, englishName: 'Chile', alpha2: 'CL', alpha3: 'CHL' },
  { numericCode: 156, englishName: 'China', alpha2: 'CN', alpha3: 'CHN' },
  {
    numericCode: 162,
    englishName: 'Christmas Island',
    alpha2: 'CX',
    alpha3: 'CXR',
  },
  {
    numericCode: 166,
    englishName: 'Cocos (Keeling) Islands',
    alpha2: 'CC',
    alpha3: 'CCK',
  },
  { numericCode: 170, englishName: 'Colombia', alpha2: 'CO', alpha3: 'COL' },
  { numericCode: 174, englishName: 'Comoros', alpha2: 'KM', alpha3: 'COM' },
  { numericCode: 178, englishName: 'Congo', alpha2: 'CG', alpha3: 'COG' },
  {
    numericCode: 180,
    englishName: 'Congo (Democratic Republic of the)',
    alpha2: 'CD',
    alpha3: 'COD',
  },
  {
    numericCode: 184,
    englishName: 'Cook Islands',
    alpha2: 'CK',
    alpha3: 'COK',
  },
  { numericCode: 188, englishName: 'Costa Rica', alpha2: 'CR', alpha3: 'CRI' },
  {
    numericCode: 384,
    englishName: "Côte d'Ivoire",
    alpha2: 'CI',
    alpha3: 'CIV',
  },
  { numericCode: 191, englishName: 'Croatia', alpha2: 'HR', alpha3: 'HRV' },
  { numericCode: 192, englishName: 'Cuba', alpha2: 'CU', alpha3: 'CUB' },
  { numericCode: 531, englishName: 'Curaçao', alpha2: 'CW', alpha3: 'CUW' },
  { numericCode: 196, englishName: 'Cyprus', alpha2: 'CY', alpha3: 'CYP' },
  {
    numericCode: 203,
    englishName: 'Czech Republic',
    alpha2: 'CZ',
    alpha3: 'CZE',
  },
  { numericCode: 208, englishName: 'Denmark', alpha2: 'DK', alpha3: 'DNK' },
  { numericCode: 262, englishName: 'Djibouti', alpha2: 'DJ', alpha3: 'DJI' },
  { numericCode: 212, englishName: 'Dominica', alpha2: 'DM', alpha3: 'DMA' },
  {
    numericCode: 214,
    englishName: 'Dominican Republic',
    alpha2: 'DO',
    alpha3: 'DOM',
  },
  { numericCode: 218, englishName: 'Ecuador', alpha2: 'EC', alpha3: 'ECU' },
  { numericCode: 818, englishName: 'Egypt', alpha2: 'EG', alpha3: 'EGY' },
  {
    numericCode: 222,
    englishName: 'El Salvador',
    alpha2: 'SV',
    alpha3: 'SLV',
  },
  {
    numericCode: 226,
    englishName: 'Equatorial Guinea',
    alpha2: 'GQ',
    alpha3: 'GNQ',
  },
  { numericCode: 232, englishName: 'Eritrea', alpha2: 'ER', alpha3: 'ERI' },
  { numericCode: 233, englishName: 'Estonia', alpha2: 'EE', alpha3: 'EST' },
  { numericCode: 231, englishName: 'Ethiopia', alpha2: 'ET', alpha3: 'ETH' },
  {
    numericCode: 238,
    englishName: 'Falkland Islands (Malvinas)',
    alpha2: 'FK',
    alpha3: 'FLK',
  },
  {
    numericCode: 234,
    englishName: 'Faroe Islands',
    alpha2: 'FO',
    alpha3: 'FRO',
  },
  { numericCode: 242, englishName: 'Fiji', alpha2: 'FJ', alpha3: 'FJI' },
  { numericCode: 246, englishName: 'Finland', alpha2: 'FI', alpha3: 'FIN' },
  { numericCode: 250, englishName: 'France', alpha2: 'FR', alpha3: 'FRA' },
  {
    numericCode: 254,
    englishName: 'French Guiana',
    alpha2: 'GF',
    alpha3: 'GUF',
  },
  {
    numericCode: 258,
    englishName: 'French Polynesia',
    alpha2: 'PF',
    alpha3: 'PYF',
  },
  {
    numericCode: 260,
    englishName: 'French Southern Territories',
    alpha2: 'TF',
    alpha3: 'ATF',
  },
  { numericCode: 266, englishName: 'Gabon', alpha2: 'GA', alpha3: 'GAB' },
  { numericCode: 270, englishName: 'Gambia', alpha2: 'GM', alpha3: 'GMB' },
  { numericCode: 268, englishName: 'Georgia', alpha2: 'GE', alpha3: 'GEO' },
  { numericCode: 276, englishName: 'Germany', alpha2: 'DE', alpha3: 'DEU' },
  { numericCode: 288, englishName: 'Ghana', alpha2: 'GH', alpha3: 'GHA' },
  { numericCode: 292, englishName: 'Gibraltar', alpha2: 'GI', alpha3: 'GIB' },
  { numericCode: 300, englishName: 'Greece', alpha2: 'GR', alpha3: 'GRC' },
  { numericCode: 304, englishName: 'Greenland', alpha2: 'GL', alpha3: 'GRL' },
  { numericCode: 308, englishName: 'Grenada', alpha2: 'GD', alpha3: 'GRD' },
  { numericCode: 312, englishName: 'Guadeloupe', alpha2: 'GP', alpha3: 'GLP' },
  { numericCode: 316, englishName: 'Guam', alpha2: 'GU', alpha3: 'GUM' },
  { numericCode: 320, englishName: 'Guatemala', alpha2: 'GT', alpha3: 'GTM' },
  { numericCode: 831, englishName: 'Guernsey', alpha2: 'GG', alpha3: 'GGY' },
  { numericCode: 324, englishName: 'Guinea', alpha2: 'GN', alpha3: 'GIN' },
  {
    numericCode: 624,
    englishName: 'Guinea-Bissau',
    alpha2: 'GW',
    alpha3: 'GNB',
  },
  { numericCode: 328, englishName: 'Guyana', alpha2: 'GY', alpha3: 'GUY' },
  { numericCode: 332, englishName: 'Haiti', alpha2: 'HT', alpha3: 'HTI' },
  {
    numericCode: 334,
    englishName: 'Heard Island and McDonald Islands',
    alpha2: 'HM',
    alpha3: 'HMD',
  },
  { numericCode: 336, englishName: 'Holy See', alpha2: 'VA', alpha3: 'VAT' },
  { numericCode: 340, englishName: 'Honduras', alpha2: 'HN', alpha3: 'HND' },
  { numericCode: 344, englishName: 'Hong Kong', alpha2: 'HK', alpha3: 'HKG' },
  { numericCode: 348, englishName: 'Hungary', alpha2: 'HU', alpha3: 'HUN' },
  { numericCode: 352, englishName: 'Iceland', alpha2: 'IS', alpha3: 'ISL' },
  { numericCode: 356, englishName: 'India', alpha2: 'IN', alpha3: 'IND' },
  { numericCode: 360, englishName: 'Indonesia', alpha2: 'ID', alpha3: 'IDN' },
  {
    numericCode: 364,
    englishName: 'Iran (Islamic Republic of)',
    alpha2: 'IR',
    alpha3: 'IRN',
  },
  { numericCode: 368, englishName: 'Iraq', alpha2: 'IQ', alpha3: 'IRQ' },
  { numericCode: 372, englishName: 'Ireland', alpha2: 'IE', alpha3: 'IRL' },
  {
    numericCode: 833,
    englishName: 'Isle of Man',
    alpha2: 'IM',
    alpha3: 'IMN',
  },
  { numericCode: 376, englishName: 'Israel', alpha2: 'IL', alpha3: 'ISR' },
  { numericCode: 380, englishName: 'Italy', alpha2: 'IT', alpha3: 'ITA' },
  { numericCode: 388, englishName: 'Jamaica', alpha2: 'JM', alpha3: 'JAM' },
  { numericCode: 392, englishName: 'Japan', alpha2: 'JP', alpha3: 'JPN' },
  { numericCode: 832, englishName: 'Jersey', alpha2: 'JE', alpha3: 'JEY' },
  { numericCode: 400, englishName: 'Jordan', alpha2: 'JO', alpha3: 'JOR' },
  { numericCode: 398, englishName: 'Kazakhstan', alpha2: 'KZ', alpha3: 'KAZ' },
  { numericCode: 404, englishName: 'Kenya', alpha2: 'KE', alpha3: 'KEN' },
  { numericCode: 296, englishName: 'Kiribati', alpha2: 'KI', alpha3: 'KIR' },
  {
    numericCode: 408,
    englishName: "Korea (Democratic People's Republic of)",
    alpha2: 'KP',
    alpha3: 'PRK',
  },
  {
    numericCode: 410,
    englishName: 'Korea (Republic of)',
    alpha2: 'KR',
    alpha3: 'KOR',
  },
  { numericCode: 414, englishName: 'Kuwait', alpha2: 'KW', alpha3: 'KWT' },
  { numericCode: 417, englishName: 'Kyrgyzstan', alpha2: 'KG', alpha3: 'KGZ' },
  {
    numericCode: 418,
    englishName: "Lao People's Democratic Republic",
    alpha2: 'LA',
    alpha3: 'LAO',
  },
  { numericCode: 428, englishName: 'Latvia', alpha2: 'LV', alpha3: 'LVA' },
  { numericCode: 422, englishName: 'Lebanon', alpha2: 'LB', alpha3: 'LBN' },
  { numericCode: 426, englishName: 'Lesotho', alpha2: 'LS', alpha3: 'LSO' },
  { numericCode: 430, englishName: 'Liberia', alpha2: 'LR', alpha3: 'LBR' },
  { numericCode: 434, englishName: 'Libya', alpha2: 'LY', alpha3: 'LBY' },
  {
    numericCode: 438,
    englishName: 'Liechtenstein',
    alpha2: 'LI',
    alpha3: 'LIE',
  },
  { numericCode: 440, englishName: 'Lithuania', alpha2: 'LT', alpha3: 'LTU' },
  { numericCode: 442, englishName: 'Luxembourg', alpha2: 'LU', alpha3: 'LUX' },
  { numericCode: 446, englishName: 'Macao', alpha2: 'MO', alpha3: 'MAC' },
  {
    numericCode: 807,
    englishName: 'Macedonia (the former Yugoslav Republic of)',
    alpha2: 'MK',
    alpha3: 'MKD',
  },
  { numericCode: 450, englishName: 'Madagascar', alpha2: 'MG', alpha3: 'MDG' },
  { numericCode: 454, englishName: 'Malawi', alpha2: 'MW', alpha3: 'MWI' },
  { numericCode: 458, englishName: 'Malaysia', alpha2: 'MY', alpha3: 'MYS' },
  { numericCode: 462, englishName: 'Maldives', alpha2: 'MV', alpha3: 'MDV' },
  { numericCode: 466, englishName: 'Mali', alpha2: 'ML', alpha3: 'MLI' },
  { numericCode: 470, englishName: 'Malta', alpha2: 'MT', alpha3: 'MLT' },
  {
    numericCode: 584,
    englishName: 'Marshall Islands',
    alpha2: 'MH',
    alpha3: 'MHL',
  },
  { numericCode: 474, englishName: 'Martinique', alpha2: 'MQ', alpha3: 'MTQ' },
  { numericCode: 478, englishName: 'Mauritania', alpha2: 'MR', alpha3: 'MRT' },
  { numericCode: 480, englishName: 'Mauritius', alpha2: 'MU', alpha3: 'MUS' },
  { numericCode: 175, englishName: 'Mayotte', alpha2: 'YT', alpha3: 'MYT' },
  { numericCode: 484, englishName: 'Mexico', alpha2: 'MX', alpha3: 'MEX' },
  {
    numericCode: 583,
    englishName: 'Micronesia (Federated States of)',
    alpha2: 'FM',
    alpha3: 'FSM',
  },
  {
    numericCode: 498,
    englishName: 'Moldova (Republic of)',
    alpha2: 'MD',
    alpha3: 'MDA',
  },
  { numericCode: 492, englishName: 'Monaco', alpha2: 'MC', alpha3: 'MCO' },
  { numericCode: 496, englishName: 'Mongolia', alpha2: 'MN', alpha3: 'MNG' },
  { numericCode: 499, englishName: 'Montenegro', alpha2: 'ME', alpha3: 'MNE' },
  { numericCode: 500, englishName: 'Montserrat', alpha2: 'MS', alpha3: 'MSR' },
  { numericCode: 504, englishName: 'Morocco', alpha2: 'MA', alpha3: 'MAR' },
  { numericCode: 508, englishName: 'Mozambique', alpha2: 'MZ', alpha3: 'MOZ' },
  { numericCode: 104, englishName: 'Myanmar', alpha2: 'MM', alpha3: 'MMR' },
  { numericCode: 516, englishName: 'Namibia', alpha2: 'NA', alpha3: 'NAM' },
  { numericCode: 520, englishName: 'Nauru', alpha2: 'NR', alpha3: 'NRU' },
  { numericCode: 524, englishName: 'Nepal', alpha2: 'NP', alpha3: 'NPL' },
  {
    numericCode: 528,
    englishName: 'Netherlands',
    alpha2: 'NL',
    alpha3: 'NLD',
  },
  {
    numericCode: 540,
    englishName: 'New Caledonia',
    alpha2: 'NC',
    alpha3: 'NCL',
  },
  {
    numericCode: 554,
    englishName: 'New Zealand',
    alpha2: 'NZ',
    alpha3: 'NZL',
  },
  { numericCode: 558, englishName: 'Nicaragua', alpha2: 'NI', alpha3: 'NIC' },
  { numericCode: 562, englishName: 'Niger', alpha2: 'NE', alpha3: 'NER' },
  { numericCode: 566, englishName: 'Nigeria', alpha2: 'NG', alpha3: 'NGA' },
  { numericCode: 570, englishName: 'Niue', alpha2: 'NU', alpha3: 'NIU' },
  {
    numericCode: 574,
    englishName: 'Norfolk Island',
    alpha2: 'NF',
    alpha3: 'NFK',
  },
  {
    numericCode: 580,
    englishName: 'Northern Mariana Islands',
    alpha2: 'MP',
    alpha3: 'MNP',
  },
  { numericCode: 578, englishName: 'Norway', alpha2: 'NO', alpha3: 'NOR' },
  { numericCode: 512, englishName: 'Oman', alpha2: 'OM', alpha3: 'OMN' },
  { numericCode: 586, englishName: 'Pakistan', alpha2: 'PK', alpha3: 'PAK' },
  { numericCode: 585, englishName: 'Palau', alpha2: 'PW', alpha3: 'PLW' },
  {
    numericCode: 275,
    englishName: 'Palestine, State of',
    alpha2: 'PS',
    alpha3: 'PSE',
  },
  { numericCode: 591, englishName: 'Panama', alpha2: 'PA', alpha3: 'PAN' },
  {
    numericCode: 598,
    englishName: 'Papua New Guinea',
    alpha2: 'PG',
    alpha3: 'PNG',
  },
  { numericCode: 600, englishName: 'Paraguay', alpha2: 'PY', alpha3: 'PRY' },
  { numericCode: 604, englishName: 'Peru', alpha2: 'PE', alpha3: 'PER' },
  {
    numericCode: 608,
    englishName: 'Philippines',
    alpha2: 'PH',
    alpha3: 'PHL',
  },
  { numericCode: 612, englishName: 'Pitcairn', alpha2: 'PN', alpha3: 'PCN' },
  { numericCode: 616, englishName: 'Poland', alpha2: 'PL', alpha3: 'POL' },
  { numericCode: 620, englishName: 'Portugal', alpha2: 'PT', alpha3: 'PRT' },
  {
    numericCode: 630,
    englishName: 'Puerto Rico',
    alpha2: 'PR',
    alpha3: 'PRI',
  },
  { numericCode: 634, englishName: 'Qatar', alpha2: 'QA', alpha3: 'QAT' },
  { numericCode: 638, englishName: 'Réunion', alpha2: 'RE', alpha3: 'REU' },
  { numericCode: 642, englishName: 'Romania', alpha2: 'RO', alpha3: 'ROU' },
  {
    numericCode: 643,
    englishName: 'Russian Federation',
    alpha2: 'RU',
    alpha3: 'RUS',
  },
  { numericCode: 646, englishName: 'Rwanda', alpha2: 'RW', alpha3: 'RWA' },
  {
    numericCode: 652,
    englishName: 'Saint Barthélemy',
    alpha2: 'BL',
    alpha3: 'BLM',
  },
  {
    numericCode: 654,
    englishName: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2: 'SH',
    alpha3: 'SHN',
  },
  {
    numericCode: 659,
    englishName: 'Saint Kitts and Nevis',
    alpha2: 'KN',
    alpha3: 'KNA',
  },
  {
    numericCode: 662,
    englishName: 'Saint Lucia',
    alpha2: 'LC',
    alpha3: 'LCA',
  },
  {
    numericCode: 663,
    englishName: 'Saint Martin (French part)',
    alpha2: 'MF',
    alpha3: 'MAF',
  },
  {
    numericCode: 666,
    englishName: 'Saint Pierre and Miquelon',
    alpha2: 'PM',
    alpha3: 'SPM',
  },
  {
    numericCode: 670,
    englishName: 'Saint Vincent and the Grenadines',
    alpha2: 'VC',
    alpha3: 'VCT',
  },
  { numericCode: 882, englishName: 'Samoa', alpha2: 'WS', alpha3: 'WSM' },
  { numericCode: 674, englishName: 'San Marino', alpha2: 'SM', alpha3: 'SMR' },
  {
    numericCode: 678,
    englishName: 'Sao Tome and Principe',
    alpha2: 'ST',
    alpha3: 'STP',
  },
  {
    numericCode: 682,
    englishName: 'Saudi Arabia',
    alpha2: 'SA',
    alpha3: 'SAU',
  },
  { numericCode: 686, englishName: 'Senegal', alpha2: 'SN', alpha3: 'SEN' },
  { numericCode: 688, englishName: 'Serbia', alpha2: 'RS', alpha3: 'SRB' },
  { numericCode: 690, englishName: 'Seychelles', alpha2: 'SC', alpha3: 'SYC' },
  {
    numericCode: 694,
    englishName: 'Sierra Leone',
    alpha2: 'SL',
    alpha3: 'SLE',
  },
  { numericCode: 702, englishName: 'Singapore', alpha2: 'SG', alpha3: 'SGP' },
  {
    numericCode: 534,
    englishName: 'Sint Maarten (Dutch part)',
    alpha2: 'SX',
    alpha3: 'SXM',
  },
  { numericCode: 703, englishName: 'Slovakia', alpha2: 'SK', alpha3: 'SVK' },
  { numericCode: 705, englishName: 'Slovenia', alpha2: 'SI', alpha3: 'SVN' },
  {
    numericCode: 90,
    englishName: 'Solomon Islands',
    alpha2: 'SB',
    alpha3: 'SLB',
  },
  { numericCode: 706, englishName: 'Somalia', alpha2: 'SO', alpha3: 'SOM' },
  {
    numericCode: 710,
    englishName: 'South Africa',
    alpha2: 'ZA',
    alpha3: 'ZAF',
  },
  {
    numericCode: 239,
    englishName: 'South Georgia and the South Sandwich Islands',
    alpha2: 'GS',
    alpha3: 'SGS',
  },
  {
    numericCode: 728,
    englishName: 'South Sudan',
    alpha2: 'SS',
    alpha3: 'SSD',
  },
  { numericCode: 724, englishName: 'Spain', alpha2: 'ES', alpha3: 'ESP' },
  { numericCode: 144, englishName: 'Sri Lanka', alpha2: 'LK', alpha3: 'LKA' },
  { numericCode: 729, englishName: 'Sudan', alpha2: 'SD', alpha3: 'SDN' },
  { numericCode: 740, englishName: 'Suriname', alpha2: 'SR', alpha3: 'SUR' },
  {
    numericCode: 744,
    englishName: 'Svalbard and Jan Mayen',
    alpha2: 'SJ',
    alpha3: 'SJM',
  },
  { numericCode: 748, englishName: 'Swaziland', alpha2: 'SZ', alpha3: 'SWZ' },
  { numericCode: 752, englishName: 'Sweden', alpha2: 'SE', alpha3: 'SWE' },
  {
    numericCode: 756,
    englishName: 'Switzerland',
    alpha2: 'CH',
    alpha3: 'CHE',
  },
  {
    numericCode: 760,
    englishName: 'Syrian Arab Republic',
    alpha2: 'SY',
    alpha3: 'SYR',
  },
  {
    numericCode: 158,
    englishName: 'Taiwan, Province of China',
    alpha2: 'TW',
    alpha3: 'TWN',
  },
  { numericCode: 762, englishName: 'Tajikistan', alpha2: 'TJ', alpha3: 'TJK' },
  {
    numericCode: 834,
    englishName: 'Tanzania, United Republic of',
    alpha2: 'TZ',
    alpha3: 'TZA',
  },
  { numericCode: 764, englishName: 'Thailand', alpha2: 'TH', alpha3: 'THA' },
  {
    numericCode: 626,
    englishName: 'Timor-Leste',
    alpha2: 'TL',
    alpha3: 'TLS',
  },
  { numericCode: 768, englishName: 'Togo', alpha2: 'TG', alpha3: 'TGO' },
  { numericCode: 772, englishName: 'Tokelau', alpha2: 'TK', alpha3: 'TKL' },
  { numericCode: 776, englishName: 'Tonga', alpha2: 'TO', alpha3: 'TON' },
  {
    numericCode: 780,
    englishName: 'Trinidad and Tobago',
    alpha2: 'TT',
    alpha3: 'TTO',
  },
  { numericCode: 788, englishName: 'Tunisia', alpha2: 'TN', alpha3: 'TUN' },
  { numericCode: 792, englishName: 'Turkey', alpha2: 'TR', alpha3: 'TUR' },
  {
    numericCode: 795,
    englishName: 'Turkmenistan',
    alpha2: 'TM',
    alpha3: 'TKM',
  },
  {
    numericCode: 796,
    englishName: 'Turks and Caicos Islands',
    alpha2: 'TC',
    alpha3: 'TCA',
  },
  { numericCode: 798, englishName: 'Tuvalu', alpha2: 'TV', alpha3: 'TUV' },
  { numericCode: 800, englishName: 'Uganda', alpha2: 'UG', alpha3: 'UGA' },
  { numericCode: 804, englishName: 'Ukraine', alpha2: 'UA', alpha3: 'UKR' },
  {
    numericCode: 784,
    englishName: 'United Arab Emirates',
    alpha2: 'AE',
    alpha3: 'ARE',
  },
  {
    numericCode: 826,
    englishName: 'United Kingdom of Great Britain and Northern Ireland',
    alpha2: 'GB',
    alpha3: 'GBR',
  },
  {
    numericCode: 840,
    englishName: 'United States of America',
    alpha2: 'US',
    alpha3: 'USA',
  },
  {
    numericCode: 581,
    englishName: 'United States Minor Outlying Islands',
    alpha2: 'UM',
    alpha3: 'UMI',
  },
  { numericCode: 858, englishName: 'Uruguay', alpha2: 'UY', alpha3: 'URY' },
  { numericCode: 860, englishName: 'Uzbekistan', alpha2: 'UZ', alpha3: 'UZB' },
  { numericCode: 548, englishName: 'Vanuatu', alpha2: 'VU', alpha3: 'VUT' },
  {
    numericCode: 862,
    englishName: 'Venezuela (Bolivarian Republic of)',
    alpha2: 'VE',
    alpha3: 'VEN',
  },
  { numericCode: 704, englishName: 'Viet Nam', alpha2: 'VN', alpha3: 'VNM' },
  {
    numericCode: 92,
    englishName: 'Virgin Islands (British)',
    alpha2: 'VG',
    alpha3: 'VGB',
  },
  {
    numericCode: 850,
    englishName: 'Virgin Islands (U.S.)',
    alpha2: 'VI',
    alpha3: 'VIR',
  },
  {
    numericCode: 876,
    englishName: 'Wallis and Futuna',
    alpha2: 'WF',
    alpha3: 'WLF',
  },
  {
    numericCode: 732,
    englishName: 'Western Sahara',
    alpha2: 'EH',
    alpha3: 'ESH',
  },
  { numericCode: 887, englishName: 'Yemen', alpha2: 'YE', alpha3: 'YEM' },
  { numericCode: 894, englishName: 'Zambia', alpha2: 'ZM', alpha3: 'ZMB' },
  { numericCode: 716, englishName: 'Zimbabwe', alpha2: 'ZW', alpha3: 'ZWE' },
  { numericCode: 383, englishName: 'Kosovo', alpha2: 'XK', alpha3: 'XKX' },
];

/**
 * Returns the Country object that matches the given two-character alpha-2 code.
 */
export function getCountryByAlpha2(alpha2: string): Country | undefined {
  return countryList.find(c => c.alpha2.toUpperCase() === alpha2.toUpperCase());
}

/**
 * Returns the Country object that matches the given three-character alpha-3 code.
 */
export function getCountryByAlpha3(alpha3: string): Country | undefined {
  return countryList.find(c => c.alpha3.toUpperCase() === alpha3.toUpperCase());
}

/**
 * Returns the Country object that matches the given numeric country code.
 */
export function getCountryByNumeric(numericCode: number): Country | undefined {
  return countryList.find(c => c.numericCode === numericCode);
}

/**
 * Returns the Country object that matches the given English name (case-insensitive).
 */
export function getCountryByName(name: string): Country | undefined {
  return countryList.find(
    c => c.englishName.toLowerCase() === name.toLowerCase()
  );
}
