import React from 'react';
import { CollapsibleSection } from '../../CollapsibleSection/CollapsibleSection';
import { TBookEvaluation } from '../../../api/generated/types/common';
import {
  TBookEvalCharacter,
  TBookEvalOverall,
  TBookEvalPlot,
  TBookEvalPromise,
  TBookEvalQuality,
} from '../../../api/generated/types/common';
import { InfoSection } from '../../InfoSection/InfoSection';
import styles from '../styles.module.css';
import EvaluationSection from '../../EvaluationSection/EvaluationSection';
import Button from 'components/design-system/Button/Button';

interface EvaluationProps {
  evaluation: TBookEvaluation | null;
  isPending: boolean;
  isEvaluating: boolean;
  isOpen: boolean;
  onToggle: (sectionName: string) => void;
  onStartEvaluation: () => void;
  showVerdict: boolean;
}

export const Evaluation: React.FC<EvaluationProps> = ({
  evaluation,
  isPending,
  isEvaluating,
  isOpen,
  onToggle,
  onStartEvaluation,
  showVerdict,
}) => {
  const renderEvaluationSection = (
    sectionName: string,
    evaluation:
      | TBookEvalOverall
      | TBookEvalCharacter
      | TBookEvalPlot
      | TBookEvalPromise
      | TBookEvalQuality
      | null
  ) => {
    if (!evaluation) return null;
    const lowerCaseName = sectionName.toLowerCase();

    return (
      <EvaluationSection
        sectionName={sectionName}
        evaluation={evaluation}
        isOpen={isOpen}
        onToggle={() => onToggle(lowerCaseName)}
        showVerdict={showVerdict}
      />
    );
  };

  return (
    <CollapsibleSection
      title="Overall Evaluation"
      isOpen={isOpen}
      onToggle={() => onToggle('evaluation')}
    >
      <InfoSection sectionId="evaluation">
        <p>
          The Evaluation section provides a comprehensive analysis of your
          manuscript across multiple dimensions. We evaluate key aspects of your
          story including:
          <ul>
            <li>Plot structure and pacing</li>
            <li>Character development and arcs</li>
            <li>Writing quality and technical execution</li>
            <li>Story promise and reader engagement</li>
          </ul>
          Each aspect receives a detailed analysis with specific examples and
          suggestions for improvement. Use these insights to identify areas of
          strength and opportunities for enhancement in your manuscript.
        </p>
      </InfoSection>
      {evaluation ? (
        <div className={styles.evaluationSections}>
          {renderEvaluationSection('Overall', evaluation.overall)}
          {renderEvaluationSection('Character', evaluation.character)}
          {renderEvaluationSection('Plot', evaluation.plot)}
          {renderEvaluationSection('Promise', evaluation.promise)}
          {renderEvaluationSection('Quality', evaluation.quality)}
        </div>
      ) : isPending ? (
        <p className={styles.pendingEvaluation}>
          Your insights will be ready in a few minutes, hang tight and we'll
          refresh when they are ready.
        </p>
      ) : (
        <div className={styles.noAnalysis}>
          <p>No evaluation available.</p>
          <Button
            onClick={onStartEvaluation}
            disabled={isEvaluating}
            variant="primary"
            size="medium"
          >
            {isEvaluating ? 'Evaluating...' : 'Start Evaluation'}
          </Button>
        </div>
      )}
    </CollapsibleSection>
  );
};
