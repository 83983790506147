import React, { useState } from 'react';
import { post } from '../../api/apiUtils';
import styles from './Feedback.module.css';

interface FeedbackFormProps {
  subtitle?: string;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({
  subtitle = 'Have a problem you wish someone would solve? Let us know!',
}) => {
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'success' | 'error' | ''>();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!feedback.trim()) return;

    setIsSubmitting(true);
    try {
      await post('/feedback', { message: feedback });
      setFeedback('');
      setSubmitStatus('success');
    } catch (error) {
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.container}>
      {subtitle && <p>{subtitle}</p>}
      <form onSubmit={handleSubmit} className={styles.form}>
        <div>
          <label htmlFor="feedback" className={styles.label}>
            Share your feedback or feature requests
          </label>
          <textarea
            id="feedback"
            value={feedback}
            onChange={e => setFeedback(e.target.value)}
            className={styles.textarea}
            placeholder="Tell us what you think or what features you'd like to see..."
          />
        </div>
        <button
          type="submit"
          disabled={isSubmitting || !feedback.trim()}
          className={styles.button}
        >
          Submit Feedback
        </button>
        {submitStatus === 'success' && (
          <p className={styles.success}>Thank you for your feedback!</p>
        )}
        {submitStatus === 'error' && (
          <p className={styles.error}>
            Failed to submit feedback. Please try again.
          </p>
        )}
      </form>
    </div>
  );
};

export default FeedbackForm;
