// src/components/FileUploader/index.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { post } from '../../api/apiUtils';
import { useSelector } from 'react-redux';
import styles from './FileUploader.module.css';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { RootState } from '../../store/reducers/rootReducer';
import {
  TConfirmUploadResponse,
  TAPIBook,
  TGetPresignedUrlParams,
  TApiFilePurpose,
} from 'api/generated/types/common';

export type FilePurpose = 'bookEval' | 'royaltyDocument';

interface FileUploaderProps {
  onFileUpload: (response: TConfirmUploadResponse) => void;
  onUploadError: (error: string) => void;
  existingFiles: TAPIBook[];
  allowedFileTypes: string[];
  filePurpose: FilePurpose;
}

export const FileUploader: React.FC<FileUploaderProps> = ({
  onFileUpload,
  onUploadError,
  existingFiles,
  allowedFileTypes,
  filePurpose,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [title, setTitle] = useState('');
  const [selectedExistingFile, setSelectedExistingFile] = useState<
    string | null
  >(null);
  const [userEditedTitle, setUserEditedTitle] = useState(false);
  const user = useSelector((state: RootState) => state.userAuth.user);
  const orgId = user?.organizationId!;
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    if (selectedFile && !userEditedTitle) {
      setTitle(selectedFile.name.split('.').slice(0, -1).join('.'));
    }
  }, [selectedFile, userEditedTitle]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (
        allowedFileTypes.some(ext =>
          file.name.toLowerCase().endsWith(ext.toLowerCase())
        )
      ) {
        setSelectedFile(file);
        setUserEditedTitle(false);
      } else {
        onUploadError(
          `Please select a file with one of the following extensions: ${allowedFileTypes.join(' ')}`
        );
        event.target.value = ''; // Reset the input
      }
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
    setUserEditedTitle(true);
  };

  const handleExistingFileChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedExistingFile(event.target.value);
    if (event.target.value !== 'new') {
      const existingFile = existingFiles.find(
        file => file.id === event.target.value
      );
      if (existingFile && !userEditedTitle) {
        setTitle(existingFile.title);
      }
    }
  };

  const getFileExtension = (filename: string) => {
    const lastDotIndex = filename.lastIndexOf('.');
    return lastDotIndex === -1 ? '' : filename.slice(lastDotIndex);
  };

  const handleUpload = async () => {
    if (!selectedFile || !title) return;

    const extension = getFileExtension(selectedFile.name);

    setUploading(true);

    try {
      const apiFilePurpose: TApiFilePurpose = (() => {
        switch (filePurpose) {
          case 'royaltyDocument':
            return { tag: 'apiRoyaltyDocument' };
          case 'bookEval':
            return {
              tag: 'apiBookEval',
              contents: [title, selectedExistingFile ?? null],
            };
          default:
            throw new Error(`Invalid file purpose: ${filePurpose}`);
        }
      })();
      const presignedUploadPayload: TGetPresignedUrlParams = (() => {
        return {
          fileName: selectedFile.name,
          title: title,
          extension: extension,
          filePurpose: apiFilePurpose,
        };
      })();
      const { fileUrl, fileId } = await post<{
        fileUrl: string;
        fileId: string;
      }>(`/files/${orgId}/get-presigned-url`, presignedUploadPayload);

      await axios.put(fileUrl, selectedFile, {
        headers: { 'Content-Type': selectedFile.type },
      });

      const uploadedFileResponse = await post<TConfirmUploadResponse>(
        `/files/${fileId}/confirm-upload`,
        { filePurpose: apiFilePurpose }
      );

      onFileUpload(uploadedFileResponse);
      setSelectedFile(null);
      setTitle('');
      setSelectedExistingFile(null);
      setUserEditedTitle(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      setFailed(true);
      onUploadError('File upload failed. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className={styles.uploaderContainer}>
      <p className={styles.instructions}>
        Please select a file to upload. Max file size: 10MB
      </p>
      <input
        type="file"
        accept={allowedFileTypes.join(',')}
        onChange={handleFileChange}
        disabled={uploading}
        className={styles.fileInput}
        id="fileInput"
      />
      <label htmlFor="fileInput" className={styles.fileInputLabel}>
        {uploading ? 'Uploading...' : 'Choose File'}
      </label>
      {selectedFile && (
        <p className={styles.selectedFileName}>Selected: {selectedFile.name}</p>
      )}
      {filePurpose === 'bookEval' && (
        <>
          <div className={styles.inputGroup}>
            <label htmlFor="titleInput" className={styles.inputLabel}>
              Title:
            </label>
            <input
              id="titleInput"
              type="text"
              value={title}
              onChange={handleTitleChange}
              placeholder="Enter document title"
              className={styles.titleInput}
              disabled={uploading}
            />
            <p className={styles.inputDescription}>
              Provide a title for your document. This will be used for display
              purposes.
            </p>
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="versionSelect" className={styles.inputLabel}>
              Version:
            </label>
            <select
              id="versionSelect"
              value={selectedExistingFile || 'new'}
              onChange={handleExistingFileChange}
              className={styles.selectExisting}
              disabled={uploading}
            >
              <option value="new">New Upload</option>
              {existingFiles.map(file => (
                <option key={file.id} value={file.id}>
                  {file.title}
                </option>
              ))}
            </select>
            <p className={styles.inputDescription}>
              Select 'New Upload' for a new document or choose an existing
              document to upload a new version.
            </p>
          </div>
        </>
      )}
      <div>
        <button
          onClick={handleUpload}
          disabled={!selectedFile || !title || uploading}
          className={styles.uploadButton}
        >
          {uploading ? 'Uploading...' : 'Upload File'}
        </button>
      </div>
      {failed && !uploading && (
        <span className={styles.errorBox}>
          <ErrorMessage message={'File upload failed'} />
        </span>
      )}
    </div>
  );
};
