import {
  TDistributionLanguage,
  TDistributionLocation,
  TDistributionRegion,
} from 'api/generated/types/common';
import { Country, getCountryByName } from 'types/country';

export const distributionLocationToString = (
  location: TDistributionLocation
): string => {
  switch (location.tag) {
    case 'worldwide':
      return 'Worldwide';
    case 'distributionCountry':
      return location.contents;
    case 'distributionRegion':
      return distributionRegionToString(location.contents);
  }
};

export const stringToDistributionLocation = (
  str: string
): TDistributionLocation | null => {
  if (str === 'Worldwide') {
    return { tag: 'worldwide' };
  } else {
    const region = stringToDistributionRegion(str);
    if (region != null) {
      return { tag: 'distributionRegion', contents: region };
    }

    // Convert country names to ISO 3166 Alpha-2 codes
    const country: Country | undefined = getCountryByName(str);
    if (country) {
      return { tag: 'distributionCountry', contents: country.englishName };
    }

    // If we can't determine a valid country code, return null
    console.warn(`Could not convert "${str}" to a valid country code`);
    return null;
  }
};

export const distributionLanguageToString = (
  language: TDistributionLanguage
): string => {
  switch (language) {
    case 'allLanguages':
      return 'All Languages';
    case 'english':
      return 'English';
    case 'spanish':
      return 'Spanish';
    case 'german':
      return 'German';
    case 'japanese':
      return 'Japanese';
    default:
      return language;
  }
};

export const stringToDistributionLanguage = (
  str: string
): TDistributionLanguage | null => {
  switch (str.toLowerCase()) {
    case 'all languages':
      return 'allLanguages';
    case 'english':
      return 'english';
    case 'spanish':
      return 'spanish';
    case 'german':
      return 'german';
    case 'japanese':
      return 'japanese';
    default:
      return null;
  }
};

export const stringToDistributionRegion = (
  str: string
): TDistributionRegion | null => {
  switch (str) {
    case 'Americas':
      return 'americas';
    case 'Europe':
      return 'europe';
    case 'Asia':
      return 'asia';
    default:
      return null;
  }
};

export const distributionRegionToString = (
  region: TDistributionRegion
): string => {
  switch (region) {
    case 'americas':
      return 'Americas';
    case 'europe':
      return 'Europe';
    case 'asia':
      return 'Asia';
  }
};
