// src/pages/UserManagement.tsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../../store/slices/userManagement';
import AdminDashboardLayout from '../../components/AdminDashboardLayout/AdminDashboardLayout';
import styles from './UserManagement.module.css';
import { AppDispatch } from '../../store';
import { post } from '../../api/apiUtils';
import { RootState } from '../../store/reducers/rootReducer';
import { refreshStore } from '../../store/slices/globalActionsSlice';
import { useNavigate } from 'react-router-dom';
import { checkSession } from '../../store/slices/userAuthSlice';

const UserManagement: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { users, totalUsers, loading, error } = useSelector(
    (state: RootState) => state.userManagement
  );
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchUsers({ page: currentPage, limit: usersPerPage }));
  }, [dispatch, currentPage]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleProvideSupport = async (userId: number, userEmail: string) => {
    try {
      const response = await post('/admin/provide-support', {
        userIdToProvideSupport: userId,
      });
      console.log('Support provided:', response);

      // Set cookie in local storage
      localStorage.setItem(
        'supportSession',
        JSON.stringify({ userId, userEmail })
      );

      // Refresh the entire Redux store
      dispatch(refreshStore());
      // Recheck auth so redirect succeeds
      dispatch(checkSession());

      // Navigate to dashboard
      navigate('/manuscripts');
      // You might want to add some user feedback here, like a toast notification
    } catch (error) {
      console.error('Error providing support:', error);
      // Handle the error, maybe show an error message to the user
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <AdminDashboardLayout>
      <h1>User Management</h1>
      <table className={styles.userTable}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>
                <button onClick={() => console.log('View', user.id)}>
                  View
                </button>
                <button
                  onClick={() => handleProvideSupport(user.id, user.email)}
                >
                  Support
                </button>
                <button onClick={() => console.log('Edit', user.id)}>
                  Edit
                </button>
                <button onClick={() => console.log('Delete', user.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {Math.ceil(totalUsers / usersPerPage)}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === Math.ceil(totalUsers / usersPerPage)}
        >
          Next
        </button>
      </div>
    </AdminDashboardLayout>
  );
};

export default UserManagement;
