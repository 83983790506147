// InfoTooltip.tsx
import React, { useState, useEffect, useRef } from 'react';
import styles from './InfoTooltip.module.css';

type Symbol =
  | 'dagger'
  | 'doubledagger'
  | 'section'
  | 'pilcrow'
  | 'asterisk'
  | 'reference';

interface InfoTooltipProps {
  text: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
  number?: number;
  symbol?: Symbol;
}

const symbolMap: Record<Symbol, string> = {
  dagger: '†', // dagger
  doubledagger: '‡', // double dagger
  section: '§', // section
  pilcrow: '¶', // pilcrow
  asterisk: '*', // asterisk
  reference: '※', // reference mark
};

const InfoTooltip = ({
  text,
  position = 'top',
  number,
  symbol = 'dagger',
}: InfoTooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [adjustedPosition, setAdjustedPosition] = useState(position);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const getDisplaySymbol = () => {
    if (number !== undefined) return number;
    return symbolMap[symbol];
  };

  useEffect(() => {
    if (isVisible && tooltipRef.current && containerRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;

      // Reset to original position before calculating
      setAdjustedPosition(position);

      // Check for overflow and flip if needed
      setTimeout(() => {
        if (
          position === 'right' &&
          containerRect.right + tooltipRect.width > windowWidth - 20
        ) {
          setAdjustedPosition('left');
        } else if (
          position === 'left' &&
          containerRect.left - tooltipRect.width < 20
        ) {
          setAdjustedPosition('right');
        } else if (
          position === 'top' &&
          containerRect.top - tooltipRect.height < 20
        ) {
          setAdjustedPosition('bottom');
        } else if (
          position === 'bottom' &&
          containerRect.bottom + tooltipRect.height > window.innerHeight - 20
        ) {
          setAdjustedPosition('top');
        }
      }, 0);
    }
  }, [isVisible, position]);

  return (
    <div className={styles.container} ref={containerRef}>
      <button
        className={styles.button}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onFocus={() => setIsVisible(true)}
        onBlur={() => setIsVisible(false)}
        aria-label={`Info: ${text}`}
      >
        <span className={styles.reference}>{getDisplaySymbol()}</span>
      </button>

      {isVisible && (
        <div
          ref={tooltipRef}
          className={`${styles.tooltip} ${styles[adjustedPosition]}`}
          role="tooltip"
        >
          <span className={styles.tooltipSymbol}>{getDisplaySymbol()}</span>{' '}
          {text}
          <div
            className={`${styles.arrow} ${styles[`arrow-${adjustedPosition}`]}`}
          />
        </div>
      )}
    </div>
  );
};

export default InfoTooltip;
