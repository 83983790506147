import React, { useState, useMemo, useEffect } from 'react';
import { FileUploader } from 'components/FileUploader';
import {
  TConfirmUploadResponse,
  TUploadRoyaltiesResponse,
  TBookFormat,
} from 'api/generated/types/common';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import styles from './RoyaltyUpload.module.css';
import Button from 'components/design-system/Button/Button';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import { marketplaceToString } from 'types/royalties/MarketplaceUtils';

// Helper to generate a unique id for each aggregated row.
const generateId = (): string =>
  `${Date.now()}-${Math.random().toString(36).substring(2, 7)}`;

// Define an interface for an aggregated row.
interface AggregatedRow {
  id: string;
  title: string;
  bookId: string;
  marketplace: string[];
  isbn: string[];
  bookFormat: TBookFormat;
  units: number;
  royalties: number;
}

const RoyaltyUpload: React.FC = () => {
  const navigate = useNavigate();
  const [monthlyRoyalties, setMonthlyRoyalties] =
    useState<TUploadRoyaltiesResponse | null>(null);
  const [success, setSuccess] = useState<{ success: boolean | undefined }>({
    success: undefined,
  });

  // State for the aggregated rows that are displayed.
  const [displayRows, setDisplayRows] = useState<AggregatedRow[]>([]);

  const breadcrumbItems = [
    { label: 'Royalties Summary', path: '/royalties' },
    { label: 'Upload Royalties' },
  ];

  // Handle file input changes.
  const handleFileUpload = (response: TConfirmUploadResponse) => {
    if (response.tag !== 'confirmUploadRoyalties') {
      throw new Error(
        'Unexpected response type. Expected confirmUploadRoyalties.'
      );
    }
    setMonthlyRoyalties(response.contents);
  };

  const handleUploadError = (error: string) => {
    console.error('Upload error:', error);
    setSuccess({ success: false });
  };

  const downloadTip =
    "Visit https://kdpreports.amazon.com/dashboard and go to Prior Months' Royalties in the sidebar to download this report.";

  // Compute the aggregated rows (grouped by title).
  const aggregatedRoyalties = useMemo<AggregatedRow[]>(() => {
    if (!monthlyRoyalties) return [];
    const aggregated = Object.values(
      monthlyRoyalties.royaltyConfigurations.reduce(
        (acc, royalty) => {
          if (acc[royalty.title]) {
            acc[royalty.title].units += royalty.units;
            acc[royalty.title].royalties += royalty.royalties;
            if (!acc[royalty.title].isbn.includes(royalty.isbn)) {
              acc[royalty.title].isbn.push(royalty.isbn);
            }
            if (
              !acc[royalty.title].marketplace.includes(
                marketplaceToString(royalty.marketplace)
              )
            ) {
              acc[royalty.title].marketplace.push(
                marketplaceToString(royalty.marketplace)
              );
            }
          } else {
            // Create an aggregated row; assign a unique id.
            acc[royalty.title] = {
              ...royalty,
              isbn: [royalty.isbn],
              marketplace: [marketplaceToString(royalty.marketplace)],
              id: generateId(),
              bookFormat: royalty.bookFormat,
              bookId: royalty.bookId,
            };
          }
          return acc;
        },
        {} as Record<
          string,
          Omit<
            (typeof monthlyRoyalties.royaltyConfigurations)[0],
            'isbn' | 'marketplace' | 'bookFormat' | 'bookId'
          > & {
            isbn: string[];
            marketplace: string[];
            id: string;
            bookFormat: TBookFormat;
            bookId: string;
          }
        >
      )
    );
    return aggregated;
  }, [monthlyRoyalties]);

  // Set the display rows on aggregatedRoyalties change.
  useEffect(() => {
    setDisplayRows(aggregatedRoyalties);
  }, [aggregatedRoyalties]);

  return (
    <div className={styles.container}>
      <Breadcrumb items={breadcrumbItems} />
      <h1>
        Upload your Prior Month KDP exports
        <InfoTooltip text={downloadTip} symbol="reference" />
      </h1>
      {monthlyRoyalties ? (
        <div className="royalty-data-display">
          <div className="table-container">
            <table className="royalty-table">
              <thead>
                <tr>
                  <th className={styles.tableHeader}>Title</th>
                  <th className={styles.tableHeader}>Country</th>
                  <th className={styles.tableHeader}>ISBN</th>
                  <th className={styles.tableHeader}>Format</th>
                  <th className={styles.tableHeader}>Units Sold</th>
                  <th className={styles.tableHeader}>Royalties</th>
                  <th className={styles.tableHeader}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {displayRows.map((row, idx) => (
                  <tr key={row.id}>
                    <td className={styles.tableCell}>{row.title}</td>
                    <td className={styles.tableCell}>
                      {row.marketplace.map((marketplace, midx) => (
                        <div key={midx}>{marketplace}</div>
                      ))}
                    </td>
                    <td className={styles.tableCell}>
                      {row.isbn.map((isbn, iidx) => (
                        <div key={iidx}>{isbn}</div>
                      ))}
                    </td>
                    <td className={styles.tableCell}>{row.bookFormat}</td>
                    <td className={styles.tableCell}>{row.units}</td>
                    <td className={styles.tableCell}>${row.royalties}</td>
                    <td>
                      <Button
                        className="calculate-button"
                        onClick={() =>
                          navigate(`/royalties/setup/${row.bookId}`)
                        }
                      >
                        Manage Contributors
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div>
          <FileUploader
            onFileUpload={handleFileUpload}
            onUploadError={handleUploadError}
            existingFiles={[]}
            allowedFileTypes={['.xlsx']}
            filePurpose="royaltyDocument"
          />
          {!success && <ErrorMessage message={'File upload failed'} />}
        </div>
      )}
    </div>
  );
};

export default RoyaltyUpload;
