// src/api/apiUtils.ts
import axiosInstance from './axiosConfig';
import { handleApiError } from './errorHandling';
import { logout } from '../store/slices/userAuthSlice';
import { AxiosError, AxiosRequestConfig } from 'axios';
import store from '../store';

// Extend AxiosRequestConfig to include our custom flag
export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  skipAuthInterceptor?: boolean;
}

// Request deduplication system
interface PendingRequest {
  timestamp: number;
  promise: Promise<any>;
}

const pendingRequests: Record<string, PendingRequest> = {};
const DEDUPLICATION_WINDOW_MS = 300; // Deduplicate requests within 300ms window

// Create a cache key from the request
const createCacheKey = (url: string, data: object): string => {
  return `${url}:${JSON.stringify(data)}`;
};

// Clean up old pending requests
const cleanupPendingRequests = (): void => {
  const now = Date.now();
  Object.keys(pendingRequests).forEach(key => {
    if (now - pendingRequests[key].timestamp > DEDUPLICATION_WINDOW_MS) {
      delete pendingRequests[key];
    }
  });
};

axiosInstance.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    // TODO: Also check the error message for "You are logged out." Text
    if (error.response?.status === 401) {
      const config = error.config as CustomAxiosRequestConfig;
      // Check if we should skip the auth interceptor for this request
      if (
        config &&
        !config.skipAuthInterceptor &&
        error.response?.status === 401
      ) {
        // If we receive a 401 Unauthorized, dispatch logout action
        store.dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

export const logoutApi = async (): Promise<void> => {
  try {
    await axiosInstance.post('/logout', {}, {
      skipAuthInterceptor: true,
    } as CustomAxiosRequestConfig);
    // The server should handle clearing the cookie
  } catch (error) {
    console.error('Logout failed:', error);
    throw error;
  }
};

export const get = async <T>(url: string, params?: object): Promise<T> => {
  try {
    // Clean up old pending requests
    cleanupPendingRequests();

    // Create a cache key for this request
    const cacheKey = createCacheKey(url, params || {});

    // Check if we have a pending request for this exact URL and params
    const pendingRequest = pendingRequests[cacheKey];
    if (pendingRequest) {
      // Return the existing promise to avoid duplicate requests
      return pendingRequest.promise;
    }

    // Create a new request
    const requestPromise = axiosInstance
      .get<T>(url, { params })
      .then(response => {
        // Remove from pending requests on success
        delete pendingRequests[cacheKey];
        return response.data;
      })
      .catch(error => {
        // Remove from pending requests on error
        delete pendingRequests[cacheKey];
        throw handleApiError(error);
      });

    // Store the pending request
    pendingRequests[cacheKey] = {
      timestamp: Date.now(),
      promise: requestPromise,
    };

    return requestPromise;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const post = async <T>(url: string, data: object): Promise<T> => {
  try {
    // Clean up old pending requests
    cleanupPendingRequests();

    // Create a cache key for this request
    const cacheKey = createCacheKey(url, data);

    // Check if we have a pending request for this exact URL and data
    const pendingRequest = pendingRequests[cacheKey];
    if (pendingRequest) {
      // Return the existing promise to avoid duplicate requests
      return pendingRequest.promise;
    }

    // Create a new request
    const requestPromise = axiosInstance
      .post<T>(url, data)
      .then(response => {
        // Remove from pending requests on success
        delete pendingRequests[cacheKey];
        return response.data;
      })
      .catch(error => {
        // Remove from pending requests on error
        delete pendingRequests[cacheKey];
        throw handleApiError(error);
      });

    // Store the pending request
    pendingRequests[cacheKey] = {
      timestamp: Date.now(),
      promise: requestPromise,
    };

    return requestPromise;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const put = async <T>(url: string, data: object): Promise<T> => {
  try {
    const response = await axiosInstance.put<T>(url, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const del = async <T>(url: string): Promise<T> => {
  try {
    const response = await axiosInstance.delete<T>(url);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
