import React, { useState, useEffect } from 'react';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import Button from 'components/design-system/Button/Button';
import styles from './PredefinedRoyalties.module.css';
import { post } from 'api/apiUtils';
import {
  TRoyaltyStructureRequest,
  TRoyaltyStructureKind,
  TBookFormat,
  TApiRoyaltyStructureRange,
  TApiRoyaltySplit,
  TDistributionRoyaltyRange,
  TApiContributorRoyaltyStructure,
  TBookRoyaltyStructure,
  TRoyaltyStructureResponse,
  TDistributionLocation,
  TDistributionLanguage,
} from 'api/generated/types/common';
import { serverEndpoints } from 'api/generated/endpoints';
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import { useParams, useNavigate } from 'react-router-dom';
import {
  distributionLocationToString,
  stringToDistributionLocation,
} from 'types/royalties/DistributionLocation';

// Options for the checkboxes
const allBookFormats = 'All Formats';
const allPrint = 'All Print';
const allDigital = 'All Digital';
const allAudio = 'All Audio';
const distributionOptions: string[] = [
  allBookFormats,
  allPrint,
  allDigital,
  allAudio,
  'eBook',
  'Print On Demand',
  'Hardcover Print On Demand',
  'Trade Paperback',
  'Hardcover',
  'Special Edition',
  'Mass Market Paperback',
  'Kindle Unlimited',
  'Audiobook',
  'Custom',
];

const bookFormatToApi = (format: string): TBookFormat => {
  switch (format) {
    case allBookFormats:
      return 'allFormats';
    case allPrint:
      return 'allPrint';
    case allDigital:
      return 'allDigital';
    case allAudio:
      return 'allAudio';
    case 'eBook':
      return 'ebook';
    case 'Print On Demand':
      return 'printOnDemand';
    case 'Hardcover Print On Demand':
      return 'printOnDemandHardcover';
    case 'Trade Paperback':
      return 'tradePaperback';
    case 'Hardcover':
      return 'hardcover';
    case 'Special Edition':
      return 'specialEdition';
    case 'Mass Market Paperback':
      return 'massMarketPaperback';
    case 'Kindle Unlimited':
      return 'kindleUnlimited';
    case 'Audiobook':
      return 'audiobook';
    default:
      throw new Error(`Unknown book format: ${format}`);
  }
};

const worldwide = 'Worldwide';
const countryOptions: string[] = [
  worldwide,
  'United States of America',
  'Canada',
  'Germany',
  'Japan',
  'Spain',
  'United Kingdom',
  'Poland',
  'Australia',
  'Italy',
];

interface TierDefinition {
  id: string;
  threshold: number; // The sales/revenue threshold.
  royaltyPercentage: string; // Royalty percentage to apply when the tier is reached.
}

interface RoyaltyDefinition {
  id: string;
  contributorId: string | null;
  contributor: string;
  locations: string[];
  bookFormats: string[];
  royaltyPercentage: string;
  tierConditionType?: 'units' | 'revenue'; // NEW: When tier mode is enabled, this replaces the flat royaltyPercentage input.
  isBranched?: boolean;
  parentContributorId?: string;
  tiers?: TierDefinition[]; // Optional tiered payout configuration.
}

// Helper to generate a unique id.
const generateId = (): string =>
  `${Date.now()}-${Math.random().toString(36).substring(2, 7)}`;

interface PredefinedRoyaltiesProps {
  isEdit: boolean;
}

const PredefinedRoyalties: React.FC<PredefinedRoyaltiesProps> = ({
  isEdit = false,
}) => {
  const { bookId = null } = useParams<{ bookId?: string }>();
  const navigate = useNavigate();

  const breadcrumbItems = [
    { label: 'Royalties Summary', path: '/royalties' },
    { label: isEdit ? 'Edit Royalties' : 'Predefined Royalties' },
  ];

  const [bookTitle, setBookTitle] = useState<string>('');
  const [definitions, setDefinitions] = useState<RoyaltyDefinition[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Load existing royalty structure data if in edit mode
  useEffect(() => {
    const loadExistingRoyaltyStructure = async (id: string) => {
      setLoading(true);
      try {
        const data = await post<TRoyaltyStructureResponse>(
          serverEndpoints.royaltiesListAllRoyaltyStructures(),
          {}
        );

        // Find the book with the matching ID
        const bookData = data.bookRoyaltyStructures.find(
          (book: TBookRoyaltyStructure) => book.bookId === id
        );

        if (bookData) {
          setBookTitle(bookData.bookTitle);

          // Convert the API data to our local format
          const newDefinitions: RoyaltyDefinition[] = [];

          bookData.contributorRoyaltyStructures.forEach(
            (contributor: TApiContributorRoyaltyStructure) => {
              contributor.distributionRoyaltyRanges.forEach(
                (distributionRange: TDistributionRoyaltyRange) => {
                  // Group distributions by country and format
                  const countries = new Set<string>();
                  const bookFormats = new Set<string>();

                  distributionRange.distributions.forEach(
                    (dist: TApiRoyaltySplit) => {
                      countries.add(
                        distributionLocationToString(dist.location)
                      );

                      // Convert API book format to UI format
                      let uiKind = '';
                      switch (dist.format) {
                        case 'allFormats':
                          uiKind = allBookFormats;
                          break;
                        case 'allPrint':
                          uiKind = allPrint;
                          break;
                        case 'allDigital':
                          uiKind = allDigital;
                          break;
                        case 'allAudio':
                          uiKind = allAudio;
                          break;
                        case 'ebook':
                          uiKind = 'eBook';
                          break;
                        case 'printOnDemand':
                          uiKind = 'Print On Demand';
                          break;
                        case 'printOnDemandHardcover':
                          uiKind = 'Hardcover Print On Demand';
                          break;
                        case 'tradePaperback':
                          uiKind = 'Trade Paperback';
                          break;
                        case 'hardcover':
                          uiKind = 'Hardcover';
                          break;
                        case 'specialEdition':
                          uiKind = 'Special Edition';
                          break;
                        case 'massMarketPaperback':
                          uiKind = 'Mass Market Paperback';
                          break;
                        case 'kindleUnlimited':
                          uiKind = 'Kindle Unlimited';
                          break;
                        case 'audiobook':
                          uiKind = 'Audiobook';
                          break;
                      }

                      bookFormats.add(uiKind);
                    }
                  );

                  const countryArray = Array.from(countries);
                  const kindArray = Array.from(bookFormats);

                  // Create the definition
                  const newDefinition: RoyaltyDefinition = {
                    id: generateId(),
                    contributorId: contributor.contributorId,
                    contributor: contributor.contributorName,
                    locations: countryArray,
                    bookFormats: kindArray,
                    royaltyPercentage: '',
                  };

                  // Handle tiers/ranges
                  if (distributionRange.ranges.length > 1) {
                    newDefinition.tiers = distributionRange.ranges.map(
                      (range: TApiRoyaltyStructureRange) => ({
                        id: generateId(),
                        threshold: range.start,
                        royaltyPercentage: range.percentage.toString(),
                      })
                    );

                    // Determine tier condition type based on the kind of royalty structure
                    // Since royaltyStructureKind might not be directly accessible, infer from other properties
                    // Default to revenue-based (dollar structure) unless we can determine it's units-based
                    let isUnitsBased = false;

                    // Try to infer from the structure or range properties if available
                    if (
                      distributionRange.ranges.length > 0 &&
                      distributionRange.ranges[0].start > 0
                    ) {
                      // If the first tier starts at a value > 0, it's likely units-based
                      isUnitsBased = true;
                    }

                    if (isUnitsBased) {
                      newDefinition.tierConditionType = 'units';
                    } else {
                      newDefinition.tierConditionType = 'revenue';
                    }
                  } else if (distributionRange.ranges.length === 1) {
                    // Single flat percentage
                    newDefinition.royaltyPercentage =
                      distributionRange.ranges[0].percentage.toString();
                  }

                  newDefinitions.push(newDefinition);
                }
              );
            }
          );

          setDefinitions(newDefinitions);
        } else {
          setError('No existing royalty structure found.');
        }
      } catch (error) {
        console.error('Error loading royalty structure:', error);
        setError('Error loading royalty structure. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    if (isEdit && bookId) {
      loadExistingRoyaltyStructure(bookId);
    }
  }, [isEdit, bookId]);

  // Show loading indicator while fetching data
  if (loading && isEdit) {
    return (
      <div className={styles.container}>
        <Breadcrumb items={breadcrumbItems} />
        <h1>Loading Royalty Data...</h1>
      </div>
    );
  }

  // Add a new primary contributor row (defaulting to "All" selections)
  const addDefinition = () => {
    const newDefinition: RoyaltyDefinition = {
      id: generateId(),
      contributorId: null,
      contributor: '',
      locations: [],
      bookFormats: [],
      royaltyPercentage: '',
    };
    setDefinitions([...definitions, newDefinition]);
  };

  // Add a new royalty structure for an existing contributor
  const addRoyaltyStructureForContributor = (
    contributorName: string,
    contributorId: string | null
  ) => {
    const newDefinition: RoyaltyDefinition = {
      id: generateId(),
      contributorId: contributorId,
      contributor: contributorName,
      locations: [],
      bookFormats: [],
      royaltyPercentage: '',
    };
    setDefinitions([...definitions, newDefinition]);
  };

  // Update text fields (like contributor, royaltyPercentage or tierConditionType) for a given row.
  // If the field is 'contributor', update all linked contributor rows with the same contributorId
  const updateDefinitionField = (
    id: string,
    field: keyof Omit<RoyaltyDefinition, 'countries' | 'bookFormats' | 'tiers'>,
    value: string
  ) => {
    setDefinitions(prev => {
      // First find the definition being updated
      const targetDef = prev.find(def => def.id === id);
      if (!targetDef) return prev;

      // If updating contributor name, update all linked rows
      if (field === 'contributor') {
        // Get the current contributor name and ID
        const oldContributorName = targetDef.contributor;
        const contributorId = targetDef.contributorId;

        return prev.map(def => {
          // Update the target row
          if (def.id === id) {
            return { ...def, [field]: value, contributorId: null };
          }

          // Update linked rows (same contributor name or ID)
          if (
            def.contributor === oldContributorName ||
            (contributorId && def.contributorId === contributorId)
          ) {
            return { ...def, contributor: value, contributorId: null };
          }

          return def;
        });
      } else {
        // For other fields, just update the specific row
        return prev.map(def => {
          if (def.id === id) return { ...def, [field]: value };
          return def;
        });
      }
    });
  };

  // Update country selections using checkboxes.
  const onCountryChange = (id: string, option: string, checked: boolean) => {
    setDefinitions(prev =>
      prev.map(def => {
        if (def.id !== id) return def;
        let newLocations = [...def.locations];
        if (option === worldwide) {
          newLocations = checked ? [worldwide] : [];
        } else {
          if (checked) {
            newLocations = newLocations.filter(opt => opt !== worldwide);
            if (!newLocations.includes(option)) {
              newLocations.push(option);
            }
          } else {
            newLocations = newLocations.filter(opt => opt !== option);
          }
        }
        return { ...def, locations: newLocations };
      })
    );
  };

  // Update distribution selections using checkboxes.
  const onDistributionChange = (
    id: string,
    option: string,
    checked: boolean
  ) => {
    setDefinitions(prev =>
      prev.map(def => {
        if (def.id !== id) return def;
        let newDistributions = [...def.bookFormats];
        if (option === allBookFormats) {
          newDistributions = checked ? [allBookFormats] : [];
        } else {
          if (checked) {
            newDistributions = newDistributions.filter(
              opt => opt !== allBookFormats
            );
            if (!newDistributions.includes(option)) {
              newDistributions.push(option);
            }
          } else {
            newDistributions = newDistributions.filter(opt => opt !== option);
          }
        }
        return { ...def, bookFormats: newDistributions };
      })
    );
  };

  const removeDefinition = (id: string) => {
    setDefinitions(prev => prev.filter(def => def.id !== id));
  };

  // Handle save implementation to construct the payload and call the API.
  const handleSave = async () => {
    // Validate inputs before saving
    if (!bookTitle.trim()) {
      setError('Please enter a book title');
      return;
    }

    if (definitions.length === 0) {
      setError('Please add at least one royalty definition');
      return;
    }

    // Check for invalid royalty percentages
    for (const def of definitions) {
      if (
        !def.tiers &&
        (!def.royaltyPercentage || isNaN(Number(def.royaltyPercentage)))
      ) {
        setError('Please enter valid royalty percentages for all definitions');
        return;
      }

      if (
        def.tiers &&
        def.tiers.some(
          tier =>
            !tier.royaltyPercentage || isNaN(Number(tier.royaltyPercentage))
        )
      ) {
        setError('Please enter valid royalty percentages for all tiers');
        return;
      }
    }

    setLoading(true);

    try {
      const royaltyRequests: TRoyaltyStructureRequest[] = definitions.map(
        def => {
          // Choose a royalty structure kind:
          // If tier mode is enabled, use the tier condition type to decide.
          const royaltyStructureKind: TRoyaltyStructureKind = def.tiers
            ? def.tierConditionType === 'units'
              ? 'royaltyUnitsSoldStructure'
              : 'royaltyDollarStructure'
            : 'royaltyDollarStructure';

          // Build ranges: if tiers exist, each tier becomes a range.
          // Otherwise, use the flat royaltyPercentage.
          const ranges = def.tiers
            ? def.tiers.map((tier, index, tiersArray) => ({
                start: tier.threshold,
                end:
                  index < tiersArray.length - 1
                    ? tiersArray[index + 1].threshold
                    : null,
                percentage: Number(tier.royaltyPercentage),
              }))
            : [
                {
                  start: 0,
                  end: null,
                  percentage: Number(def.royaltyPercentage),
                },
              ];

          // Create book distributions as a cross product of the selected countries and distribution kinds.
          const expandedLocations: TDistributionLocation[] =
            def.locations.flatMap(country => {
              const location = stringToDistributionLocation(country);
              return location ? [location] : [];
            });

          const expandedBookFormats = def.bookFormats;

          const royaltySplits = expandedLocations.flatMap(country =>
            expandedBookFormats.map(format => ({
              format: bookFormatToApi(format),
              location: country,
              language: 'allLanguages' as TDistributionLanguage,
            }))
          );

          return {
            contributorId: def.contributorId,
            contributorName: def.contributorId ? null : def.contributor,
            royaltyStructureKind,
            ranges,
            bookTitle,
            royaltySplits,
            bookId: bookId,
          };
        }
      );

      console.log('Sending royalty requests:', royaltyRequests);

      // If in edit mode and we have a bookId, we're updating existing structures
      // The backend will handle overwriting the existing structures
      await Promise.all(
        royaltyRequests.map(royaltyRequest =>
          post<TRoyaltyStructureResponse>(
            serverEndpoints.royaltiesSetupRoyaltyStructure(),
            royaltyRequest
          )
        )
      );

      // Show success message and navigate back to royalties summary on success
      setError(
        isEdit
          ? 'Royalty structure updated successfully!'
          : 'Royalty structure created successfully!'
      );
      navigate('/royalties');
    } catch (error) {
      console.error('Error saving royalty structures:', error);
      setError('Error saving royalty structures. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Helper: Check if the candidate option (in a given field) should be disabled.
  // This disables an option if:
  // (1) For an "All" candidate, if any other option has been selected in the same row.
  // (2) The specific combination (candidate + complementary selection) already exists in another row for the same contributor.
  const isComboUsedElsewhere = (
    currentDef: RoyaltyDefinition,
    candidate: string,
    field: 'countries' | 'bookFormats'
  ): boolean => {
    const otherRows = definitions.filter(
      (d: RoyaltyDefinition) =>
        d.id !== currentDef.id && d.contributor === currentDef.contributor
    );

    // Check if the combination already exists in another row for the same contributor.
    if (field === 'countries') {
      return otherRows.some((r: RoyaltyDefinition) =>
        r.bookFormats.some(
          (distribution: string) =>
            (r.locations.includes(candidate) ||
              r.locations.includes(worldwide)) &&
            (currentDef.bookFormats.includes(distribution) ||
              r.bookFormats.includes(allBookFormats))
        )
      );
    } else {
      return otherRows.some((r: RoyaltyDefinition) =>
        r.locations.some(
          (country: string) =>
            (r.bookFormats.includes(candidate) ||
              r.bookFormats.includes(allBookFormats)) &&
            (currentDef.locations.includes(country) ||
              r.locations.includes(worldwide))
        )
      );
    }
  };

  //
  // NEW: Functions to manage tiered payouts
  //

  // Add a new tier to a contributor, initializing with default values.
  const addTier = (definitionId: string) => {
    setDefinitions((prev: RoyaltyDefinition[]) =>
      prev.map((def: RoyaltyDefinition) => {
        if (def.id !== definitionId) return def;
        const tiers = def.tiers || [];
        return {
          ...def,
          tiers: [
            ...tiers,
            {
              id: generateId(),
              threshold: tiers.length === 0 ? 0 : 1000, // Default threshold
              royaltyPercentage: '10', // Default percentage
            },
          ],
        };
      })
    );
  };

  // Update a field on a specific tier.
  const updateTierField = (
    definitionId: string,
    tierId: string,
    field: keyof TierDefinition,
    value: any
  ) => {
    setDefinitions((prev: RoyaltyDefinition[]) =>
      prev.map((def: RoyaltyDefinition) => {
        if (def.id === definitionId && def.tiers) {
          const updatedTiers = def.tiers.map((tier: TierDefinition) =>
            tier.id === tierId ? { ...tier, [field]: value } : tier
          );
          return { ...def, tiers: updatedTiers };
        }
        return def;
      })
    );
  };

  // Remove a tier from a contributor.
  const removeTier = (definitionId: string, tierId: string) => {
    setDefinitions((prev: RoyaltyDefinition[]) =>
      prev.map((def: RoyaltyDefinition) => {
        if (def.id === definitionId && def.tiers) {
          return {
            ...def,
            tiers: def.tiers.filter(
              (tier: TierDefinition) => tier.id !== tierId
            ),
          };
        }
        return def;
      })
    );
  };

  // Toggle tier mode: if tiers are enabled, disable them; if not, initialize with an empty array.
  const toggleTierMode = (definitionId: string) => {
    setDefinitions((prev: RoyaltyDefinition[]) =>
      prev.map((def: RoyaltyDefinition) => {
        if (def.id === definitionId) {
          return def.tiers
            ? { ...def, tiers: undefined }
            : { ...def, tiers: [] };
        }
        return def;
      })
    );
  };

  return (
    <div className={styles.container}>
      <Breadcrumb items={breadcrumbItems} />
      <h1>{isEdit ? 'Edit Royalties' : 'Predefine Royalties'}</h1>
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.bookInfo}>
        <label>
          Book Title:
          <input
            type="text"
            placeholder="Enter book title"
            value={bookTitle}
            onChange={e => setBookTitle(e.target.value)}
            readOnly={isEdit}
          />
        </label>
      </div>
      <p>Define the royalty splits for each contributor below.</p>
      <Button onClick={addDefinition}>Add Contributor</Button>
      <table className={styles.royaltyTable}>
        <thead>
          <tr>
            <th>Contributor</th>
            <th>Countries</th>
            <th>Distribution Kinds</th>
            <th>Royalties Configuration</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {definitions.map(def => (
            <React.Fragment key={def.id}>
              <tr>
                <td>
                  <input
                    type="text"
                    placeholder="Contributor Name"
                    value={def.contributor}
                    onChange={e =>
                      updateDefinitionField(
                        def.id,
                        'contributor',
                        e.target.value
                      )
                    }
                  />
                </td>
                <td>
                  <div className={styles.checkboxContainer}>
                    {countryOptions.map(option => (
                      <label key={option} className={styles.checkboxLabel}>
                        <input
                          type="checkbox"
                          checked={def.locations.includes(option)}
                          onChange={e =>
                            onCountryChange(def.id, option, e.target.checked)
                          }
                          disabled={isComboUsedElsewhere(
                            def,
                            option,
                            'countries'
                          )}
                        />
                        <span>{option}</span>
                      </label>
                    ))}
                  </div>
                </td>
                <td>
                  <div className={styles.checkboxContainer}>
                    {distributionOptions.map(option => (
                      <label key={option} className={styles.checkboxLabel}>
                        <input
                          type="checkbox"
                          checked={def.bookFormats.includes(option)}
                          onChange={e =>
                            onDistributionChange(
                              def.id,
                              option,
                              e.target.checked
                            )
                          }
                          disabled={isComboUsedElsewhere(
                            def,
                            option,
                            'bookFormats'
                          )}
                        />
                        <span>{option}</span>
                      </label>
                    ))}
                  </div>
                </td>
                <td>
                  {!def.isBranched ? (
                    def.tiers ? (
                      <select
                        value={def.tierConditionType || 'units'}
                        onChange={e =>
                          updateDefinitionField(
                            def.id,
                            'tierConditionType',
                            e.target.value
                          )
                        }
                      >
                        <option value="units">Units Sold</option>
                        <option value="revenue">Revenue</option>
                      </select>
                    ) : (
                      <input
                        type="number"
                        placeholder="Royalty %"
                        value={def.royaltyPercentage}
                        onChange={e =>
                          updateDefinitionField(
                            def.id,
                            'royaltyPercentage',
                            e.target.value
                          )
                        }
                      />
                    )
                  ) : null}
                </td>
                <td>
                  <Button
                    onClick={() => removeDefinition(def.id)}
                    variant="danger"
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={() => toggleTierMode(def.id)}
                    variant="secondary"
                    style={{ marginLeft: '8px' }}
                  >
                    {def.tiers
                      ? 'Disable Tiered Royalties'
                      : 'Enable Tiered Royalties'}
                  </Button>
                  <Button
                    onClick={() =>
                      addRoyaltyStructureForContributor(
                        def.contributor,
                        def.contributorId
                      )
                    }
                    variant="secondary"
                    style={{ margin: '4px' }}
                  >
                    Add Structure
                  </Button>
                </td>
              </tr>
              {def.tiers && (
                <tr>
                  <td colSpan={5}>
                    <div className={styles.tiersSection}>
                      <table className={styles.tiersTable}>
                        <thead>
                          <tr>
                            <th>
                              Threshold
                              <InfoTooltip
                                text="This is the revenue amount or number of units sold that the royalty percentage is applied to. For example, if the threshold is 1000 units, and the royalty percentage is 10%, then the contributor will receive 10% of all revenue starting at 1000 units until the next threshold."
                                position="right"
                                symbol="reference"
                              />
                            </th>
                            <th>Royalty %</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {def.tiers.map(tier => (
                            <tr key={tier.id}>
                              <td>
                                <input
                                  type="number"
                                  placeholder="Enter threshold"
                                  value={tier.threshold}
                                  onChange={e =>
                                    updateTierField(
                                      def.id,
                                      tier.id,
                                      'threshold',
                                      parseFloat(e.target.value)
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder="Royalty %"
                                  value={tier.royaltyPercentage}
                                  onChange={e =>
                                    updateTierField(
                                      def.id,
                                      tier.id,
                                      'royaltyPercentage',
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Button
                                  onClick={() => removeTier(def.id, tier.id)}
                                  variant="danger"
                                >
                                  Delete Tier
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Button
                        onClick={() => addTier(def.id)}
                        variant="secondary"
                        style={{ marginTop: '8px' }}
                      >
                        Add Tier
                      </Button>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
          {definitions.length === 0 && (
            <tr>
              <td colSpan={5}>
                No royalty splits yet. Add a Contributor to create one.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Button onClick={handleSave} className={styles.saveButton}>
        {isEdit ? 'Update Royalties' : 'Save Definitions'}
      </Button>
    </div>
  );
};

export default PredefinedRoyalties;
