import React, { useState, useEffect } from 'react';
import styles from './RoyaltiesSummary.module.css';
import { post } from '../../api/apiUtils';
import { serverEndpoints } from 'api/generated/endpoints';
import {
  TBookFormat,
  TRoyaltyStructureResponse,
} from 'api/generated/types/common';
import Button from 'components/design-system/Button/Button';
import { useNavigate } from 'react-router-dom';
import { Card } from 'components/Card/Card';
import { distributionLocationToString } from 'types/royalties/DistributionLocation';

const RoyaltiesSummary: React.FC = () => {
  const [royalties, setRoyalties] = useState<TRoyaltyStructureResponse | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoyalties = async () => {
      try {
        const response = await post<TRoyaltyStructureResponse>(
          serverEndpoints.royaltiesListAllRoyaltyStructures(),
          {}
        );
        setRoyalties(response);
        setLoading(false);
      } catch (err) {
        setError('Failed to load royalty definitions');
        setLoading(false);
      }
    };

    fetchRoyalties();
  }, []);

  const formatRoyaltyRange = (range: {
    start: number;
    end: number | null;
    percentage: number;
  }) => {
    const threshold = range.start === 0 ? 'Start' : `${range.start}+`;
    return `${threshold}: ${range.percentage}%`;
  };

  const apiBookFormatToHuman = (format: TBookFormat) => {
    switch (format) {
      case 'allFormats':
        return 'All Formats';
      case 'allPrint':
        return 'All Print';
      case 'allDigital':
        return 'All Digital';
      case 'allAudio':
        return 'All Audio';
      case 'ebook':
        return 'eBook';
      case 'printOnDemand':
        return 'Print On Demand';
      case 'printOnDemandHardcover':
        return 'Hardcover Print On Demand';
      case 'tradePaperback':
        return 'Trade Paperback';
      case 'hardcover':
        return 'Hardcover';
      case 'specialEdition':
        return 'Special Edition';
      case 'massMarketPaperback':
        return 'Mass Market Paperback';
      case 'kindleUnlimited':
        return 'Kindle Unlimited';
      case 'audiobook':
        return 'Audiobook';
      default:
        throw new Error(`Unknown book format: ${format}`);
    }
  };

  if (loading)
    return (
      <div className={styles.royaltiesSummary}>
        <div className={styles.loading}>Loading royalty definitions...</div>
      </div>
    );
  if (error)
    return (
      <div className={styles.royaltiesSummary}>
        <div className={styles.error}>{error}</div>
      </div>
    );
  if (!royalties) return <div className={styles.royaltiesSummary}>Loading</div>;

  return (
    <div className={styles.royaltiesSummary}>
      <h1>Royalties Summary</h1>

      {royalties.bookRoyaltyStructures.length === 0 ? (
        <p>No royalty definitions found.</p>
      ) : (
        <div className={styles.royaltiesGrid}>
          {royalties.bookRoyaltyStructures.map((bookRoyalty, bookIndex) => (
            <Card key={bookIndex} className={styles.royaltyCard}>
              <div className={styles.header}>
                <h2>{bookRoyalty.bookTitle}</h2>
                <div className={styles.buttonGroup}>
                  <Button
                    onClick={() =>
                      navigate(`/royalties/calculate/${bookRoyalty.bookId}`)
                    }
                    variant="primary"
                    size="small"
                  >
                    View Royalties
                  </Button>
                  <Button
                    onClick={() =>
                      navigate(`/royalties/setup/${bookRoyalty.bookId}`)
                    }
                    variant="secondary"
                    size="small"
                  >
                    Edit Royalties
                  </Button>
                </div>
              </div>

              {bookRoyalty.contributorRoyaltyStructures.map(
                (contributorRoyalty, contributorIndex) => (
                  <div key={contributorIndex} className={styles.cardContent}>
                    <h3>{contributorRoyalty.contributorName || 'Unnamed'}</h3>

                    {contributorRoyalty.distributionRoyaltyRanges.map(
                      (distributionRoyaltyRange, rangeIndex) => (
                        <div key={rangeIndex} className={styles.section}>
                          <h4>Royalty Structure</h4>
                          <div className={styles.ranges}>
                            <strong>Ranges:</strong>
                            <ul>
                              {distributionRoyaltyRange.ranges.map(
                                (range, idx) => (
                                  <li key={idx}>{formatRoyaltyRange(range)}</li>
                                )
                              )}
                            </ul>
                          </div>

                          <h4>Distribution Details</h4>
                          <div className={styles.distributions}>
                            {distributionRoyaltyRange.distributions.map(
                              (dist, idx) => (
                                <div key={idx} className={styles.distribution}>
                                  <span>
                                    {distributionLocationToString(
                                      dist.location
                                    )}
                                  </span>
                                  <span>
                                    {apiBookFormatToHuman(dist.format)}
                                  </span>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )
              )}
            </Card>
          ))}
        </div>
      )}

      <div className={styles.buttonContainer}>
        <Button
          onClick={() => navigate('/royalties/setup')}
          disabled={false}
          variant="primary"
          size="medium"
        >
          Setup New Book
        </Button>
      </div>
    </div>
  );
};

export default RoyaltiesSummary;
