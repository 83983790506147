import { createApi } from '@reduxjs/toolkit/query/react';
import axiosInstance from '../axiosConfig';
import { TQueuedSubscriberStatus } from './types/common';

// Interface that matches the QueuedSubscriber table structure
export interface QueuedJob {
  id: string;
  name: string;
  payload: any;
  status: TQueuedSubscriberStatus;
  executionDetails?: string;
  createdAt: string;
  updatedAt: string;
  scheduledAt?: string;
  pendingAt?: string;
  fifoKey?: string;
  retryNum: number;
  maxRetries: number;
  priority: number;
}

// Statistics about jobs
export interface JobQueueStats {
  total: number;
  completed: number;
  failed: number;
  queued: number;
  pending: number;
  cancelled: number;
  scheduled: number;
  averageWaitTimeSeconds: number;
  averageProcessingTimeSeconds: number;
  throughputPerHour: {
    timestamp: string;
    count: number;
  }[];
  failureRatePerHour: {
    timestamp: string;
    rate: number;
  }[];
}

export interface ListJobsParams {
  status?: TQueuedSubscriberStatus;
  page?: number;
  limit?: number;
  startDate?: string;
  endDate?: string;
  search?: string;
}

export interface RetryJobParams {
  jobId: string;
}

// Custom fetchBaseQuery that uses our axiosInstance
const axiosBaseQuery =
  () =>
  async ({ url, method, body, params }: any) => {
    try {
      const result = await axiosInstance({
        url,
        method,
        data: body,
        params,
      });
      return { data: result.data };
    } catch (axiosError: any) {
      return {
        error: {
          status: axiosError.response?.status,
          data: axiosError.response?.data || axiosError.message,
        },
      };
    }
  };

// Define the job queue API
export const jobQueueApi = createApi({
  reducerPath: 'jobQueueApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Jobs', 'JobStats'],
  endpoints: builder => ({
    // Get job queue statistics
    getJobQueueStats: builder.query<JobQueueStats, { timeframe?: string }>({
      query: ({ timeframe = '24h' }) => ({
        url: '/admin/job-queue/stats',
        method: 'POST',
        body: { timeframe },
      }),
      providesTags: ['JobStats'],
    }),

    // List jobs with filtering
    listJobs: builder.query<
      { jobs: QueuedJob[]; total: number },
      ListJobsParams
    >({
      query: params => ({
        url: '/admin/job-queue/jobs',
        method: 'POST',
        body: params,
      }),
      providesTags: ['Jobs'],
    }),

    // Get details for a specific job
    getJobDetails: builder.query<QueuedJob, string>({
      query: jobId => ({
        url: `/admin/job-queue/jobs/${jobId}`,
        method: 'POST',
      }),
      providesTags: (_, __, jobId) => [{ type: 'Jobs', id: jobId }],
    }),

    // Retry a failed job
    retryJob: builder.mutation<void, RetryJobParams>({
      query: ({ jobId }) => ({
        url: `/admin/job-queue/jobs/${jobId}/retry`,
        method: 'POST',
      }),
      invalidatesTags: ['Jobs', 'JobStats'],
    }),

    // Cancel a queued or scheduled job
    cancelJob: builder.mutation<void, string>({
      query: jobId => ({
        url: `/admin/job-queue/jobs/${jobId}/cancel`,
        method: 'POST',
      }),
      invalidatesTags: ['Jobs', 'JobStats'],
    }),
  }),
});

// Export hooks for using the API
export const {
  useGetJobQueueStatsQuery,
  useListJobsQuery,
  useGetJobDetailsQuery,
  useRetryJobMutation,
  useCancelJobMutation,
} = jobQueueApi;
