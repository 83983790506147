import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import styles from './ProductsDropdown.module.css';

const ProductsDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const authorProducts = [
    {
      name: 'Manuscript Analysis',
      path: '/products/manuscript-analysis',
      description:
        'Score manuscripts on a variety of elements to assess literary merit',
    },
    {
      name: 'Editorial Analysis',
      path: '/products/editorial-analysis',
      description: 'Advanced N-gram analysis for writing enhancement',
    },
    {
      name: 'Story Beats',
      path: '/products/story-beats',
      description:
        'Extract the core beats of your story and analyze them in isolation',
    },
  ];

  const publisherProducts = [
    {
      name: 'Royalty Calculator',
      path: '/products/royalty-calculator',
      description:
        'Calculate author royalties from multiple platform sales reports',
    },
  ];

  return (
    <div className={styles.container} ref={dropdownRef}>
      <button
        className={styles.trigger}
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        Products
        <ChevronDown
          size={16}
          className={`${styles.chevron} ${isOpen ? styles.open : ''}`}
        />
      </button>

      {isOpen && (
        <div className={styles.dropdown} role="menu">
          <div className={styles.section}>
            <div className={styles.sectionTitle}>For Authors</div>
            {authorProducts.map(product => (
              <Link
                key={product.path}
                to={product.path}
                className={styles.item}
                onClick={() => setIsOpen(false)}
                role="menuitem"
              >
                <div className={styles.productName}>{product.name}</div>
                <div className={styles.productDescription}>
                  {product.description}
                </div>
              </Link>
            ))}
          </div>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>For Publishers</div>
            {publisherProducts.map(product => (
              <Link
                key={product.path}
                to={product.path}
                className={styles.item}
                onClick={() => setIsOpen(false)}
                role="menuitem"
              >
                <div className={styles.productName}>{product.name}</div>
                <div className={styles.productDescription}>
                  {product.description}
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsDropdown;
