// src/pages/SeriesWiki/SeriesForm.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { seriesApi } from '../../services/api/seriesApi';
import './SeriesForm.css';

interface SeriesFormData {
  title: string;
  description: string;
}

const defaultSeriesData: SeriesFormData = {
  title: '',
  description: '',
};

const SeriesForm: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<SeriesFormData>(defaultSeriesData);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const newSeries = await seriesApi.createSeries({
        ...formData,
        genre: null,
        settingPhysical: null,
        settingCultural: null,
        settingMagicSystem: null,
      });
      navigate(`/series/${newSeries.id}`);
    } catch (err) {
      setError('Failed to create series');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="series-form-container">
      <div className="series-form-header">
        <h1>Create New Series</h1>
      </div>

      {error && <div className="error-message">{error}</div>}

      <form onSubmit={handleSubmit} className="series-form">
        <div className="form-group">
          <label htmlFor="title">
            Series Title <span className="required">*</span>
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            required
            className="form-control"
            placeholder="Enter the title of your series"
          />
        </div>

        <div className="form-group">
          <label htmlFor="description">
            Description <span className="required">*</span>
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            required
            className="form-control"
            rows={5}
            placeholder="Write a brief description of your series"
          />
        </div>

        <div className="form-footer">
          <p className="form-hint">
            You can add more details like characters, locations, and settings
            after creating your series.
          </p>
          <div className="form-actions">
            <button
              type="button"
              className="cancel-button"
              onClick={() => navigate('/series')}
            >
              Cancel
            </button>
            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? 'Creating...' : 'Create Series'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SeriesForm;
