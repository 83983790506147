import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Breadcrumb.module.css';

interface BreadcrumbProps {
  items: {
    label: string;
    path?: string;
  }[];
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.breadcrumb}>
      {items.map((item, index) => (
        <React.Fragment key={item.label}>
          {index > 0 && <span className={styles.separator}>/</span>}
          {item.path ? (
            <span
              className={styles.link}
              onClick={() => navigate(item.path!)}
              role="button"
              tabIndex={0}
            >
              {item.label}
            </span>
          ) : (
            <span className={styles.current}>{item.label}</span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
