// src/pages/SeriesWiki/LocationDetail.tsx
import React, { useState, useEffect } from 'react';
import {
  useParams,
  Link,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { get, del } from '../../api/apiUtils';
import Button from '../../components/design-system/Button/Button';
import { TLocationResponse } from '../../api/generated/types/common';
import './LocationDetail.css'; // Create this CSS file, reuse styles from CharacterDetail

const LocationDetail: React.FC = () => {
  const { seriesId, locationId } = useParams<{
    seriesId: string;
    locationId: string;
  }>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnTab = searchParams.get('returnTab') || 'locations';
  const [location, setLocation] = useState<TLocationResponse | null>(null);
  // Optional: Add state for parent location name if needed
  // const [parentLocationName, setParentLocationName] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLocation = async () => {
      if (!locationId) return;
      try {
        setLoading(true);
        const data = await get<TLocationResponse>(
          `/wiki/locations/${locationId}/get`
        );
        setLocation(data);
        setError(null);

        // --- Optional: Fetch Parent Location Name ---
        // if (data.parentLocation) {
        //   try {
        //     const parentData = await get<TLocationResponse>(`/wiki/locations/${data.parentLocation}/get`);
        //     setParentLocationName(parentData.name);
        //   } catch (parentErr) {
        //     console.error("Could not fetch parent location name", parentErr);
        //     setParentLocationName("Unknown"); // Or handle differently
        //   }
        // }
        // --- End Optional ---
      } catch (err) {
        console.error('Error fetching location:', err);
        setError('Failed to load location data.');
      } finally {
        setLoading(false);
      }
    };
    fetchLocation();
  }, [locationId]);

  const handleDelete = async () => {
    if (
      !location ||
      !window.confirm(`Are you sure you want to delete "${location.name}"?`)
    ) {
      return;
    }

    try {
      await del(`/wiki/locations/${locationId}/delete`);
      navigate(`/series/${seriesId}?tab=${returnTab}`);
    } catch (err) {
      console.error('Error deleting location:', err);
      setError('Failed to delete location');
    }
  };

  if (loading)
    return <div className="loading-indicator">Loading location details...</div>; // Reuse styles
  if (error) return <div className="error-message">{error}</div>; // Reuse styles
  if (!location)
    return <div className="error-message">Location not found.</div>; // Reuse styles

  return (
    <div className="location-detail-container">
      <div className="location-detail-header">
        <div className="header-content">
          <h1>{location.name}</h1>
        </div>
        <div className="header-actions">
          <Link to={`/series/${seriesId}?tab=${returnTab}`}>
            <Button variant="secondary">Back to Series</Button>
          </Link>
          <Link
            to={`/series/${seriesId}/locations/${locationId}/edit?returnTab=${returnTab}`}
          >
            <Button variant="primary">Edit Location</Button>
          </Link>
          <Button variant="danger" onClick={handleDelete}>
            Delete Location
          </Button>
        </div>
      </div>
      <div className="detail-content">
        {' '}
        {/* Reuse styles */}
        {/* Organize details into sections or a grid */}
        <div className="info-grid">
          {' '}
          {/* Reuse styles */}
          {location.locationType && (
            <InfoItem label="Type" value={location.locationType} />
          )}
          {location.parentLocation && (
            <InfoItem
              label="Parent Location"
              value={
                location.parentLocation /* Replace with parentLocationName if fetched */
              }
            />
          )}
          {location.geography && (
            <InfoItem label="Geography" value={location.geography} />
          )}
          {location.climate && (
            <InfoItem label="Climate" value={location.climate} />
          )}
          {location.politics && (
            <InfoItem label="Politics" value={location.politics} />
          )}
          {location.economy && (
            <InfoItem label="Economy" value={location.economy} />
          )}
          {location.firstAppearance && (
            <InfoItem
              label="First Appearance"
              value={location.firstAppearance /* Fetch book title? */}
            />
          )}
          {location.mapReference && (
            <InfoItem label="Map Reference" value={location.mapReference} />
          )}
        </div>
        {location.description && (
          <DetailSection title="Description" content={location.description} />
        )}
        {location.history && (
          <DetailSection title="History" content={location.history} />
        )}
        {location.culture && (
          <DetailSection title="Culture" content={location.culture} />
        )}
        {/* Add more sections as needed */}
      </div>
    </div>
  );
};

// Helper components for displaying info (reuse or create)
const InfoItem: React.FC<{ label: string; value: string | number | null }> = ({
  label,
  value,
}) => (
  <div className="info-item">
    {' '}
    {/* Reuse styles */}
    <h3>{label}</h3>
    <p>{value || '-'}</p>
  </div>
);

const DetailSection: React.FC<{ title: string; content: string | null }> = ({
  title,
  content,
}) => (
  <section className="detail-section">
    {' '}
    {/* Reuse styles */}
    <h2>{title}</h2>
    <p>{content || 'Not specified.'}</p>
  </section>
);

export default LocationDetail;
