// src/pages/Admin/JobQueueManagement/components/JobList.tsx
import React from 'react';
import { QueuedJob } from '../../../../api/generated/jobQueueApi';
import styles from './JobList.module.css';
import { TQueuedSubscriberStatus } from 'api/generated/types/common';

interface JobListProps {
  jobs: QueuedJob[];
  isLoading: boolean;
  isError: boolean;
  selectedJobId: string | null;
  onJobSelect: (jobId: string) => void;
  formatDate: (date: string) => string;
  total: number;
  page: number;
  limit: number;
  onPageChange: (page: number) => void;
}

// Status badge styling
export const getStatusBadgeClass = (
  status: TQueuedSubscriberStatus
): string => {
  switch (status) {
    case 'completed':
      return styles.statusCompleted;
    case 'failed':
      return styles.statusFailed;
    case 'blocked':
      return styles.statusBlocked;
    case 'pending':
      return styles.statusPending;
    case 'canceled':
      return styles.statusCancelled;
    case 'scheduled':
      return styles.statusScheduled;
    default:
      return '';
  }
};

const JobList: React.FC<JobListProps> = ({
  jobs,
  isLoading,
  isError,
  selectedJobId,
  onJobSelect,
  formatDate,
  total,
  page,
  limit,
  onPageChange,
}) => {
  // Calculate pagination
  const totalPages = Math.ceil(total / limit);
  const hasPrevious = page > 1;
  const hasNext = page < totalPages;

  if (isLoading) {
    return <div className={styles.loading}>Loading jobs...</div>;
  }

  if (isError) {
    return <div className={styles.error}>Failed to load jobs</div>;
  }

  if (jobs.length === 0) {
    return <div className={styles.empty}>No jobs found</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Jobs ({total})</h3>
      </div>
      <div className={styles.jobsList}>
        {jobs.map(job => (
          <div
            key={job.id}
            className={`${styles.jobItem} ${selectedJobId === job.id ? styles.selected : ''}`}
            onClick={() => onJobSelect(job.id)}
          >
            <div className={styles.jobHeader}>
              <span className={styles.jobName}>{job.name}</span>
              <span
                className={`${styles.statusBadge} ${getStatusBadgeClass(job.status)}`}
              >
                {job.status}
              </span>
            </div>
            <div className={styles.jobMeta}>
              <span className={styles.jobId}>
                ID: {job.id.substring(0, 8)}...
              </span>
              <span className={styles.jobDate}>
                Created: {formatDate(job.createdAt)}
              </span>
            </div>
            {job.status === 'failed' && (
              <div className={styles.jobError}>
                <span>
                  Retries: {job.retryNum}/{job.maxRetries}
                </span>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className={styles.pagination}>
          <button
            className={styles.pageButton}
            onClick={() => onPageChange(page - 1)}
            disabled={!hasPrevious}
          >
            Previous
          </button>
          <span className={styles.pageInfo}>
            Page {page} of {totalPages}
          </span>
          <button
            className={styles.pageButton}
            onClick={() => onPageChange(page + 1)}
            disabled={!hasNext}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default JobList;
