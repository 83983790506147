// src/mocks/data/fileData.ts
import {
  TAuthorNGrams,
  TBookDetails,
  TLineEditsAnalysis,
  TStoryBeats,
} from '../../api/generated/types/common';
export interface ScoredSection {
  sectionName: string;
  sectionScore: number;
  sectionText: string;
}

export interface UploadedFileData {
  id: string;
  title: string;
  uploadDate: string;
  versions: [number, string][];
  mostRecentDetails: TBookDetails;
}

const mockAuthorNGrams: TAuthorNGrams = {
  unigrams: '{}',
  bigrams: '{}',
  trigrams: '{}',
  quadgrams: '{}',
  quintgrams: '{}',
  sextgrams: '{}',
};

const mockStoryBeats: TStoryBeats = {
  beats: [
    {
      id: 'beat1',
      number: 1,
      chapterLocation: 'Chapter 1',
      description:
        'Protagonist encounters old family photograph that reveals family secret',
      narrativeFunction:
        "Inciting incident that disrupts protagonist's understanding of their past",
      agencyType: 'external',
      genreExpectation: 'expected',
    },
    {
      id: 'beat2',
      number: 2,
      chapterLocation: 'Chapter 3',
      description:
        'Protagonist decides to investigate the truth about their family history',
      narrativeFunction: 'Character takes active role in pursuing the truth',
      agencyType: 'character',
      genreExpectation: 'expected',
    },
    {
      id: 'beat3',
      number: 3,
      chapterLocation: 'Chapter 7',
      description: "Discovery of grandmother's hidden letters reveals betrayal",
      narrativeFunction: 'Major revelation that raises stakes',
      agencyType: 'external',
      genreExpectation: 'subverted',
    },
    {
      id: 'beat4',
      number: 4,
      chapterLocation: 'Chapter 12',
      description: 'Protagonist confronts living family members about the past',
      narrativeFunction: 'Climactic confrontation',
      agencyType: 'character',
      genreExpectation: 'expected',
    },
  ],
  beatConnections: [
    {
      fromBeatId: 'beat1',
      toBeatId: 'beat2',
      connectionType: 'therefore',
      strength: 8,
      explanation:
        "The discovery of the photograph directly motivates the protagonist's decision to investigate",
    },
    {
      fromBeatId: 'beat2',
      toBeatId: 'beat3',
      connectionType: 'but',
      strength: 9,
      explanation:
        'While investigating family history, protagonist finds unexpected evidence that changes their understanding of the situation',
    },
    {
      fromBeatId: 'beat3',
      toBeatId: 'beat4',
      connectionType: 'because',
      strength: 7,
      explanation:
        'The revelations in the letters force the protagonist to confront their living family members',
    },
  ],
};

const mockLineEdits: TLineEditsAnalysis = {
  chapters: [
    {
      title: 'Introduction',
      subtitle: 'Terms and Conditions',
      number: 1,
      tokenCount: 2500,
      metrics: {
        action_density: 99.24,
        action_verbs: [
          'pay',
          'explain',
          'copyright',
          'publish',
          'maintain',
          'dictate',
          'remain',
          'use',
          'give',
          'describe',
        ],
        character_count: 1982,
        characters: [],
        dialogue_count: 0,
        dialogue_ratio: 0,
        dialogue_tags: [],
        locations: [],
        readability_score: 67.76,
        word_count: 393,
      },
    },
    {
      title: 'Rights and Obligations',
      subtitle: null,
      number: 2,
      tokenCount: 3200,
      metrics: {
        action_density: 85.5,
        action_verbs: [
          'create',
          'cover',
          'use',
          'pay',
          'return',
          'act',
          'offer',
          'take',
          'publish',
          'give',
        ],
        character_count: 2150,
        characters: [],
        dialogue_count: 0,
        dialogue_ratio: 0,
        dialogue_tags: [],
        locations: [],
        readability_score: 72.4,
        word_count: 425,
      },
    },
    {
      title: 'Payment Terms',
      subtitle: 'Royalties and Fees',
      number: 3,
      tokenCount: 2800,
      metrics: {
        action_density: 92.8,
        action_verbs: [
          'require',
          'buy',
          'pay',
          'provide',
          'earn',
          'publish',
          'explain',
          'require',
          'market',
          'require',
        ],
        character_count: 1875,
        characters: [],
        dialogue_count: 0,
        dialogue_ratio: 0,
        dialogue_tags: [],
        locations: [],
        readability_score: 69.9,
        word_count: 375,
      },
    },
  ],
};

export const mockFiles: UploadedFileData[] = [
  {
    id: '1',
    title: 'The Great Gatsby',
    uploadDate: '2023-09-15T10:30:00Z',
    versions: [[1, '1']],
    mostRecentDetails: {
      author: 'F. Scott Fitzgerald',
      wordCount: 47094,
      chapterCount: 9,
      tokenCount: 47094,
      firstLine:
        'In my younger and more vulnerable years my father gave me some advice that I’ve been turning over in my mind ever since.',
      evaluation: {
        overall: {
          assessment: '',
          score: 50,
          verdict: false,
          confidence: 1,
          supportingArgument: 'Great book',
          opposingArgument: 'Not a great book',
          editorialLetterLink: 'https://notalink.com',
          commentsLink: 'https://notalink.com',
        },
        character: null,
        plot: null,
        promise: null,
        quality: null,
        // scores: {
        //   plotCoherence: 8.5,
        //   characterDevelopment: 9.0,
        //   proseQuality: 9.5,
        //   thematicDepth: 9.0,
        // },
        // descriptions: {
        //   plotCoherence: "The narrative is tightly woven, with events building logically to the climax.",
        //   characterDevelopment: "Characters are well-rounded and evolve naturally throughout the story.",
        //   proseQuality: "Fitzgerald's prose is lyrical and evocative, painting vivid pictures with words.",
        //   thematicDepth: "The novel explores themes of wealth, love, and the American Dream with nuance and complexity.",
        // },
      },
      evaluationPending: false,
      authorNGramAnalysis: mockAuthorNGrams,
      authorNGramAnalysisPending: false,
      characterNGramAnalysis: 'TODO',
      characterNGramAnalysisPending: false,
      storyBeatAnalysis: mockStoryBeats,
      storyBeatAnalysisPending: false,
      lineEditAnalysis: mockLineEdits,
      lineEditAnalysisPending: false,
      versionNumber: 1,
      versionId: '1',
      currentStatus: 'bookPending',
    },
  },
  {
    id: '2',
    title: 'To Kill a Mockingbird',
    uploadDate: '2023-09-16T14:45:00Z',
    versions: [[1, '1']],
    mostRecentDetails: {
      author: 'Harper Lee',
      wordCount: 100388,
      chapterCount: 31,
      tokenCount: 100388,
      firstLine: 'When he was',
      evaluation: {
        overall: {
          assessment: '',
          score: 50,
          verdict: false,
          confidence: 1,
          supportingArgument: 'Great book',
          opposingArgument: 'Not a great book',
          editorialLetterLink: 'https://notalink.com',
          commentsLink: 'https://notalink.com',
        },
        character: null,
        plot: null,
        promise: null,
        quality: null,
        // scores: {
        //   plotCoherence: 9.0,
        //   characterDevelopment: 9.5,
        //   proseQuality: 9.0,
        //   thematicDepth: 9.5,
        // },
        // descriptions: {
        //   plotCoherence: "The story unfolds naturally, with subplots that enhance the main narrative.",
        //   characterDevelopment: "Characters are deeply human, with Scout's coming-of-age journey being particularly well-crafted.",
        //   proseQuality: "Lee's writing is clear and evocative, capturing the voice of a young narrator perfectly.",
        //   thematicDepth: "The novel tackles complex themes of racism, injustice, and moral growth with sensitivity and depth.",
        // },
      },
      evaluationPending: true,
      authorNGramAnalysis: mockAuthorNGrams,
      authorNGramAnalysisPending: false,
      characterNGramAnalysis: 'TODO',
      characterNGramAnalysisPending: false,
      storyBeatAnalysis: mockStoryBeats,
      storyBeatAnalysisPending: false,
      lineEditAnalysis: mockLineEdits,
      lineEditAnalysisPending: false,
      versionNumber: 1,
      versionId: '1',
      currentStatus: 'bookPending',
    },
  },
  {
    id: '3',
    title: '1984',
    uploadDate: '2023-09-17T09:15:00Z',
    versions: [[1, '1']],
    mostRecentDetails: {
      author: 'George Orwell',
      wordCount: 88942,
      chapterCount: 23,
      tokenCount: 88942,
      firstLine:
        'It was a bright cold day in April, and the clocks were striking thirteen.',
      evaluation: {
        overall: {
          assessment: '',
          score: 50,
          verdict: false,
          confidence: 1,
          supportingArgument: 'Great book',
          opposingArgument: 'Not a great book',
          editorialLetterLink: 'https://notalink.com',
          commentsLink: 'https://notalink.com',
        },
        character: null,
        plot: null,
        promise: null,
        quality: null,
        // scores: {
        //   plotCoherence: 9.0,
        //   characterDevelopment: 8.5,
        //   proseQuality: 9.0,
        //   thematicDepth: 10.0,
        // },
        // descriptions: {
        //   plotCoherence: "The dystopian narrative is chillingly consistent, building tension throughout.",
        //   characterDevelopment: "While Winston's journey is well-portrayed, some secondary characters feel less developed.",
        //   proseQuality: "Orwell's prose is clear and impactful, effectively conveying the bleakness of the world.",
        //   thematicDepth: "The novel's exploration of totalitarianism, surveillance, and the manipulation of truth is unparalleled.",
        // },
      },
      evaluationPending: true,
      authorNGramAnalysis: mockAuthorNGrams,
      authorNGramAnalysisPending: false,
      characterNGramAnalysis: 'TODO',
      characterNGramAnalysisPending: false,
      storyBeatAnalysis: mockStoryBeats,
      storyBeatAnalysisPending: false,
      lineEditAnalysis: mockLineEdits,
      lineEditAnalysisPending: false,
      versionNumber: 1,
      versionId: '1',
      currentStatus: 'bookPending',
    },
  },
];
