import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import styles from './ImageCarousel.module.css';

export type CarouselImage = {
  src: string;
  alt: string;
  caption?: string;
};

type ImageCarouselProps = {
  images: CarouselImage[];
  autoAdvanceInterval?: number;
};

const ImageCarousel: React.FC<ImageCarouselProps> = ({
  images,
  autoAdvanceInterval = 5000,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [timerKey, setTimerKey] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const nextSlide = useCallback(() => {
    setCurrentIndex(prevIndex =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
    if (!isModalOpen) {
      setTimerKey(key => key + 1);
    }
  }, [images.length, isModalOpen]);

  const prevSlide = useCallback(() => {
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
    if (!isModalOpen) {
      setTimerKey(key => key + 1);
    }
  }, [images.length, isModalOpen]);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
    // Focus will move to close button when modal opens
    setTimeout(() => closeButtonRef.current?.focus(), 0);
    document.body.style.overflow = 'hidden'; // Prevent background scrolling
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    document.body.style.overflow = '';
    setTimerKey(key => key + 1); // Reset timer when closing modal
  }, []);

  // Handle keyboard events
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isModalOpen) {
        switch (event.key) {
          case 'Escape':
            closeModal();
            break;
          case 'ArrowLeft':
            prevSlide();
            break;
          case 'ArrowRight':
            nextSlide();
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isModalOpen, closeModal, prevSlide, nextSlide]);

  // Auto-advance timer
  useEffect(() => {
    if (autoAdvanceInterval > 0 && images.length > 1 && !isModalOpen) {
      const timer = setInterval(nextSlide, autoAdvanceInterval);
      return () => clearInterval(timer);
    }
  }, [autoAdvanceInterval, images.length, timerKey, isModalOpen, nextSlide]);

  if (images.length === 0) return null;

  return (
    <>
      <div className={styles.carouselContainer}>
        <div
          className={styles.imageContainer}
          role="button"
          tabIndex={0}
          onClick={openModal}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              openModal();
            }
          }}
        >
          <img
            src={images[currentIndex].src}
            alt={images[currentIndex].alt}
            className={styles.carouselImage}
          />

          {images[currentIndex].caption && (
            <div className={styles.caption}>{images[currentIndex].caption}</div>
          )}
        </div>

        {images.length > 1 && (
          <>
            <button
              onClick={e => {
                e.stopPropagation(); // Prevent modal from opening
                prevSlide();
              }}
              className={`${styles.navButton} ${styles.prevButton}`}
              aria-label="Previous slide"
            >
              <ChevronLeft />
            </button>
            <button
              onClick={e => {
                e.stopPropagation(); // Prevent modal from opening
                nextSlide();
              }}
              className={`${styles.navButton} ${styles.nextButton}`}
              aria-label="Next slide"
            >
              <ChevronRight />
            </button>

            <div className={styles.indicators}>
              {images.map((_, index) => (
                <button
                  key={index}
                  onClick={e => {
                    e.stopPropagation(); // Prevent modal from opening
                    setCurrentIndex(index);
                    if (!isModalOpen) {
                      setTimerKey(key => key + 1);
                    }
                  }}
                  className={`${styles.indicator} ${
                    currentIndex === index ? styles.indicatorActive : ''
                  }`}
                  aria-label={`Go to slide ${index + 1}`}
                  aria-current={currentIndex === index ? 'true' : 'false'}
                />
              ))}
            </div>
          </>
        )}
      </div>

      {isModalOpen && (
        <div
          className={styles.modalOverlay}
          ref={modalRef}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title"
        >
          <div className={styles.modalContent}>
            <button
              ref={closeButtonRef}
              onClick={closeModal}
              className={styles.closeButton}
              aria-label="Close modal"
            >
              <X />
            </button>

            <div className={styles.modalImageContainer}>
              <img
                src={images[currentIndex].src}
                alt={images[currentIndex].alt}
                className={styles.modalImage}
              />

              {images[currentIndex].caption && (
                <div className={styles.modalCaption} id="modal-title">
                  {images[currentIndex].caption}
                </div>
              )}
            </div>

            {images.length > 1 && (
              <div className={styles.modalNavigation}>
                <button
                  onClick={prevSlide}
                  className={`${styles.modalNavButton} ${styles.modalPrevButton}`}
                  aria-label="Previous image"
                >
                  <ChevronLeft />
                </button>
                <button
                  onClick={nextSlide}
                  className={`${styles.modalNavButton} ${styles.modalNextButton}`}
                  aria-label="Next image"
                >
                  <ChevronRight />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ImageCarousel;
