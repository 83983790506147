// src/components/PublicRoute/index.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';

const PublicRoute: React.FC = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.userAuth);

  return isAuthenticated ? <Navigate to="/manuscripts" replace /> : <Outlet />;
};

export default PublicRoute;
